import React from "react";
import { getTranslation } from "util/localisation";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const MultiSelect = ({ field = {}, form: { touched, errors }, ...props }) => {
  const { selectoptions, showcolumn, labeltext } = props;
  const isInvalid = touched[field.name] && errors[field.name];
  return (
    <React.Fragment>
      <Label>{getTranslation(labeltext)}</Label>
      <FormGroup>
        <Input invalid={isInvalid} type="select" {...field} {...props}>
          <option key="prompt" value={undefined}>
            Molimo odaberite
          </option>
          {selectoptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option[showcolumn]}
            </option>
          ))}
        </Input>
        {isInvalid && <FormFeedback>{errors[field.name]}</FormFeedback>}
      </FormGroup>
    </React.Fragment>
  );
};

export default MultiSelect;
