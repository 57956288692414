import React, { Component } from "react";
import { Input, Table, Button } from "reactstrap";
import { getTranslation } from "util/localisation";
import { connect } from "react-redux";

class SettingsEdit extends Component {
  constructor() {
    super();
  }

  state = {
    settings: [],
    loading: true,
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    let URL = `api/settings?max=-1`;
    this.setState({ loading: true });
    fetch(URL, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((data) => {
          this.setState({ settings: data.data });
        });
      }
      this.setState({ loading: false });
    });
  };

  saveSettings = () => {
    let { settings } = this.state;
    let params = { settings: settings };
    this.setState({ loading: true });
    fetch(`api/settings/saveSettings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(params),
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      } else {
        showErrorAlert(getTranslation("api.error.fetching"));
      }
      this.setState({ loading: false });
    });
  };

  onValueChange = (id, value) => {
    let { settings } = this.state;
    const tempSettings = settings.map((setting) => {
      if (setting.id === id) {
        setting.settingValue = value;
      }
      return setting;
    });
    this.setState({ settings: tempSettings });
  };

  render() {
    const { loading, settings } = this.state;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      group: {
        field: "settingGroup",
        header: "settings.table.header.group",
        properties: {},
      },
      key: {
        field: "settingKey",
        header: "settings.table.header.key",
        properties: {},
      },
      value: {
        field: "settingValue",
        header: "settings.table.header.value",
        properties: {},
      },
    };

    return (
      <React.Fragment>
        <Table striped responsive hover size="sm" bordered>
          <thead>
            <tr>
              <th>{getTranslation("tables.id")}</th>
              <th>{getTranslation("settings.table.header.group")}</th>
              <th>{getTranslation("settings.table.header.key")}</th>
              <th>{getTranslation("settings.table.header.value")}</th>
            </tr>
          </thead>
          <tbody>
            {settings.map((setting) => {
              return (
                <tr key={setting.id}>
                  <td>{setting.id}</td>
                  <td>{setting.settingGroup}</td>
                  <td>{setting.settingKey}</td>
                  <td>
                    <Input
                      value={setting.settingValue}
                      disabled={loading}
                      onChange={(e) =>
                        this.onValueChange(setting.id, e.target.value)
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button type="submit" disabled={loading} onClick={this.saveSettings}>
          {getTranslation("table.button.save")}
        </Button>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

export default connect(null, mapDispatchToProps)(SettingsEdit);
