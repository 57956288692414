import { GetApiCallFiltersBearer } from "./api/Get";

const settings = "settings";

export function setAppSettings(settingsData) {
  localStorage.setItem(settings, JSON.stringify(settingsData));
}
export function getAppSettings() {
  return GetApiCallFiltersBearer("settings", "");
}

export function getAppSetting(group, key) {
  const localStorageValue = localStorage.getItem(settings);
  if (localStorageValue) {
    const localStorageJson = JSON.parse(localStorageValue);
    if (localStorageJson && localStorageJson.length > 0) {
      for (let i = 0; i < localStorageJson.length; i++) {
        const entry = localStorageJson[i];
        if (entry.settingGroup === group && entry.settingKey === key) {
          return entry.settingValue;
        }
      }
      localStorageJson.forEach((entry) => {});
    }
  }
  return undefined;
}
