import React from "react";
import { getTranslation } from "util/localisation";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { DateTimePicker } from "react-widgets";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";

moment.locale("hr-HR");
moment().format("L");
momentLocalizer();

const DatePicker = ({ field, form: { touched, errors }, ...props }) => {
  const { labeltext, required } = props;

  const isInvalid =
    (touched[field.name] && errors[field.name]) ||
    (errors[field.name] && field.value == undefined);
  //const isInvalid = touched[field.name] && errors[field.name]
  let { value } = field;

  const inputProps = {
    ...field,
    value,
  };

  return (
    <React.Fragment>
      <Label>{getTranslation(labeltext)}</Label>
      <FormGroup>
        <DateTimePicker
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          time={false}
          {...inputProps}
          {...props}
          onChange={(val) => props.setFieldValue(field.name, val)}
        />
        {isInvalid && (
          <span style={{ color: "#f86c6b", fontSize: "80%" }}>
            {errors[field.name]}
          </span>
        )}
      </FormGroup>
    </React.Fragment>
  );
};

export default DatePicker;
