import {
  SHOW_SPINNER,
  SHOW_MAIN_SPINNER,
  HIDE_SPINNER,
  HIDE_MAIN_SPINNER,
  SHOW_ERROR_ALERT,
  DISMISS_ERROR_ALERT,
} from 'actions/spinnerActions';

export const showSpinner = () => ({
  type: SHOW_SPINNER,
});

export const hideSpinner = () => ({
  type: HIDE_SPINNER,
});

export const showMainSpinner = () => ({
  type: SHOW_MAIN_SPINNER,
});

export const hideMainSpinner = () => ({
  type: HIDE_MAIN_SPINNER,
});

export const showErrorAlert = error => ({
  type: SHOW_ERROR_ALERT,
  payload: error,
});

export const hideErrorAlert = () => ({
  type: DISMISS_ERROR_ALERT,
});
