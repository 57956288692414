import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import { Button } from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";

const TABLE_CODE = "company.list";

class CompanyList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    companyList: { data: [], count: 0 },
    loading: true,
  };

  componentDidMount() {
    this.getCompanyList();
  }

  getCompanyList() {
    this.setState({ loading: true });
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      "company",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(`api/${filter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
            this.setState({ companyList: data, loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Error while getting companies: ", error);
        this.setState({ loading: false });
      });
  }

  edit = (item) => {
    const { history } = this.props;
    history.push(`/app/company/${item.id}`);
  };

  create = () => {
    const { history } = this.props;
    history.push("/app/company/create");
  };

  createQRCode = (id) => {
    const { showErrorAlert } = this.props;
    const REPORT_ENDPOINT = `api/report/qrCode?reportId=${id}&type=company`;

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.pdf";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  render() {
    const { loading, companyList } = this.state;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      name: {
        field: "name",
        header: "company.table.header.name",
        properties: {},
      },
      code: {
        field: "code",
        header: "company.table.header.code",
        properties: {},
      },
      description: {
        field: "description",
        header: "company.table.header.description",
        properties: {},
      },
      parent: {
        field: "parent.name",
        header: "company.table.header.parent",
        properties: {},
      },
      buttonQRCode: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-qr-code",
          style: { width: "1em" },
          action: this.createQRCode,
        },
      },
    };

    return (
      <React.Fragment>
        <Button
          outline
          className="margin--bottom"
          color="primary"
          onClick={this.create}
        >
          {getTranslation("company.create.button.name")}
        </Button>
        <CustomTable
          data={companyList}
          tableMap={map}
          edit={this.edit}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getCompanyList()}
        />
      </React.Fragment>
    );
  }
}

export default CompanyList;
