import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { getTranslation } from "util/localisation";
import DropDownComponent from "../FormComponents/DropDownComponent";
import TextInput from "../FormComponents/TextInput";
import CustomProgress from "../Progress/CustomProgress";
import * as Yup from "yup";

const filter =
  '/autocomplete?columns=id&columns=name&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';

const CashCenterSchema = Yup.object().shape({
  code: Yup.string().required("Unesite kod!"),
  name: Yup.string().required("Unesite naziv!"),
  email: Yup.string().required("Unesite mail!"),
  address: Yup.string().required("Unesite adresu!"),
  city: Yup.object().required("Odaberite grad!"),
});

const CashCenterForm = ({
  initialValues,
  onSubmit,
  onDelete,
  onSubmitFail,
  history,
  backPath,
}) => (
  <Formik
    enableReinitialize
    validationSchema={CashCenterSchema}
    initialValues={initialValues}
    onSubmit={(values, actions) => {
      onSubmit(values)
        .then((r) => {
          if (r.status !== 200) {
            onSubmitFail();
          } else {
            history.push(backPath);
          }
          actions.setSubmitting(false);
        })
        .catch((e) => {
          console.error("Error saving cash center", e);
          onSubmitFail();
        });
    }}
    render={({ errors, status, touched, isSubmitting, setFieldValue }) => {
      const onAutocompleteOptionSelected = (suggestion) => {
        setFieldValue("city", suggestion);
      };
      return (
        <Row>
          <Col xs="12">
            <Form>
              {isSubmitting && <CustomProgress />}
              <Row>
                <Col md="6">
                  <Field
                    required
                    labeltext="cash.center.create.label.name"
                    name="name"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    required
                    labeltext="cash.center.create.label.email"
                    name="email"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    labeltext="cash.center.create.label.phone"
                    name="phone"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    labeltext="cash.center.create.label.fax"
                    name="fax"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    required
                    labeltext="cash.center.create.label.city"
                    name="city"
                    onselected={onAutocompleteOptionSelected}
                    tabledatabasename="city"
                    filter={filter}
                    showcolumn="name"
                    component={DropDownComponent}
                  />
                </Col>
                <Col md="6">
                  <Field
                    required
                    labeltext="cash.center.create.label.address"
                    name="address"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    required
                    labeltext="cash.center.create.label.code"
                    name="code"
                    component={TextInput}
                  />
                </Col>
              </Row>
              <Button
                className="margin--right"
                type="submit"
                disabled={isSubmitting}
              >
                {getTranslation("table.button.save")}
              </Button>
              {initialValues && (
                <Button
                  color="danger"
                  onClick={onDelete}
                  disabled={isSubmitting}
                >
                  {getTranslation("table.button.delete")}
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      );
    }}
  />
);

export default CashCenterForm;
