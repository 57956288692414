import {
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_ERROR,
} from '../actions/userActions';

const userState = {
  loading: true,
  companyEmployer: {},
  error: null,
  failLogin: false,
  filteredNavs: [],
};

export default (state = { userState }, action) => {
  switch (action.type) {
    case GET_USER_LOADING:
      return {
        ...state,
        userState: {
          ...state.userState,
          loading: true,
        },
      };

    case GET_USER_SUCCESS:
      console.log(action);
      return {
        ...state,
        userState: {
          ...state.userState,
          companyEmployer: action.payload.user,
          filteredNavs: action.payload.navs,
          loading: false,
          failLogin: false,
        },
      };

    case GET_USER_FAILURE:
      return {
        ...state,
        userState: {
          ...state.userState,
          loading: false,
          failLogin: true,
        },
      };

    case GET_USER_ERROR:
      return {
        ...state,
        userState: {
          error: action.error,
          loading: false,
          failLogin: true,
        },
      };

    default:
      return state;
  }
};
