export const getFilterURL = (table, filters, max, offset) => {
  const filtersString = JSON.stringify(filters);
  const fullURI = `${table}?filter=${filtersString}&max=${max}&offset=${offset}`;
  return fullURI;
};

export const getAutocompleteURL = (table, rowsToGet, filters) => {
  return `${table}/autocomplete?${rowsToGet}&filter=${JSON.stringify(filters)}`;
};

export const FilterManagerSingle = (table, filters) => {
  const filterString = JSON.stringify(filters);
  return `${table}?${filterString}`;
};

export const getEnumURL = enumName => {
  const enumPackage = 'hr.btc.enums';
  return `util/enumeration?enumName=${enumPackage}.${enumName}`;
};
