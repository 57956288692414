import FormCreator from 'components/FormComponents/FormCreator';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class CompanyAccountEdit extends Component {
  sendForm = form => {
    const { sendForm } = this.props;
    sendForm(form);
  };

  sendRequest = () => {
    const { sendRequest } = this.props;
    sendRequest();
  };

  delete = id => {
    const { deleteFunc } = this.props;
    deleteFunc(id);
  };

  render() {
    const { created, deleted, form, validation, edit } = this.props;

    const filters =
      '/autocomplete?columns=id&columns=name&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';

    const filters2 =
      '/autocomplete?columns=id&accountNumber=name&filter={ "search":{"type":"string","key":"accountNumber","value":';

    const formFieldInfo = [
      {
        localisationName: 'companyAccounts.create.label.company',
        databaseName: 'company',
        validation: 'companyAccounts.create.validation.company',
        type: 'autocomplete',
        tableDatabaseName: 'company',
        filters,
        showCollumn: 'name',
      },
      {
        localisationName: 'companyAccounts.create.label.account',
        databaseName: 'account',
        validation: 'companyAccounts.create.validation.account',
        type: 'autocomplete',
        tableDatabaseName: 'bankAccount',
        filters: filters2,
        showCollumn: 'accountNumber',
      },
    ];

    if (created || deleted) {
      return <Redirect to="/app/companyAccounts" />;
    }

    return (
      <div>
        <FormCreator
          formFieldInfo={formFieldInfo}
          formStructure={form}
          validation={validation}
          handleChange={this.sendForm}
          handleSubmit={this.sendRequest}
          edit={edit}
          handleDelete={this.delete}
          inputLabel="companyAccounts.create.button.name"
        />
      </div>
    );
  }
}

export default CompanyAccountEdit;
