import React, { useEffect, useState } from "react";
import "./CustomCircularProgress.scss";

const CustomCircularProgress = ({
  start,
  onFinish,
  duration,
  label = undefined,
}) => {
  const [remaining, setRemaining] = useState(duration);
  const [circleDasharray, setCircleDasharray] = useState("283");
  const [intervalId, setIntervalId] = useState("283");

  const calculateTimeFraction = (remaining) => {
    const rawTimeFraction = remaining / duration;
    return rawTimeFraction - (1 / duration) * (1 - rawTimeFraction);
  };

  const updateCircleDasharray = (remaining) => {
    setCircleDasharray(
      `${(calculateTimeFraction(remaining) * 283).toFixed(0)} 283`
    );
  };

  useEffect(() => {
    updateCircleDasharray(remaining);
    if (remaining < 0) {
      clearInterval(intervalId);
      setRemaining(duration);
      if (onFinish) onFinish();
    }
  }, [remaining]);

  useEffect(() => {
    if (start === true) {
      const intervalId = setInterval(() => {
        setRemaining((sseconds) => sseconds - 1);
      }, 1000);
      setIntervalId(intervalId);
    } else if (start === false) {
      setRemaining(duration);
    }
  }, [start]);

  return (
    <div>
      {label && <h5 style={{ textAlign: "center" }}>{label}</h5>}
      <div className="base-timer" style={{ margin: "0 auto 0 auto" }}>
        <svg
          className="base-timer__svg"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className="base-timer__circle">
            <circle
              className="base-timer__path-elapsed"
              cx="50"
              cy="50"
              r="45"
            />
            <path
              id="base-timer-path-remaining"
              strokeDasharray={circleDasharray}
              className="base-timer__path-remaining"
              d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
            ></path>
          </g>
        </svg>
        <span className="base-timer__label">{remaining}</span>
      </div>
    </div>
  );
};

export default CustomCircularProgress;
