import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import DropDownComponent from "../FormComponents/DropDownComponent";

const TABLE_CODE = "task.list";

class TaskList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    taskList: { data: [], count: 0 },
    loading: true,
    showTodaysTasksOnly: true,
    cashCenter: undefined,
    currency: undefined,
  };

  componentDidMount() {
    this.getTaskList();
  }

  getTaskList() {
    this.setState({ loading: true });
    const { showErrorAlert } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const { showTodaysTasksOnly } = this.state;
    const filter = getFilterURL(
      "task",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(`api/${filter}&showTodaysTasksOnly=${showTodaysTasksOnly}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({ taskList: data, loading: false });
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Error while getting tasks: ", error);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  }

  edit = (item) => {
    const { history } = this.props;
    history.push(`/app/task/${item.id}`);
  };

  addNewTask = () => {
    const { history } = this.props;
    history.push("/app/task/create");
  };

  showGCState = () => {
    this.setState({ isGCModalOpen: true });
  };

  onGCModalClose = () => {
    this.setState({ isGCModalOpen: false, currency: undefined });
  };

  openGCStatePDF = () => {
    this.setState({ loading: true });
    const { cashCenter, currency } = this.state;
    let REPORT_URL = `api/report/vaultReport?cashCenterId=${cashCenter.id}`;
    if (currency) {
      REPORT_URL += `&currencyId=${currency.id}`;
    }
    fetch(REPORT_URL, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "state.pdf";
        a.target = "_blank";
        a.click();
        a.remove();
        this.setState({ loading: false });
        this.onGCModalClose();
      })
      .catch((e) => {
        console.error("error : ", e);
        this.setState({ loading: false });
      });
  };

  setCashCenter = (cashCenter) => {
    this.setState({ cashCenter: cashCenter });
  };

  setCurrency = (currency) => {
    this.setState({ currency: currency });
  };

  processTask = (id) => {
    const { history } = this.props;
    history.push(`/app/task/${id}/process`);
  };

  cancelTask = (id) => {
    this.setState({ loading: true });
    fetch(`api/task/cancelTask?taskId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            if (data.task) {
              const { history } = this.props;
              history.push(`/app/task/${data.task.id}`);
            }
          });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while cancelling task", e);
        this.setState({ loading: false });
      });
  };

  createReport = (id) => {
    const { showErrorAlert } = this.props;
    const REPORT_ENDPOINT = `api/report/taskReport?reportId=${id}`;

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.pdf";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  render() {
    const { userInfo } = this.props;
    const {
      loading,
      taskList,
      showTodaysTasksOnly,
      isGCModalOpen,
      cashCenter,
      currency,
    } = this.state;

    const cashCenterFilter =
      '/autocomplete?columns=id&columns=code&columns=name&filter={"active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';

    const currencyFilter =
      '/autocomplete?columns=id&columns=code&columns=name&filter={"active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"code","value":';

    if (userInfo && userInfo.companyEmployer) {
      if (
        cashCenter === undefined &&
        userInfo.companyEmployer.defaultCashCenter
      ) {
        this.setState({
          cashCenter: userInfo.companyEmployer.defaultCashCenter,
        });
      }
      // if (currency === undefined && userInfo.companyEmployer.defaultCurrency) {
      //   this.setState({ currency: userInfo.companyEmployer.defaultCurrency });
      // }
    }

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      processed: {
        field: "processed",
        header: "task.table.header.processed",
        properties: { type: "boolean" },
        message: {
          field: "cancelled",
          values: [true, 1],
          message: getTranslation("task.table.message.cancelled"),
        },
      },
      code: { field: "code", header: "task.table.header.code", properties: {} },
      cashCenter: {
        field: "cashCenter.name",
        header: "task.table.header.cash.center",
        properties: {},
      },
      taskDate: {
        field: "taskDate",
        header: "task.table.header.date",
        properties: { type: "date" },
      },
      company: {
        field: "company.name",
        header: "task.table.header.company",
        properties: {},
      },
      type: {
        field: "type.name",
        header: "task.table.header.task.type",
        properties: {},
      },
      direction: {
        field: "directionText",
        header: "task.table.header.direction",
        properties: {},
      },
      buttonProcess: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-check",
          style: { width: "1em" },
          action: this.processTask,
          show: [{ field: "processed", values: [false, 0] }],
        },
      },
      buttonReport: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-file",
          style: { width: "1em" },
          action: this.createReport,
          show: [{ field: "processed", values: [true, 1] }],
        },
      },
      buttonCancel: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-x",
          style: { width: "1em" },
          action: this.cancelTask,
          show: [
            { field: "processed", values: [true, 1] },
            { field: "cancelled", values: [false, 0] },
          ],
        },
      },
    };

    return (
      <React.Fragment>
        <Modal isOpen={isGCModalOpen} className="custommodal--small">
          <ModalHeader toggle={this.onGCModalClose}>
            <span>{getTranslation("task.create.button.cashCenter")}</span>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <DropDownComponent
                  field={{
                    name: "cashCenter",
                    value: cashCenter,
                    onChange: (e, { newValue }) => this.setCashCenter(newValue),
                  }}
                  form={{ errors: {}, touched: {} }}
                  labeltext="task.table.header.cash.center"
                  onselected={(suggestion) => this.setCashCenter(suggestion)}
                  tabledatabasename="cashCenter"
                  filter={cashCenterFilter}
                  showcolumn="name"
                />
              </Col>
              <Col>
                <DropDownComponent
                  field={{
                    name: "currency",
                    value: currency,
                    onChange: (e, { newValue }) => this.setCurrency(newValue),
                  }}
                  form={{ errors: {}, touched: {} }}
                  labeltext="task.table.header.currency"
                  onselected={(suggestion) => this.setCurrency(suggestion)}
                  tabledatabasename="currency"
                  filter={currencyFilter}
                  showcolumn="code"
                />
              </Col>
            </Row>
            <Button
              outline
              className="margin--bottom"
              color="primary"
              onClick={this.openGCStatePDF}
              style={{ float: "right" }}
            >
              {getTranslation("task.create.button.pdf")}
            </Button>
          </ModalBody>
        </Modal>
        <Row>
          <Button
            outline
            className="margin--bottom"
            color="primary"
            onClick={this.addNewTask}
          >
            {getTranslation("task.create.button.name")}
            {isGCModalOpen}
          </Button>
          <FormGroup check inline style={{ marginLeft: "1em" }}>
            <Label check>
              <Input
                type="checkbox"
                checked={showTodaysTasksOnly}
                onChange={(e) => {
                  this.setState(
                    { showTodaysTasksOnly: e.target.checked },
                    this.getTaskList
                  );
                }}
              />
              {getTranslation("specification.list.showOnlyToday")}
            </Label>
          </FormGroup>
          <Button
            outline
            className="margin--bottom"
            color="primary"
            onClick={this.showGCState}
            style={{ position: "absolute", right: "20px" }}
          >
            {getTranslation("task.create.button.cashCenter")}
          </Button>
        </Row>

        <CustomTable
          data={taskList}
          tableMap={map}
          edit={this.edit}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getTaskList()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
