import { getTranslation } from 'util/localisation';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

class BankAccountEdit extends Component {
  sendRequest = () => {
    const { sendRequest } = this.props;
    sendRequest();
  };

  delete = (e, id) => {
    const { deleteFunc } = this.props;
    deleteFunc(id);
  };

  closeModal = () => {
    const { modalDown } = this.props;
    modalDown();
  };

  sendChange = e => {
    const { form, sendForm } = this.props;

    for (let i in form) {
      if (i == e.target.name) {
        form[i] = e.target.value;
      }
    }
    sendForm(form);
  };

  render() {
    const { created, deleted, validation, form, edit } = this.props;
    if (created || deleted) {
      return <Redirect to="/app/bankAccount" />;
    }

    const accountNumber = 'accountNumber';

    const invalidProp = {
      invalid: true,
    };

    return (
      <div>
        <div className="header_specification">
          <h2> {getTranslation('specification.create.label.top')}</h2>
          <Button onClick={e => this.closeModal(e)} color="danger">
            xjnbkb
          </Button>
        </div>
        <div className="animated fadeIn tableButtonMargins ">
          <Row>
            <Col xs="12">
              <Form className="customForm border border-light p-5">
                <h2 className="tableButtonMargins">-test </h2>

                <div>
                  <Label>-- localisatioName</Label>
                  <FormGroup>
                    <Input
                      {...(validation[accountNumber] ? undefined : invalidProp)}
                      type="text"
                      name={accountNumber}
                      id={accountNumber}
                      value={form[accountNumber]}
                      onChange={e => this.sendChange(e)}
                    />
                    {validation[accountNumber] ? (
                      undefined
                    ) : (
                      <FormFeedback>---- validation</FormFeedback>
                    )}
                  </FormGroup>
                </div>

                <div>
                  <Button
                    className=""
                    onClick={e => this.sendRequest(e)}
                    color="success"
                  >
                    {getTranslation('country.create.label.create')}
                  </Button>
                  {edit ? (
                    <Button
                      color="danger"
                      className="margin--left"
                      onClick={e => this.delete(e, form.id)}
                    >
                      {getTranslation('table.button.delete')}
                    </Button>
                  ) : (
                    undefined
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default BankAccountEdit;
