import React, { useState, useEffect } from "react";
import { Button, FormGroup, Label, Input, Table } from "reactstrap";
import DropDownComponent from "components/FormComponents/DropDownComponent";
import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomProgress from "../Progress/CustomProgress";
import { getTranslation } from "util/localisation";
import "../Table/CustomTable.scss";

const CURRENCY_LOCALE = ["hr-HR", { minimumFractionDigits: 2 }];

const currencyFilter =
  '/autocomplete?columns=id&columns=code&columns=name&filter={"search":{"type":"string","key":"name","value":';

const VaultState = ({ cashCenterId }) => {
  const [vaultItems, setVaultItems] = useState([]);
  const [currency, setCurrency] = useState(undefined);
  const [allCurrencies, setAllCurrencies] = useState(true);
  const [generatingReport, setGeneratingReport] = useState(false);

  const getVaultItems = () => {
    let URL = `api/vault?cashCenterId=${cashCenterId}`;
    if (!allCurrencies && currency) {
      URL = `${URL}&currencyId=${currency.id}`;
    }
    fetch(URL, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((data) => {
          setVaultItems(data.data);
        });
      }
    });
  };

  const createReport = () => {
    setGeneratingReport(true);
    const REPORT_URL = `api/report/vaultReport?cashCenterId=${cashCenterId}${
      currency instanceof Object ? `&currencyId=${currency.id}` : ""
    }`;

    fetch(REPORT_URL, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "download.pdf";
        a.target = "_blank";
        a.click();
        a.remove();
        setGeneratingReport(false);
      })
      .catch((e) => {
        console.error("error : ", e);
        setGeneratingReport(false);
      });
  };

  useEffect(() => {
    getVaultItems();
  }, [cashCenterId, currency]);

  return (
    <React.Fragment>
      {generatingReport && <CustomProgress />}
      <h2>Stanje trezora</h2>
      <DropDownComponent
        disabled={allCurrencies}
        labeltext="specification.create.currency"
        onselected={(suggestion) => setCurrency(suggestion)}
        tabledatabasename="currency"
        filter={currencyFilter}
        showcolumn="name"
        field={{
          name: "currency",
          value: currency,
          onChange: (e, { newValue }) => setCurrency(newValue),
        }}
        form={{ errors: {}, touched: {} }}
      />
      <FormGroup check>
        <Label check>
          <Input
            checked={allCurrencies}
            type="checkbox"
            onChange={(e) => {
              setAllCurrencies(e.target.checked);
              setCurrency(undefined);
            }}
          />{" "}
          Sve valute
        </Label>
      </FormGroup>
      <Table striped responsive hover size="sm" bordered>
        <thead>
          <tr>
            <th>{getTranslation(`vault.state.currency`)}</th>
            <th>{getTranslation(`vault.state.value`)}</th>
            <th>{getTranslation(`vault.state.quality`)}</th>
            <th>{getTranslation(`vault.state.series`)}</th>
            <th>{getTranslation(`vault.state.status`)}</th>
            <th>{getTranslation(`vault.state.amount`)}</th>
            <th>{getTranslation(`vault.state.totals`)}</th>
          </tr>
        </thead>
        <tbody>
          {vaultItems.map((item) => {
            return (
              <tr>
                <td>{item.currency.name}</td>
                <td>
                  {new Intl.NumberFormat(...CURRENCY_LOCALE).format(
                    item.currencyDenomination.value
                  )}
                </td>
                <td>
                  {getTranslation(`specificationItem.quality.${item.quality}`)}
                </td>
                <td>{item.currencySeries.name}</td>
                <td>
                  {item.status != undefined &&
                    getTranslation(`specificationItem.status.${item.status}`)}
                </td>
                <td>{item.amount}</td>
                <td>
                  {new Intl.NumberFormat(...CURRENCY_LOCALE).format(
                    item.currencyDenomination.value * item.amount
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button onClick={createReport}>Izradi izvještaj</Button>
    </React.Fragment>
  );
};

export default VaultState;
