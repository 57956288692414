import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { getTranslation } from 'util/localisation';
import DropDownComponent from './DropDownComponent';
import EnumeratorComponent from './EnumeratorComponent';
import TextInput from './TextInput';

export default class FormCreator extends Component {
  sendChange = e => {
    const { formStructure, handleChange } = this.props;
    const { name, value } = e.target;
    formStructure[name] = value;
    handleChange(formStructure);
  };

  sendAutoComplete = (value, name) => {
    const { formStructure, handleChange } = this.props;
    formStructure[name] = value;
    handleChange(formStructure);
  };

  changeEnumerator = e => {
    const { formStructure, handleChange } = this.props;
    const { name, value } = e.target;
    formStructure[name] = value;
    handleChange(formStructure);
  };

  delete = (e, id) => {
    const { handleDelete } = this.props;
    handleDelete(id);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { handleSubmit } = this.props;
    handleSubmit();
  };

  render() {
    const {
      validation,
      formFieldInfo,
      inputLabel,
      formStructure,
      isEditing,
      backPath,
      history,
    } = this.props;

    return (
      <div className="animated fadeIn tableButtonMargins ">
        <Row>
          <Col xs="12">
            {backPath && (
              <Button onClick={() => history.push(backPath)}>Natrag</Button>
            )}

            <Form className="customForm border p-3">
              <h2 className="tableButtonMargins">
                {getTranslation(inputLabel)}
              </h2>
              <Row>
                {formFieldInfo.map(inputField => {
                  if (
                    inputField.type === 'text' ||
                    inputField.type === 'password'
                  ) {
                    return (
                      <Col md="6" key={inputField.databaseName}>
                        <TextInput
                          inputField={inputField}
                          validation={validation}
                          currentForm={formStructure}
                          change={e => this.sendChange(e)}
                        />
                      </Col>
                    );
                  }
                  if (inputField.type === 'autocomplete') {
                    return (
                      <Col md="6" key={inputField.databaseName}>
                        <DropDownComponent
                          inputField={inputField}
                          validation={validation}
                          currentForm={formStructure}
                          change={(value, type) =>
                            this.sendAutoComplete(value, type)
                          }
                        />
                      </Col>
                    );
                  }
                  if (inputField.type === 'enum') {
                    return (
                      <Col md="6" key={inputField.databaseName}>
                        <EnumeratorComponent
                          currentForm={formStructure}
                          change={e => this.changeEnumerator(e)}
                          inputField={inputField}
                        />
                      </Col>
                    );
                  }
                })}
              </Row>

              <div>
                <Button
                  outline
                  onClick={e => this.handleSubmit(e)}
                  color="success"
                >
                  {getTranslation('country.create.label.create')}
                </Button>
                {isEditing ? (
                  <Button
                    outline
                    color="danger"
                    className="margin--left"
                    onClick={e => this.delete(e, formStructure.id)}
                  >
                    {getTranslation('table.button.delete')}
                  </Button>
                ) : (
                  undefined
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

FormCreator.propTypes = {
  formFieldInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
  formStructure: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
  ).isRequired,
  validation: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
};
