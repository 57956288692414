import { PostApiCall } from './Get';

export default {
  logIn(auth, username) {
    localStorage.auth = auth;
    localStorage.username = username;
  },

  logOut() {
    delete localStorage.auth;
    window.location.reload();
  },

  loggedIn() {
    // test dali je nas token ok, ako nije treba ga refreshati
  },

  loginAsk(loginCredentials) {
    const testUser = {
      username: loginCredentials.username,
      password: loginCredentials.password,
      otp: loginCredentials.otp,
    };

    const testUserString = JSON.stringify(testUser);

    const Params = {
      path: 'login',
      bodyContent: testUserString,
    };

    return PostApiCall(Params);
  },
};
