import CustomTable from 'components/Table/CustomTable';
import { getTranslation } from 'util/localisation';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import CustomSpinner from 'components/Spinner/CustomSpinner';

class CompanyAccountList extends Component {
  componentDidMount() {
    const { getCountries } = this.props;
    getCountries();
  }

  changeCurrentPage = nextPage => {
    const { changeNextPage } = this.props;
    changeNextPage(nextPage);
  };

  changeMaxItems = maxItems => {
    const { changeMaxItems } = this.props;
    changeMaxItems(maxItems);
  };

  edit = item => {
    const { editPrepare } = this.props;
    editPrepare(item);
  };

  reset = () => {
    const { postReset } = this.props;
    postReset();
  };

  render() {
    const { loading, tableInfo, countries } = this.props;

    const map = {
      id: { field: 'id', header: 'tables.id', properties: {} },
      company: {
        field: 'company.name',
        header: 'companyAccount.table.header.company',
        properties: {},
      },
      account: {
        field: 'account.accountNumber',
        header: 'companyAccount.table.header.account',
        properties: {},
      },
    };

    return (
      <div className="border border-light p-5 tableButtonMargins">
        <h2>{getTranslation('navbar.companyAccounts')}</h2>

        <Button
          className="tableButtonMargins"
          color="primary"
          onClick={this.reset}
        >
          {getTranslation('companyAccounts.create.button.name')}
        </Button>
        <CustomTable
          currentPage={tableInfo.currentPage}
          maxPages={tableInfo.maxPages}
          maxItems={tableInfo.maxItems}
          data={countries}
          tableMap={map}
          edit={this.edit}
          changeCurrentPage={this.changeCurrentPage}
          changeMaxItems={this.changeMaxItems}
        />
      </div>
    );
  }
}

export default CompanyAccountList;
