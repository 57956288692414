import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { FormGroup, Label } from 'reactstrap';
import { GetApiCallFiltersBearer } from 'util/api/Get';
import { getTranslation } from 'util/localisation';

const DropDownComponent = ({ field, form: { touched, errors }, ...props }) => {
  const {
    showcolumn,
    tabledatabasename,
    filter,
    labeltext,
    required,
    onselected,
    suggestionFilter,
  } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getSuggestionValue = suggestion => {
    return suggestion[showcolumn];
  };

  const renderSuggestion = suggestion => {
    return <span>{suggestion[showcolumn]}</span>;
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const filters = `${filter}"${value}"}}`;

    GetApiCallFiltersBearer(tabledatabasename, filters).then(response => {
      let filteredSuggestion = response;
      if (suggestionFilter) {
        filteredSuggestion = response.filter(suggestionFilter);
      }
      setSuggestions(filteredSuggestion);
    });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    onselected(suggestion);
  };

  const shouldRenderSuggestions = value => {
    return value.length >= 0;
  };

  const isInvalid = touched[field.name] && errors[field.name];

  const theme = {
    container: 'autosuggest',
    input: isInvalid ? 'form-control is-invalid' : 'form-control',
    suggestionsContainer: 'dropdown',
    suggestionsList: `dropdown-menu ${suggestions.length ? 'show' : ''}`,
    suggestion: 'dropdown-item',
    suggestionFocused: 'active',
  };

  useEffect(() => {
    let { value } = field;
    if (value instanceof Object) {
      const objectAccessor = showcolumn.split('.');
      while (objectAccessor.length && (value = value[objectAccessor.shift()]));
      setInputValue(value);
    } else if (value || value === '') {
      setInputValue(value);
    }
  }, [field]);

  const { disabled } = props;

  const inputProps = {
    ...field,
    value: inputValue,
    disabled,
  };

  return (
    <div>
      {required && '*'}
      {labeltext && <Label>{getTranslation(labeltext)}</Label>}
      <FormGroup>
        <Autosuggest
          inputProps={inputProps}
          {...props}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          shouldRenderSuggestions={shouldRenderSuggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={renderSuggestion}
          theme={theme}
        />
        {isInvalid && (
          <div className="custom--invalid--feedback">{errors[field.name]}</div>
        )}
      </FormGroup>
    </div>
  );
};

export default DropDownComponent;
