import CustomTable from 'components/Table/CustomTable';
import { getTranslation } from 'util/localisation';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import CustomSpinner from 'components/Spinner/CustomSpinner';

class BankAccountList extends Component {
  componentDidMount() {
    const { getCountries } = this.props;
    getCountries();
  }

  changeCurrentPage = nextPage => {
    const { changeNextPage } = this.props;
    changeNextPage(nextPage);
  };

  changeMaxItems = maxItems => {
    const { changeMaxItems } = this.props;
    changeMaxItems(maxItems);
  };

  edit = item => {
    const { editPrepare, history } = this.props;
    editPrepare(item);
    history.push('/app/bankAccount/create');
  };

  reset = () => {
    const { postReset, history } = this.props;
    postReset();
    history.push('/app/bankAccount/create');
  };

  render() {
    const { loading, tableInfo, countries } = this.props;

    const map = {
      id: { field: 'id', header: 'tables.id', properties: {} },
      accountNumber: {
        field: 'accountNumber',
        header: 'bankAccount.table.header.accountNumber',
        properties: {},
      },
    };

    return (
      <div className="border p-3 tableButtonMargins">
        <h2>{getTranslation('navbar.accountNumber')}</h2>

        <Button
          className="tableButtonMargins"
          color="primary"
          onClick={this.reset}
        >
          {getTranslation('bankAccount.create.button.name')}
        </Button>

        <CustomTable
          currentPage={tableInfo.currentPage}
          maxPages={tableInfo.maxPages}
          maxItems={tableInfo.maxItems}
          data={countries}
          tableMap={map}
          edit={this.edit}
          changeCurrentPage={this.changeCurrentPage}
          changeMaxItems={this.changeMaxItems}
        />
      </div>
    );
  }
}

export default BankAccountList;
