import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import CustomTable from "components/Table/CustomTable";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { getAutocompleteURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import DatePicker from "../FormComponents/DatePicker";
import DropDownComponent from "../FormComponents/DropDownComponent";
import EnumeratorComponent from "../FormComponents/EnumeratorComponent";
import TextInput from "../FormComponents/TextInput";
import CustomSpinner from "../Spinner/CustomSpinner";
import CustomProgress from "../Progress/CustomProgress";

const TABLE_CODE_SHIPMENT = "task.shipment.list";

const MAIN_COMPANY_TYPE = 0;
const BRANCH_COMPANY_TYPE = 1;

class TaskEdit extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE_SHIPMENT)) {
      localStorage.setItem(
        TABLE_CODE_SHIPMENT,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    cashCenter: null,
    company: null,
    taskData: {
      taskDate: new Date(),
      shipmentCode: 1,
    },
    loading: true,
    shipments: [],
  };

  componentDidMount() {
    const { match } = this.props;
    const { id, specificationId } = match.params;

    if (id) {
      this.getTask(id);
    } else if (specificationId) {
      this.getSpecification(specificationId);
    } else {
      this.generateTaskCode();
    }
  }

  generateClientAutocompleteURL = (type, parent, key) => {
    const columns = "columns=id&columns=name";
    const filters = {
      active: {
        type: "boolean",
        key: "active",
        value: "true",
      },
      type: {
        type: "integer",
        key: "type",
        value: type,
      },
    };

    if (parent) {
      filters.parent = {
        type: "long",
        key: "parent.id",
        value: parent.id,
      };
    }

    var url = getAutocompleteURL("", columns, filters);
    if (key) {
      url =
        url.slice(0, -1) +
        ', "search":{"type":"string","key":"' +
        key +
        '","value":';
    }
    return url;
  };

  isAddShipmentButtonDisabled = (values) => {
    const { shipmentCompany, shipmentCode } = values;
    return !shipmentCompany || !shipmentCode;
  };

  addShipmentToTask = (values) => {
    const { shipmentSpecification, shipmentCompany, shipmentCode } = values;
    const { taskData } = this.state;

    const shipment = {
      code: shipmentCode,
      company: shipmentCompany,
      specification: shipmentSpecification,
      shipmentDate: new Date(),
    };
    this.setState((prevState) => ({
      shipments: [...prevState.shipments, shipment],
      taskData: {
        ...values,
        shipmentSpecification: "",
        shipmentCompany: "",
        shipmentCode: +shipmentCode + 1,
      },
    }));
  };

  getTask = (id) => {
    this.setState({ loading: true });
    fetch(`api/task/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState(
              {
                taskData: formData,
                company: formData.company,
                cashCenter: formData.cashCenter,
                shipments: formData.shipments ? formData.shipments : [],
              },
              this.generateShipmentCode
            );
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting task", e);
        this.setState({ loading: false });
      });
  };

  getSpecification = (id) => {
    const { showErrorAlert } = this.props;
    this.setState({ loading: true });
    fetch(`api/specification/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            const tempTaskData = {
              cashCenter: formData.companyBranch.cashCenter,
              company: formData.company,
              taskDate: new Date(),
              shipmentCode: 2,
              direction: formData.orderRoute === 0 ? 1 : 0,
            };
            const tempShipmentData = {
              company: formData.companyBranch,
              code: 1,
              specification: formData,
              shipmentDate: new Date(),
            };
            this.setState(
              {
                taskData: tempTaskData,
                shipments: [tempShipmentData],
              },
              this.generateTaskCode
            );
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting specification", e);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  };

  deleteTask = () => {
    const { match, history, showErrorAlert } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/task/${id}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      })
        .then((r) => {
          if (r.status === 200) {
            history.push("/app/task");
          } else {
            showErrorAlert(getTranslation("api.error.deleting"));
          }
        })
        .catch((e) => {
          console.error("Error deleting task", e);
          showErrorAlert(getTranslation("api.error.deleting"));
        });
    }
  };

  removeItem = (row) => {
    const { shipments } = this.state;

    const newShipments = shipments.filter(
      (item) =>
        (item.id && item.id !== row.id) || (!item.id && item.code !== row.code)
    );
    this.setState({ shipments: newShipments });
  };

  generateTaskCode() {
    const { showErrorAlert } = this.props;
    const { taskData } = this.state;
    this.setState({ loading: true });
    fetch(`api/task/generateCode`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const newTaskData = {
              ...taskData,
              code: data.code,
            };
            this.setState({ taskData: newTaskData, loading: false });
          });
        } else {
          showErrorAlert(getTranslation("api.error.generatingCode"));
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting task code", e);
        showErrorAlert(getTranslation("api.error.generatingCode"));
        this.setState({ loading: false });
      });
  }

  generateShipmentCode() {
    const { showErrorAlert } = this.props;
    const { taskData } = this.state;
    this.setState({ loading: true });
    fetch(`api/highValueShipment/generateCode?taskId=${taskData.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            const newTaskData = {
              ...taskData,
              shipmentCode: data.code,
            };
            this.setState({ taskData: newTaskData, loading: false });
          });
        } else {
          showErrorAlert(getTranslation("api.error.generatingCode"));
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting shipment code", e);
        showErrorAlert(getTranslation("api.error.generatingCode"));
        this.setState({ loading: false });
      });
  }

  saveTask(formData) {
    const { match } = this.props;
    const { id } = match.params;
    const { shipments } = this.state;
    const data = {
      ...formData,
      shipments,
    };
    if (id) {
      // editing
      const url = `api/task/${id}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(data),
      });
    }
    // new save
    const url = "api/task";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(data),
    });
  }

  render() {
    const { userInfo, history, showErrorAlert } = this.props;
    const { taskData, loading, shipments } = this.state;
    const backPath = "/app/task";

    const specificationFilter =
      '/autocomplete?columns=id&columns=code&columns=code&columns=companyBranch.id&columns=companyBranch.name&processed=false&&specificationType=1&&filter={"active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"code","value":';
    const deliveryVehicleFilter =
      '/autocomplete?columns=id&columns=code&columns=name&filter={"active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';
    const cashCenterFilter =
      '/autocomplete?columns=id&columns=code&columns=name&filter={"active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';
    const taskTypeFilter =
      '/autocomplete?columns=id&columns=code&columns=name&filter={"search":{"type":"string","key":"name","value":';

    const mapShipment = {
      code: {
        field: "code",
        header: "shipment.table.header.code",
        properties: {},
      },
      company: {
        field: "company.name",
        header: "shipment.table.header.company",
        properties: {},
      },
      shipmentDate: {
        field: "shipmentDate",
        header: "shipment.table.header.date",
        properties: { type: "date" },
      },
      specification: {
        field: "specification.code",
        header: "shipment.table.header.specification",
        properties: {},
      },
      buttonRemove: {
        properties: {
          disabled: taskData.processed,
          type: "button",
          text: "",
          icon: "cil-backspace",
          style: { width: "1em" },
          action: this.removeItem,
          returnRow: true,
        },
      },
    };

    if (loading) {
      return <CustomSpinner />;
    }

    let transformedTaskData = null;

    let defaultCashCenter = null;
    let defaultClient = null;

    if (userInfo.companyEmployer) {
      if (
        userInfo.companyEmployer.defaultCashCenter &&
        userInfo.companyEmployer.defaultCashCenter.id
      ) {
        defaultCashCenter = userInfo.companyEmployer.defaultCashCenter;
      }
      if (
        userInfo.companyEmployer.defaultClient &&
        userInfo.companyEmployer.defaultClient.id
      ) {
        defaultClient = userInfo.companyEmployer.defaultClient;
      }
    }

    if (taskData) {
      transformedTaskData = {
        ...taskData,
        taskDate: new Date(taskData.taskDate),
        cashCenter:
          this.state.cashCenter != null
            ? this.state.cashCenter
            : defaultCashCenter,
        company:
          this.state.company != null ? this.state.company : defaultClient,
      };
    }

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>
        <Formik
          enableReinitialize
          initialValues={transformedTaskData}
          onSubmit={(values, actions) => {
            this.saveTask(values).then((response) => {
              if (response.status === 200) {
                history.push(backPath);
              } else {
                showErrorAlert(getTranslation("api.error.saving"));
              }
              actions.setSubmitting(false);
            });
          }}
          render={({
            errors,
            status,
            touched,
            isSubmitting,
            setFieldValue,
            values,
          }) => {
            const onAutocompleteOptionSelectedCompany = (suggestion) => {
              setFieldValue("company", suggestion);
              this.setState({ company: suggestion });
            };
            const onAutocompleteOptionSelectedCompanyBranch = (suggestion) => {
              setFieldValue("shipmentCompany", suggestion);
            };
            const onAutocompleteOptionSelectedSpecification = (suggestion) => {
              setFieldValue("shipmentSpecification", suggestion);
              if (suggestion && suggestion["companyBranch.id"]) {
                setFieldValue("shipmentCompany", {
                  id: suggestion["companyBranch.id"],
                  name: suggestion["companyBranch.name"],
                });
              }
            };
            const onAutocompleteOptionSelectedCashCenter = (suggestion) => {
              setFieldValue("cashCenter", suggestion);
              this.setState({ cashCenter: suggestion });
            };
            const onAutocompleteOptionSelectedType = (suggestion) => {
              setFieldValue("type", suggestion);
            };
            const onAutocompleteOptionSelectedVehicle = (suggestion) => {
              setFieldValue("vehicle", suggestion);
            };
            return (
              <Row>
                <Col xs="12">
                  <Form className="form--container">
                    {isSubmitting && <CustomProgress />}
                    <h4>{getTranslation("task.create.header")}</h4>
                    <Row>
                      <Col md="6">
                        <Field
                          disabled={taskData.processed === true}
                          labeltext="task.create.label.code"
                          name="code"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={taskData.processed === true}
                          labeltext="task.create.label.date"
                          name="taskDate"
                          component={DatePicker}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={taskData.processed === true}
                          labeltext="task.table.header.cash.center"
                          name="cashCenter"
                          onselected={onAutocompleteOptionSelectedCashCenter}
                          tabledatabasename="cashCenter"
                          filter={cashCenterFilter}
                          showcolumn="name"
                          component={DropDownComponent}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={taskData.processed === true}
                          labeltext="task.table.header.company"
                          name="company"
                          onselected={onAutocompleteOptionSelectedCompany}
                          tabledatabasename="company"
                          filter={this.generateClientAutocompleteURL(
                            MAIN_COMPANY_TYPE,
                            null,
                            "name"
                          )}
                          showcolumn="name"
                          component={DropDownComponent}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={taskData.processed === true}
                          labeltext="task.table.header.delivery.vehicle"
                          name="vehicle"
                          onselected={onAutocompleteOptionSelectedVehicle}
                          tabledatabasename="deliveryVehicle"
                          filter={deliveryVehicleFilter}
                          showcolumn="name"
                          component={DropDownComponent}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={taskData.processed === true}
                          labeltext="task.table.header.task.type"
                          name="type"
                          onselected={onAutocompleteOptionSelectedType}
                          tabledatabasename="taskType"
                          filter={taskTypeFilter}
                          showcolumn="name"
                          component={DropDownComponent}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={taskData.processed === true}
                          labeltext="task.table.header.direction"
                          enumname="TaskDirection"
                          onselected={(value) =>
                            setFieldValue("direction", value)
                          }
                          name="direction"
                          component={EnumeratorComponent}
                        />
                      </Col>
                    </Row>
                    <hr
                      style={{ border: "1px solid #c8ced3", width: "100%" }}
                    />
                    <h4>{getTranslation("task.create.shipment")}</h4>
                    {!taskData.processed && (
                      <React.Fragment>
                        <Row>
                          <Col md="6">
                            <Field
                              labeltext="shipment.create.label.code"
                              name="shipmentCode"
                              component={TextInput}
                            />
                          </Col>
                          <Col md="6">
                            <Field
                              labeltext="shipment.table.header.specification"
                              name="shipmentSpecification"
                              onselected={
                                onAutocompleteOptionSelectedSpecification
                              }
                              tabledatabasename="specification"
                              filter={specificationFilter}
                              showcolumn="code"
                              component={DropDownComponent}
                            />
                          </Col>
                          <Col md="6">
                            <Field
                              labeltext="shipment.table.header.company"
                              name="shipmentCompany"
                              onselected={
                                onAutocompleteOptionSelectedCompanyBranch
                              }
                              tabledatabasename="company"
                              filter={this.generateClientAutocompleteURL(
                                BRANCH_COMPANY_TYPE,
                                values.company,
                                "name"
                              )}
                              showcolumn="name"
                              component={DropDownComponent}
                            />
                          </Col>
                        </Row>
                        <Button
                          disabled={this.isAddShipmentButtonDisabled(values)}
                          onClick={(e) => {
                            e.preventDefault();
                            this.addShipmentToTask(values);
                          }}
                          color="primary"
                        >
                          {getTranslation("shipment.create.button.name")}
                        </Button>
                      </React.Fragment>
                    )}

                    <CustomTable
                      data={{ data: shipments, count: 0 }}
                      tableMap={mapShipment}
                      tableCode={TABLE_CODE_SHIPMENT}
                      onTableChange={() => {}}
                      hidePagination
                    />

                    <hr
                      style={{ border: "1px solid #c8ced3", width: "100%" }}
                    />
                    {!taskData.processed && (
                      <Button type="submit" disabled={isSubmitting}>
                        {getTranslation("table.button.save")}
                      </Button>
                    )}
                    {taskData.id && (
                      <Button
                        color="danger"
                        onClick={this.deleteTask}
                        disabled={isSubmitting}
                      >
                        {getTranslation("table.button.delete")}
                      </Button>
                    )}
                  </Form>
                </Col>
              </Row>
            );
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskEdit);
