import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getTranslation } from "util/localisation";
import CompanyForm from "./CompanyForm";
import CompanyEmployeeList from "../CompanyEmployee/CompanyEmployeeList";
import CustomSpinner from "../Spinner/CustomSpinner";
import CurrencyDenominationList from "./CompanyDenominationList";
import CompanyDenominationList from "./CompanyDenominationList";
import { MEDITERAN_SECURITY_ROLES } from "util/roles/roleList";

const ACC_TABLE_CODE = "company.accaounts.table";

const TabNavigation = ({ editing, activeTab, switchTab }) => (
  <React.Fragment>
    {editing ? (
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              switchTab("1");
            }}
          >
            {getTranslation("company.label.company")}
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === '2',
            })}
            onClick={() => {
              switchTab('2');
            }}
          >
            {getTranslation('company.label.bankAccount')}
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              switchTab("3");
            }}
          >
            {getTranslation("company.label.employees")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "4",
            })}
            onClick={() => {
              switchTab("4");
            }}
          >
            {getTranslation("company.label.denoms")}
          </NavLink>
        </NavItem>
      </Nav>
    ) : (
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              switchTab("1");
            }}
          >
            {getTranslation("company.label.company")}
          </NavLink>
        </NavItem>
      </Nav>
    )}
  </React.Fragment>
);

class CompanyEdit extends Component {
  state = {
    activeTab: "1",
    editing: false,
    companyData: null,
    loading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    if (!localStorage.getItem(ACC_TABLE_CODE)) {
      localStorage.setItem(
        ACC_TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }

    if (id) {
      this.setState({ editing: true });
      this.getCompany(id);
    } else {
      this.setState({ loading: false });
    }
  }

  getCompany = (id) => {
    this.setState({ loading: true });
    fetch(`api/company/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState({ companyData: formData, loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting company", e);
        this.setState({ loading: false });
      });
  };

  deleteCompany = () => {
    const { match, history } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/company/${id}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }).then((r) => {
        console.log(r);
        history.push("/app/company");
      });
    }
  };

  saveCompany(formData) {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/company/${id}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(formData),
      });
    }
    // new save
    const url = "api/company";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(formData),
    });
  }

  toggle(tab) {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab, editing, companyData, loading } = this.state;
    const { history, match, userInfo } = this.props;
    const backPath = "/app/company";

    let parentRequired = true;
    if (userInfo && userInfo.companyEmployer && userInfo.companyEmployer.user) {
      const userRoles = userInfo.companyEmployer.user.authorities.map(
        (auth) => auth.authority
      );
      const companyRoles = [
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_OBJECTS,
      ];
      const matchingRoles = userRoles.filter((userRole) =>
        companyRoles.includes(userRole)
      );
      if (matchingRoles.length > 0) {
        parentRequired = false;
      }
    }

    if (loading) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>

        <TabNavigation
          activeTab={activeTab}
          switchTab={(tab) => this.toggle(tab)}
          editing={editing}
        />

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CompanyForm
              companyData={companyData}
              onSubmit={(formData) => this.saveCompany(formData)}
              onDelete={() => this.deleteCompany()}
              backPath={backPath}
              history={history}
              parentRequired={parentRequired}
            />
          </TabPane>
          {
            <TabPane tabId="2">
              <h2>{getTranslation("navbar.companyAccounts")}</h2>
            </TabPane>
          }
          <TabPane tabId="3">
            <CompanyEmployeeList
              history={history}
              match={match}
              company={companyData}
            />
          </TabPane>
          <TabPane tabId="4">
            <CompanyDenominationList
              history={history}
              match={match}
              company={companyData}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

export default withRouter(connect(mapStateToProps)(CompanyEdit));
