import CustomSpinner from 'components/Spinner/CustomSpinner';
import CustomTable from 'components/Table/CustomTable';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { getFilterURL } from 'util/api/callGenerator';
import { getTranslation } from 'util/localisation';

const TABLE_CODE = 'currency.denomination.list';

class CurrencyDenominationList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    currencyDenominationList: { data: [], count: 0 },
    loading: true,
  };

  componentDidMount() {
    this.getCurrencyDenominationList();
  }

  getCurrencyDenominationList() {
    this.setState({ loading: true });
    const { currencyId } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      `currencyDenomination?currencyId=${currencyId}&`,
      '',
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(`api/${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then(data => {
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
            this.setState({
              currencyDenominationList: data,
              loading: false,
            });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error('Error while getting currency denominations: ', error);
        this.setState({ loading: false });
      });
  }

  edit = item => {
    const { history, currencyId } = this.props;
    history.push(`/app/currency/${currencyId}/denomination/${item.id}`);
  };

  addNewCurrencyDenomination = () => {
    const { history, currencyId } = this.props;
    history.push(`/app/currency/${currencyId}/denomination/create`);
  };

  render() {
    const { loading, currencyDenominationList } = this.state;

    const map = {
      id: { field: 'id', header: 'tables.id', properties: {} },
      name: {
        field: 'name',
        header: 'currency.denomination.table.header.name',
        properties: {},
      },
      value: {
        field: 'value',
        header: 'currency.denomination.table.header.value',
        properties: {},
      },
      denominationType: {
        field: 'denominationTypeText',
        header: 'currency.denomination.table.header.type',
        properties: {},
      },
      code: {
        field: 'code',
        header: 'currency.denomination.table.header.code',
        properties: {},
      },
    };

    return (
      <React.Fragment>
        <Button
          outline
          className="margin--bottom"
          color="primary"
          onClick={this.addNewCurrencyDenomination}
        >
          {getTranslation('currency.denomination.create.button.name')}
        </Button>
        <CustomTable
          data={currencyDenominationList}
          tableMap={map}
          edit={this.edit}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getCurrencyDenominationList()}
        />
      </React.Fragment>
    );
  }
}

export default CurrencyDenominationList;
