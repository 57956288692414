import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { DateTimePicker } from "react-widgets";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import { MEDITERAN_SECURITY_ROLES } from "util/roles/roleList";
import { getAppSetting } from "../../util/settings";
import CustomTable from "../Table/CustomTable";

const TABLE_CODE = "order.list";

class ClientOrderList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    specificationList: { data: [], count: 0 },
    showTodaysOrdersOnly: false,
    showInProgressOnly: true,
    showCutoffModal: false,
    showDeleteModal: false,
    showProcessModal: false,
    showCancelModal: false,
    showReportModal: false,
    reportDateFrom: new Date(),
    reportDateTo: new Date(),
    selectedId: null,
    intervalIds: {},
    countdowns: {},
    workDay: undefined,
  };

  componentDidMount() {
    this.getSpecificationList();
    this.checkIfWorkDay();
  }

  checkIfWorkDay() {
    this.setState({ loading: true });

    fetch(`api/holiday/isWorkDay`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({ workDay: data });
          });
        } else {
          this.setState({ loading: false });
          showErrorAlert(getTranslation("api.error.fetching"));
        }
      })
      .catch((error) => {
        console.error("Error while checking if work day: ", error);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  }

  getSpecificationList() {
    this.setState({ loading: true });
    this.resetRemainingTimes();
    const { showErrorAlert } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const { showTodaysOrdersOnly } = this.state;
    const { showInProgressOnly } = this.state;
    const filter = getFilterURL(
      "specification",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(
      `api/${filter}&specificationType=3&showTodaysItemsOnly=${showTodaysOrdersOnly}&showInProgressOnly=${showInProgressOnly}&clientOrderOnly=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
            this.setState({ specificationList: data, loading: false });
          });
        } else {
          this.setState({ loading: false });
          showErrorAlert(getTranslation("api.error.fetching"));
        }
      })
      .catch((error) => {
        console.error("Error while getting orders: ", error);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  }

  numberWithCommas = (x) => {
    return x.toLocaleString("hr-HR", { minimumFractionDigits: 2 });
  };

  addNewSpecification = () => {
    const { history } = this.props;
    if (this.isNotCutoffTime()) {
      history.push("/app/clientOrder/create");
    } else {
      this.setState({ showCutoffModal: true });
    }
  };

  editSpecification = (row) => {
    const { history } = this.props;
    history.push(`/app/clientOrder/${row.id}`);
  };

  processOrder = () => {
    this.setState({ loading: true });
    fetch(`api/specification/processOrder?orderId=${this.state.selectedId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.getSpecificationList();
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while processing order", e);
        this.setState({ loading: false });
      });
  };

  cancelOrder = () => {
    this.setState({ loading: true });
    fetch(`api/specification/cancelOrder?orderId=${this.state.selectedId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.getSpecificationList();
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while canceling order", e);
        this.setState({ loading: false });
      });
  };

  createReport = (id) => {
    const { showErrorAlert } = this.props;
    const REPORT_ENDPOINT = `api/report/orderReport?reportId=${id}`;

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.pdf";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  createReportAll = () => {
    const { showErrorAlert } = this.props;
    const { reportDateFrom, reportDateTo } = this.state;
    let REPORT_ENDPOINT = `api/specification/generateOrderReport?specificationType=3&clientOrderOnly=true`;

    if (reportDateFrom) {
      REPORT_ENDPOINT += `&dateFrom=${moment(reportDateFrom).format(
        "yyyy-MM-DD"
      )}`;
    }

    if (reportDateTo) {
      REPORT_ENDPOINT += `&dateTo=${moment(reportDateTo).format("yyyy-MM-DD")}`;
    }

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.xlsx";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  deleteOrder = () => {
    this.setState({ loading: true });
    fetch(`api/specification/deleteOrder?orderId=${this.state.selectedId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        this.getSpecificationList();
        this.setState({ selectedId: null });
      } else {
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      }
    });
  };

  isNotCutoffTime = () => {
    const { userInfo } = this.props;
    let cutoff = undefined;

    if (
      userInfo &&
      userInfo.companyEmployer &&
      userInfo.companyEmployer.company &&
      userInfo.companyEmployer.company.cutoffTime
    ) {
      cutoff = userInfo.companyEmployer.company.cutoffTime;
    } else if (
      userInfo &&
      userInfo.companyEmployer &&
      userInfo.companyEmployer.company &&
      userInfo.companyEmployer.company.parent &&
      userInfo.companyEmployer.company.parent.cutoffTime
    ) {
      cutoff = userInfo.companyEmployer.company.parent.cutoffTime;
    } else {
      cutoff = getAppSetting("orders", "cutoff.time");
    }

    let isNotCutoff = true;
    if (cutoff) {
      const cutoffTime = moment(cutoff, "HH:mm");
      if (cutoffTime.isBefore(moment())) {
        isNotCutoff = false;
      }
    }
    return isNotCutoff;
  };

  resetRemainingTimes = () => {
    let intervalIds = { ...this.state.intervalIds };
    Object.keys(intervalIds).forEach((key) => {
      clearInterval(key);
    });
  };

  millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  getRemainingTime = (id, date) => {
    let intervalIds = { ...this.state.intervalIds };
    if (!intervalIds[id]) {
      intervalIds[id] = setInterval(() => {
        let countdowns = { ...this.state.countdowns };
        let diff = moment(date).diff(moment());
        if (diff > 0) {
          countdowns[id] = this.millisToMinutesAndSeconds(diff);
        } else {
          countdowns[id] = undefined;
          clearInterval(intervalIds[id]);
        }
        this.setState({ countdowns: countdowns });
      }, 1000);
      this.setState({ intervalIds: intervalIds });
    }
  };

  onReportDateFromSelect = (value) => {
    this.setState({ reportDateFrom: value });
  };

  onReportDateToSelect = (value) => {
    this.setState({ reportDateTo: value });
  };

  hasAnyRole = (userRoles, requiredRoles) => {
    let hasRole = false;
    userRoles.forEach((role) => {
      if (requiredRoles.includes(role.authority)) {
        hasRole = true;
      }
    });
    return hasRole;
  };

  isWorkDay = () => {
    const { userInfo } = this.props;
    if (
      userInfo &&
      userInfo.companyEmployer &&
      userInfo.companyEmployer.company
    ) {
      const { workingSaturday, workingSunday } =
        userInfo.companyEmployer.company;
      if (!workingSaturday && moment().isoWeekday() === 6) {
        return false;
      }
      if (!workingSunday && moment().isoWeekday() === 7) {
        return false;
      }
    }

    return this.state.workDay ? this.state.workDay.isWorkDay : false;
  };

  render() {
    const {
      specificationList,
      loading,
      showTodaysOrdersOnly,
      showInProgressOnly,
      countdowns,
      reportDateFrom,
      reportDateTo,
      workDay,
    } = this.state;

    const { userInfo } = this.props;

    let roles = [];
    if (userInfo && userInfo.companyEmployer && userInfo.companyEmployer.user) {
      roles = userInfo.companyEmployer.user.authorities;
    }

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      creationDate: {
        field: "creationDate",
        header: "table.header.specification.date.created",
        properties: { type: "date" },
      },
      deliveryDate: {
        field: "deliveryDate",
        header: "table.header.specification.date.due",
        properties: { type: "date" },
      },
      creationEmployerUser: {
        field: "creationEmployerUser.fullName",
        header: "table.header.specification.employee",
        properties: {},
      },
      creationEmployerCompany: {
        field: "creationEmployerCompany.name",
        header: "table.header.specification.employeeCompany",
        properties: {},
      },
      amount: {
        field: "amount",
        header: "table.header.specification.amount",
        properties: { type: "currency" },
      },
      currency: {
        field: "currency.code",
        header: "table.header.specification.currency",
        properties: {},
      },
      companyBranchParent: {
        field: "company.name",
        header: "table.header.specification.company.parent.name",
        properties: {},
      },
      cashCenter: {
        field: "cashCenter.name",
        header: "table.header.specification.cashCenter",
        properties: {},
      },
      status: {
        header: "table.header.specification.status",
        properties: { type: "boolean" },
        render: (row) => {
          if (row.specificationStatus === 0) {
            if (countdowns[row.id]) {
              return <span>{getTranslation("clientOrder.status.new")}</span>;
            } else {
              return <span>{getTranslation("clientOrder.status.sent")}</span>;
            }
          } else if (row.specificationStatus === 1) {
            return (
              <span>{getTranslation("clientOrder.status.processed")}</span>
            );
          } else if (row.specificationStatus === 2) {
            return <span>{getTranslation("clientOrder.status.canceled")}</span>;
          }
        },
      },
      locked: {
        header: "table.header.specification.locked",
        render: (row) => {
          if (row.lockedDate && row.id) {
            this.getRemainingTime(row.id, row.lockedDate);
            if (countdowns[row.id]) {
              return (
                <div>
                  <i
                    class="cil-lock-unlocked"
                    style={{ color: "green", fontSize: "16pt" }}
                  ></i>
                  <span style={{ fontSize: "12pt" }}>{countdowns[row.id]}</span>
                  {!row.processed && (
                    <span
                      style={{ float: "right" }}
                      onClick={() => {
                        if (this.isNotCutoffTime()) {
                          this.setState({
                            showDeleteModal: true,
                            selectedId: row.id,
                          });
                        } else {
                          this.setState({ showCutoffModal: true });
                        }
                      }}
                    >
                      <i class="cil-trash" style={{ fontSize: "16pt" }}></i>
                    </span>
                  )}
                </div>
              );
            }
            return (
              <div>
                <i
                  class="cil-lock-locked"
                  style={{ color: "red", fontSize: "16pt" }}
                ></i>
                {!row.processed && (
                  <span
                    style={{ float: "right" }}
                    onClick={() => {
                      if (this.isNotCutoffTime()) {
                        this.setState({
                          showDeleteModal: true,
                          selectedId: row.id,
                        });
                      } else {
                        this.setState({ showCutoffModal: true });
                      }
                    }}
                  >
                    <i class="cil-trash" style={{ fontSize: "16pt" }}></i>
                  </span>
                )}
              </div>
            );
          }
        },
      },
      buttonConfirm: {
        width: 1,
        render: (row) => {
          if (
            this.hasAnyRole(roles, [
              MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_PROCESS,
            ]) &&
            row.processed != undefined &&
            (row.processed === false || row.processed === 0)
          ) {
            return (
              <Button
                className={"table-button"}
                onClick={() =>
                  this.setState({
                    showProcessModal: true,
                    selectedId: row.id,
                  })
                }
                disabled={
                  (row.specificationType &&
                    row.specificationType == 3 &&
                    countdowns[row.id]) ||
                  row.specificationType != 3
                }
              >
                <span className="cil-check" />
              </Button>
            );
          }
        },
      },
      buttonCancel: {
        width: 1,
        render: (row) => {
          if (
            this.hasAnyRole(roles, [
              MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_PROCESS,
            ]) &&
            row.processed != undefined &&
            (row.processed === false || row.processed === 0)
          ) {
            return (
              <Button
                className={"table-button"}
                onClick={() =>
                  this.setState({
                    showCancelModal: true,
                    selectedId: row.id,
                  })
                }
                disabled={
                  (row.specificationType &&
                    row.specificationType == 3 &&
                    countdowns[row.id]) ||
                  row.specificationType != 3
                }
              >
                <span className="cil-x" />
              </Button>
            );
          }
        },
      },
      buttonReport: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-file",
          style: { width: "1em" },
          action: this.createReport,
        },
      },
    };

    return (
      <div className="animated fadeIn">
        <Modal isOpen={this.state.showCutoffModal} onExit={() => {}}>
          <ModalHeader toggle={() => this.setState({ showCutoffModal: false })}>
            {getTranslation("dialog.warning")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>{getTranslation("order.cutoff.message")}</Col>
            </Row>

            <Button
              outline
              className="margin--bottom"
              color="primary"
              onClick={() => this.setState({ showCutoffModal: false })}
              style={{ float: "right" }}
            >
              {getTranslation("table.button.ok")}
            </Button>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showDeleteModal} onExit={() => {}}>
          <ModalHeader
            toggle={() =>
              this.setState({ showDeleteModal: false, selectedId: false })
            }
          >
            {getTranslation("dialog.warning")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>{getTranslation("order.delete.message")}</Col>
            </Row>

            <Row>
              <div style={{ width: "100%" }}>
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    this.setState({ showDeleteModal: false });
                    this.deleteOrder();
                  }}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.yes")}
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() =>
                    this.setState({ showDeleteModal: false, selectedId: false })
                  }
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.no")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showProcessModal} onExit={() => {}}>
          <ModalHeader
            toggle={() =>
              this.setState({ showProcessModal: false, selectedId: false })
            }
          >
            {getTranslation("dialog.warning")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>{getTranslation("order.process.message")}</Col>
            </Row>

            <Row>
              <div style={{ width: "100%" }}>
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    this.setState({ showProcessModal: false });
                    this.processOrder();
                  }}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.yes")}
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showProcessModal: false,
                      selectedId: false,
                    })
                  }
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.no")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showCancelModal} onExit={() => {}}>
          <ModalHeader
            toggle={() =>
              this.setState({ showCancelModal: false, selectedId: false })
            }
          >
            {getTranslation("dialog.warning")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>{getTranslation("order.cancel.message")}</Col>
            </Row>

            <Row>
              <div style={{ width: "100%" }}>
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    this.setState({ showCancelModal: false });
                    this.cancelOrder();
                  }}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.yes")}
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() =>
                    this.setState({ showCancelModal: false, selectedId: false })
                  }
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.no")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showReportModal} onExit={() => {}}>
          <ModalHeader toggle={() => this.setState({ showReportModal: false })}>
            {getTranslation("orderReport.modal.forDate")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <React.Fragment>
                  <Label>{getTranslation("orderReport.modal.dateFrom")}</Label>
                  <FormGroup>
                    <DateTimePicker
                      time={false}
                      value={reportDateFrom}
                      onChange={(val) => this.onReportDateFromSelect(val)}
                    />
                  </FormGroup>
                </React.Fragment>
              </Col>
              <Col md="6">
                <React.Fragment>
                  <Label>{getTranslation("orderReport.modal.dateTo")}</Label>
                  <FormGroup>
                    <DateTimePicker
                      time={false}
                      value={reportDateTo}
                      onChange={(val) => this.onReportDateToSelect(val)}
                    />
                  </FormGroup>
                </React.Fragment>
              </Col>
            </Row>

            <Row>
              <div style={{ width: "100%" }}>
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    this.setState({ showReportModal: false });
                    this.createReportAll();
                  }}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.create")}
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() => this.setState({ showReportModal: false })}
                  style={{ float: "right" }}
                >
                  {getTranslation("table.button.cancel")}
                </Button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <Row>
          <Button
            disabled={!this.isWorkDay()}
            outline
            className="margin--bottom custom-tooltip-container"
            color="primary"
            onClick={this.addNewSpecification}
          >
            {getTranslation("order.create.button.label")}
            {workDay && !workDay.isWorkDay && (
              <div class="custom-tooltip">
                {getTranslation("holiday.disabled.warning")}: {workDay.info}
              </div>
            )}
          </Button>
          <FormGroup check inline>
            <Label check style={{ marginLeft: "1em" }}>
              <Input
                type="checkbox"
                checked={showTodaysOrdersOnly}
                onChange={(e) => {
                  this.setState(
                    { showTodaysOrdersOnly: e.target.checked },
                    this.getSpecificationList
                  );
                }}
              />
              {getTranslation("specification.list.showOnlyToday")}
            </Label>
            <Label check style={{ marginLeft: "1em" }}>
              <Input
                type="checkbox"
                checked={showInProgressOnly}
                onChange={(e) => {
                  this.setState(
                    { showInProgressOnly: e.target.checked },
                    this.getSpecificationList
                  );
                }}
              />
              {getTranslation("specification.list.showOnlyInProgress")}
            </Label>
          </FormGroup>
          <Button
            outline
            className="margin--bottom"
            color="primary"
            onClick={() => this.setState({ showReportModal: true })}
            style={{ position: "absolute", right: "20px" }}
          >
            {getTranslation("orderReport.button.create")}
          </Button>
        </Row>

        <CustomTable
          data={specificationList}
          tableMap={map}
          edit={this.editSpecification}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getSpecificationList()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientOrderList);
