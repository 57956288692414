import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import CustomSpinner from "components/Spinner/CustomSpinner";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { getTranslation } from "util/localisation";
import {
  MEDITERAN_SECURITY_ROLES,
  ROLE_WEB_ADMIN,
  SC_ROLES,
} from "util/roles/roleList";
import * as Yup from "yup";
import CheckInput from "../FormComponents/CheckInput";
import DropDownComponent from "../FormComponents/DropDownComponent";
import TextInput from "../FormComponents/TextInput";
import CustomProgress from "../Progress/CustomProgress";

const filter =
  '/autocomplete?columns=id&columns=name&userCreate=true&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';

const companyFilter =
  '/autocomplete?columns=id&columns=name&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "type": { "type": "integer", "key": "type", "value":"0"}, "search":{"type":"string","key":"name","value":';

const roleFilter =
  '/autocomplete?columns=id&columns=authority&columns=humanReadable&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"humanReadable","value":';

const CompanyEmployeeSchemaCreate = Yup.object().shape({
  phone: Yup.string().max(50),
  mobilePhone: Yup.string().max(50),
  workPlace: Yup.string().max(50),
  company: Yup.object().required("Odaberite tvrtku!"),
  user: Yup.object()
    .shape({
      username: Yup.string().max(50).required("Unesite korisničko ime!"),
      password: Yup.string()
        .max(50)
        .required("Unesite lozinku")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.{8,12}$)/,
          getTranslation("passwordPolicy.validation")
        ),
      firstName: Yup.string().max(50),
      lastName: Yup.string().max(50),
      email: Yup.string().email(),
    })
    .required(),
});

const CompanyEmployeeSchema = Yup.object().shape({
  phone: Yup.string().max(50),
  mobilePhone: Yup.string().max(50),
  workPlace: Yup.string().max(50),
  company: Yup.object().required("Odaberite tvrtku!"),
  user: Yup.object()
    .shape({
      username: Yup.string().max(50).required("Unesite korisničko ime!"),
      firstName: Yup.string().max(50).required(),
      lastName: Yup.string().max(50).required(),
      email: Yup.string().email(),
      password: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.{8,12}$)|^(?=.{0,0}$)/,
        getTranslation("passwordPolicy.validation")
      ),
    })
    .required(),
});

class CompanyEmployeeEdit extends Component {
  state = {
    id: null,
    employeeData: null,
    userRoles: [],
    roleInputValue: "",
    isCreate: false,
  };

  componentDidMount() {
    let id = null;
    const { employeeId } = this.props;
    if (!employeeId) {
      const { match } = this.props;
      const { employeeId } = match.params;
      id = employeeId;
    } else {
      id = employeeId;
    }

    if (id && id !== "create") {
      this.setState({ id: id });
      this.getEmployee(id);
    } else {
      this.setState({ isCreate: true });
    }
  }

  getAllowedRoles = () => {
    const { userInfo } = this.props;
    const { authorities } = userInfo.companyEmployer.user;
    const userRoles = authorities.map((auth) => auth.authority);
    const allowedRoles = [];
    if (userRoles.includes(ROLE_WEB_ADMIN)) {
      allowedRoles.push(
        SC_ROLES.ROLE_SC_ADMIN,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN
      );
    }
    if (userRoles.includes(SC_ROLES.ROLE_SC_ADMIN)) {
      allowedRoles.push(
        SC_ROLES.ROLE_WEB_BANK_ADMIN,
        SC_ROLES.ROLE_WEB_CUSTOMER_ADMIN,
        SC_ROLES.ROLE_WEB_CUSTOMER
      );
    }
    if (userRoles.includes(MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN)) {
      allowedRoles.push(
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ORDERS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_SPECIFICATIONS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_TASKS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS_COMPANY,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_OBJECTS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_OBJECTS_COMPANY,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_SPECIFICATIONS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_VIEW,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_MONITOR,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_PROCESS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_GUARD,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CASH_CENTER
      );
    } else if (
      userRoles.includes(MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS) ||
      userRoles.includes(MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS_COMPANY)
    ) {
      allowedRoles.push(
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_SPECIFICATIONS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_VIEW,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC
      );
    }
    return allowedRoles;
  };

  getEmployee(employeeId) {
    this.setState({ loading: true });
    fetch(`api/companyEmployer/${employeeId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState({
              employeeData: formData,
              userRoles: formData.user.authorities,
              loading: false,
            });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting employee", e);
        this.setState({ loading: false });
      });
  }

  onRoleSearch = (newValue) => {
    this.setState({ roleInputValue: newValue });
  };

  removeRole = (roleToRemove) => {
    const { userRoles } = this.state;
    const newRoles = userRoles.filter((role) => role.id !== roleToRemove.id);
    this.setState({ userRoles: newRoles });
  };

  addRole = (roleToAdd) => {
    this.setState((prevState) => ({
      userRoles: [...prevState.userRoles, roleToAdd],
    }));
  };

  saveEmployee(formData) {
    const { match } = this.props;
    const { userRoles } = this.state;
    const employeeId = this.state.id;

    const userForm = {
      ...formData,
      user: {
        ...formData.user,
        authorities: userRoles,
      },
    };

    if (employeeId && employeeId !== "create") {
      // editing
      const url = `api/companyEmployer/${employeeId}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(userForm),
      });
    }
    // new save
    const url = "api/companyEmployer";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(userForm),
    });
  }

  render() {
    const { employeeData, userRoles, roleInputValue, loading } = this.state;
    const { company, afterSubmit } = this.props;
    let initialValues = { user: { passChangeForced: true } };
    if (company) {
      initialValues = { ...initialValues, ...employeeData, company };
    } else {
      initialValues = { ...initialValues, ...employeeData };
    }

    if (loading) {
      return <CustomSpinner />;
    }

    return (
      <React.Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={
            this.state.isCreate
              ? CompanyEmployeeSchemaCreate
              : CompanyEmployeeSchema
          }
          onSubmit={(values, actions) => {
            this.saveEmployee(values).then((r) => {
              actions.setSubmitting(false);
              if (r && r.status === 200) {
                afterSubmit(r, true);
              } else {
                const { showErrorAlert } = this.props;
                showErrorAlert(getTranslation("api.error.saving"));
              }
            });
          }}
          render={({
            errors,
            status,
            touched,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Row>
                <Col xs="12">
                  <Form className="form--container">
                    {isSubmitting && <CustomProgress />}
                    <Row>
                      <Col md="6">
                        <Field
                          required
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.firstName"
                          name="user.firstName"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          required
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.lastName"
                          name="user.lastName"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          required
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.username"
                          name="user.username"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          required={this.state.isCreate}
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.password"
                          name="user.password"
                          component={TextInput}
                        />
                        {errors.user &&
                          touched.user &&
                          errors.user.password &&
                          touched.user.password && (
                            <div className="custom--invalid--feedback">
                              {errors.user.password}
                            </div>
                          )}
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.email"
                          name="user.email"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.phone"
                          name="phone"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.mobilePhone"
                          name="mobilePhone"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.workPlace"
                          name="workPlace"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          required
                          disabled={company || isSubmitting}
                          labeltext="companyEmployer.create.label.company"
                          name="company"
                          tabledatabasename="company"
                          filter={filter}
                          showcolumn="name"
                          component={DropDownComponent}
                          onselected={(suggestion) =>
                            setFieldValue("company", suggestion)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <hr
                          style={{
                            border: "1px solid #c8ced3",
                            width: "100%",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.force2fa"
                          name="user.twoFAForced"
                          component={CheckInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.forcePassChange"
                          name="user.passChangeForced"
                          component={CheckInput}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <hr
                          style={{
                            border: "1px solid #c8ced3",
                            width: "100%",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.default.cashCenter"
                          name="defaultCashCenter"
                          tabledatabasename="cashCenter"
                          filter={filter}
                          showcolumn="name"
                          component={DropDownComponent}
                          onselected={(suggestion) =>
                            setFieldValue("defaultCashCenter", suggestion)
                          }
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.default.client"
                          name="defaultClient"
                          tabledatabasename="company"
                          filter={companyFilter}
                          showcolumn="name"
                          component={DropDownComponent}
                          onselected={(suggestion) =>
                            setFieldValue("defaultClient", suggestion)
                          }
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          disabled={isSubmitting}
                          labeltext="companyEmployer.create.label.default.currency"
                          name="defaultCurrency"
                          tabledatabasename="currency"
                          filter={filter}
                          showcolumn="name"
                          component={DropDownComponent}
                          onselected={(suggestion) =>
                            setFieldValue("defaultCurrency", suggestion)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <hr
                          style={{
                            border: "1px solid #c8ced3",
                            width: "100%",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div>
                          <DropDownComponent
                            disabled={isSubmitting}
                            labeltext="companyEmployer.create.label.roles"
                            onselected={(suggestion) =>
                              this.addRole(suggestion)
                            }
                            tabledatabasename="role"
                            filter={roleFilter}
                            suggestionFilter={(suggestion) =>
                              this.getAllowedRoles().includes(
                                suggestion.authority
                              )
                            }
                            showcolumn="humanReadable"
                            field={{
                              name: "currency",
                              value: roleInputValue,
                              onChange: (e, { newValue }) =>
                                this.onRoleSearch(newValue),
                            }}
                            form={{ errors: {}, touched: {} }}
                          />
                          {userRoles.map((role) => (
                            <div
                              key={role.id}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0.5em",
                              }}
                            >
                              <span>{role.humanReadable}</span>
                              <Button onClick={() => this.removeRole(role)}>
                                <span className="cil-ban" />
                              </Button>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                    <div className="margin--right">
                      <Button type="submit" disabled={isSubmitting}>
                        {getTranslation("table.button.save")}
                      </Button>
                      {/* {!this.state.isCreate && (
                        <Button
                          disabled={isSubmitting}
                          color="danger"
                          onClick={onDelete}
                        >
                          {getTranslation("table.button.delete")}
                        </Button>
                      )} */}
                    </div>
                  </Form>
                </Col>
              </Row>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyEmployeeEdit);
