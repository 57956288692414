import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  SHOW_MAIN_SPINNER,
  HIDE_MAIN_SPINNER,
  SHOW_ERROR_ALERT,
  DISMISS_ERROR_ALERT,
} from 'actions/spinnerActions';

export default (
  state = { loading: false, mainLoading: true, error: false },
  action
) => {
  switch (action.type) {
    case SHOW_MAIN_SPINNER:
      return {
        ...state,
        mainLoading: true,
      };
    case HIDE_MAIN_SPINNER:
      return {
        ...state,
        mainLoading: false,
      };
    case SHOW_SPINNER:
      return {
        ...state,
        loading: true,
      };

    case HIDE_SPINNER:
      return {
        ...state,
        loading: false,
      };

    case SHOW_ERROR_ALERT:
      return {
        ...state,
        error: action.payload,
      };

    case DISMISS_ERROR_ALERT:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
