import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { getTranslation } from "util/localisation";
import * as Yup from "yup";
import DropDownComponent from "../FormComponents/DropDownComponent";
import EnumeratorComponent from "../FormComponents/EnumeratorComponent";
import TextInput from "../FormComponents/TextInput";
import CheckInput from "../FormComponents/CheckInput";
import CustomProgress from "../Progress/CustomProgress";

const filterCity =
  '/autocomplete?columns=id&columns=name&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';
const filterParent =
  '/autocomplete?columns=id&columns=name&companyCreate=true&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';
const filterCashCenter =
  '/autocomplete?columns=id&columns=name&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';

const CompanySchema = Yup.object().shape({
  name: Yup.string().required("Unesite naziv!"),
  type: Yup.number().required("Odaberite tip!"),
});

const CompanyForm = ({
  companyData,
  onSubmit,
  onDelete,
  backPath,
  history,
  parentRequired,
}) => (
  <Formik
    enableReinitialize
    validationSchema={CompanySchema}
    initialValues={companyData}
    onSubmit={(values, actions) => {
      onSubmit(values).then((r) => {
        actions.setSubmitting(false);
        history.push(backPath);
      });
    }}
    render={({ errors, status, touched, isSubmitting, setFieldValue }) => {
      const onAutocompleteOptionSelectedParent = (suggestion) => {
        setFieldValue("parent", suggestion);
      };
      const onAutocompleteOptionSelectedCashCenter = (suggestion) => {
        setFieldValue("cashCenter", suggestion);
      };
      const onAutocompleteOptionSelectedCity = (suggestion) => {
        setFieldValue("city", suggestion);
      };

      return (
        <Row>
          <Col xs="12">
            <Form>
              {isSubmitting && <CustomProgress />}
              <Row>
                <Col md="6">
                  <Field
                    required
                    disabled={isSubmitting}
                    labeltext="company.create.label.name"
                    name="name"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.code"
                    name="code"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.description"
                    name="description"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.oib"
                    name="oib"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.phone"
                    name="phone"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.fax"
                    name="fax"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.city"
                    name="city"
                    onselected={onAutocompleteOptionSelectedCity}
                    tabledatabasename="city"
                    filter={filterCity}
                    showcolumn="name"
                    component={DropDownComponent}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.address"
                    name="address"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    required={parentRequired}
                    disabled={isSubmitting}
                    labeltext="company.create.label.parent"
                    name="parent"
                    onselected={onAutocompleteOptionSelectedParent}
                    tabledatabasename="company"
                    filter={filterParent}
                    showcolumn="name"
                    component={DropDownComponent}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.cash.center"
                    name="cashCenter"
                    onselected={onAutocompleteOptionSelectedCashCenter}
                    tabledatabasename="cashCenter"
                    filter={filterCashCenter}
                    showcolumn="name"
                    component={DropDownComponent}
                  />
                </Col>
                <Col md="6">
                  <Field
                    required
                    disabled={isSubmitting}
                    labeltext="company.create.label.type"
                    enumname="CompanyType"
                    onselected={(value) => setFieldValue("type", value)}
                    name="type"
                    component={EnumeratorComponent}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.cutoffTime"
                    name="cutoffTime"
                    component={TextInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.workingSaturday"
                    name="workingSaturday"
                    component={CheckInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.workingSunday"
                    name="workingSunday"
                    component={CheckInput}
                  />
                </Col>
                <Col md="6">
                  <Field
                    disabled={isSubmitting}
                    labeltext="company.create.label.apiKey"
                    name="apiKey"
                    component={TextInput}
                  />
                </Col>
              </Row>

              <div className="margin--top">
                <Button
                  className="margin--right"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {getTranslation("table.button.save")}
                </Button>
                {companyData && (
                  <Button
                    disabled={isSubmitting}
                    color="danger"
                    onClick={onDelete}
                  >
                    {getTranslation("table.button.delete")}
                  </Button>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      );
    }}
  />
);

export default CompanyForm;
