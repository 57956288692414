import React from 'react';
import { Spinner } from 'reactstrap';
import './CustomSpinner.scss';

const CustomSpinner = ({ size = '', color = 'secondary' }) => (
  <div className="spinner-container">
    <Spinner size={size} color={color} />
  </div>
);

export default CustomSpinner;
