import classnames from "classnames";
import React, { Component } from "react";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getTranslation } from "util/localisation";
import CustomSpinner from "../Spinner/CustomSpinner";
import CurrencyForm from "./CurrencyForm";
import CurrencyDenominationList from "./CurrencyDenominationList";
import CurrencySeriesList from "./CurrencySeriesList";

const TabNavigation = ({ editing, activeTab, switchTab }) => (
  <React.Fragment>
    {editing ? (
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              switchTab("1");
            }}
          >
            {getTranslation("currency.label.currency")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              switchTab("2");
            }}
          >
            {getTranslation("currency.label.currency.denomination")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              switchTab("3");
            }}
          >
            {getTranslation("currency.label.currency.series")}
          </NavLink>
        </NavItem>
      </Nav>
    ) : (
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              switchTab("1");
            }}
          >
            {getTranslation("currency.label.currency")}
          </NavLink>
        </NavItem>
      </Nav>
    )}
  </React.Fragment>
);

class CurrencyEdit extends Component {
  state = {
    activeTab: "1",
    editing: false,
    currencyData: null,
    loading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getCurrency(id);
      this.setState({ editing: true });
    } else {
      this.setState({ loading: false });
    }
  }

  getCurrency = (id) => {
    this.setState({ loading: true });
    fetch(`api/currency/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState({ currencyData: formData, loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting currency", e);
        this.setState({ loading: false });
      });
  };

  deleteCurrency = () => {
    const { match, history } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/currency/${id}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }).then((r) => {
        console.log(r);
        history.push("/app/currency");
      });
    }
  };

  saveCurrency(formData) {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/currency/${id}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(formData),
      });
    }
    // new save
    const url = "api/currency";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(formData),
    });
  }

  toggle(tab) {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { history } = this.props;
    const { activeTab, editing, currencyData, loading } = this.state;
    const backPath = "/app/currency";

    if (loading) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>

        <TabNavigation
          activeTab={activeTab}
          switchTab={(tab) => this.toggle(tab)}
          editing={editing}
        />

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CurrencyForm
              currencyData={currencyData}
              onSubmit={(formData) => this.saveCurrency(formData)}
              onDelete={() => this.deleteCurrency()}
              backPath={backPath}
              history={history}
            />
          </TabPane>
          <TabPane tabId="2">
            <CurrencyDenominationList
              history={history}
              currencyId={currencyData && currencyData.id}
            />
          </TabPane>
          <TabPane tabId="3">
            <CurrencySeriesList
              history={history}
              currencyId={currencyData && currencyData.id}
            />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default CurrencyEdit;
