import { GetApiCall, GetApiCallParams } from './api/Get';
import * as CONST from './constants';

const localisation = 'localisation';

export function setLocalisation(localisationData) {
  localStorage.setItem(localisation, JSON.stringify(localisationData));
  localStorage.setItem('version', CONST.getVersion());
}

export function getLocalisationFromServerDefault() {
  const path = 'application/getDefaultLanguage';
  return GetApiCall(path);
}
export function getLocalisationFromServer() {
  const Params = {
    path: 'application/getLanguage',
    params: `lang=${CONST.getCountry()}`,
  };

  return GetApiCallParams(Params);
}

export function getTranslation(key) {
  const localStorageValue = localStorage.getItem(localisation);
  if (localStorageValue) {
    const localStorageJson = JSON.parse(localStorageValue);
    if (localStorageJson[key]) {
      return localStorageJson[key];
    }
    return key;
  }
  return '<<<TRANSLATION_NEEDED>>>';
}

export function changeCountry(setCountry) {
  setCountry(setCountry);
}
