export const BAD_CREDENTIALS = 'BAD_CREDENTIALS';
export const BAD_REQUEST = 'BAD_REQUEST';
export const OTP_NEEDED = 'OTP_NEEDED';
let VERSION = '1.0.0';
let COUNTRY = 'hr';

export function getVersion() {
  return VERSION;
}

export function setVersion(version) {
  VERSION = version;
}

export function setCountry(country) {
  COUNTRY = country;
}

export function getCountry() {
  return COUNTRY;
}
