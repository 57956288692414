import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Input, FormGroup, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import DropDownComponent from "../FormComponents/DropDownComponent";

const TABLE_CODE = "ftp.report.list";

class FtpReportList extends Component {
    constructor() {
        super();
        if (!localStorage.getItem(TABLE_CODE)) {
            localStorage.setItem(
                TABLE_CODE,
                JSON.stringify({
                    currentPage: 0,
                    maxItems: 10,
                })
            );
        }
    }

    state = {
        reportList: { data: [], count: 0 },
        showUnsentOnly: true,
        loading: true,
    };

    componentDidMount() {
        this.getReportList();
    }

    getFileNameFromHeader = (dispositionHeader) => {
        let fileName = "download.xml";
        if (dispositionHeader && dispositionHeader.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(dispositionHeader);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, "");
            }
        }
        return fileName;
    };

    getReportList() {
        this.setState({ loading: true });
        const { showErrorAlert } = this.props;
        const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
        const { showUnsentOnly } = this.state;
        const filter = getFilterURL("ftpXmlReport", "", tableInfo.maxItems, tableInfo.currentPage * tableInfo.maxItems);

        fetch(`api/${filter}&showUnsentOnly=${showUnsentOnly}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${localStorage.auth}`,
            },
        })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then((data) => {
                        this.setState({ reportList: data, loading: false });
                        localStorage.setItem(
                            TABLE_CODE,
                            JSON.stringify({
                                ...tableInfo,
                            })
                        );
                    });
                } else {
                    showErrorAlert(getTranslation("api.error.fetching"));
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                console.error("Error while getting ftp xml reports: ", error);
                showErrorAlert(getTranslation("api.error.fetching"));
                this.setState({ loading: false });
            });
    }

    resend = (reportId) => {
        this.setState({ loading: true });
        fetch(`api/ftpXmlReport/resend?reportId=${reportId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${localStorage.auth}`,
            },
        })
            .then((response) => {
                this.setState({ loading: false });
                if (response.status === 200) {
                    this.getReportList();
                }
            })
            .catch((e) => {
                console.error("Error while resending XML", e);
                this.setState({ loading: false });
            });
    };

    download = (reportId) => {
        this.setState({ loading: true });
        const { showErrorAlert } = this.props;
        const ENDPOINT = `api/ftpXmlReport/download?reportId=${reportId}`;

        fetch(ENDPOINT, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${localStorage.auth}`,
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    response.blob().then((blob) => {
                        const disposition = response.headers.get("Content-Disposition");
                        const fileName = this.getFileNameFromHeader(disposition);
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = fileName;
                        a.target = "_blank";
                        a.click();
                        a.remove();
                        this.setState({ loading: false });
                    });
                } else {
                    showErrorAlert(getTranslation("api.error.generatingXML"));
                    this.setState({ loading: false });
                }
            })
            .catch((e) => {
                console.error("Error generating XML", e);
                showErrorAlert(getTranslation("api.error.generatingXML"));
                this.setState({ loading: false });
            });
    };

    render() {
        const { loading, reportList, showUnsentOnly } = this.state;

        const map = {
            id: { field: "id", header: "tables.id", properties: {} },
            fileName: { field: "fileName", header: "ftpReport.table.fileName", properties: {} },
            transferDate: { field: "transferDate", header: "ftpReport.table.transferDate", properties: { type: "date" } },
            successfulTransfer: { field: "successfulTransfer", header: "ftpReport.table.successfulTransfer", properties: { type: "boolean" } },
            buttonDownload: {
                properties: {
                    type: "button",
                    text: "",
                    icon: "cil-file",
                    style: { width: "1em" },
                    action: this.download,
                },
            },
            buttonResend: {
                properties: {
                    type: "button",
                    text: "",
                    icon: "cil-arrow-thick-right",
                    style: { width: "1em" },
                    action: this.resend,
                },
            },
        };

        return (
            <React.Fragment>
                <Row>
                    <FormGroup check inline style={{ marginLeft: "1em" }}>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={showUnsentOnly}
                                onChange={(e) => {
                                    this.setState({ showUnsentOnly: e.target.checked }, this.getReportList);
                                }}
                            />
                            {getTranslation("ftpReport.table.show.unsent.only")}
                        </Label>
                    </FormGroup>
                </Row>
                <CustomTable data={reportList} tableMap={map} edit={{}} tableCode={TABLE_CODE} loading={loading} onTableChange={() => this.getReportList()} />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

export default connect(undefined, mapDispatchToProps)(FtpReportList);
