import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { userSuccess } from "actionCreators/userActionCreators";
import Auth from "util/api/auth";
import * as Constants from "util/constants";
import { getTranslation } from "util/localisation";
import {
  getLocalisationFromServer,
  setLocalisation,
} from "../../util/localisation";
import { getAppSettings, setAppSettings } from "../../util/settings";
import CustomCircularProgress from "../Progress/CustomCircularProgress";
import { API_ENDPOINT } from "util/api/apiConstants";
import { EventSourcePolyfill } from "event-source-polyfill";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      start: false,
      showTimeNotification: false,
      duration: undefined,
      requestId: undefined,
      validateFail: false,
      username: "",
      password: "",
      otp: "",
      needTwoFac: false,
      okLogin: false,
      wrongCredentials: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChangeStateUsername = (e) => {
    const { value } = e.target;
    this.setState({ username: value });
  };

  onChangeStatePassword = (e) => {
    const { value } = e.target;
    this.setState({ password: value });
  };

  onChangeStateOtp = (e) => {
    const { value } = e.target;
    this.setState({ otp: value });
  };

  okLogin = () => {
    this.setState({ okLogin: true });
  };

  finishLogin = (token, username) => {
    const { checkUser } = this.props;

    Auth.logIn(token, username);

    checkUser();

    getLocalisationFromServer()
      .then((localisation) => {
        setLocalisation(localisation);
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error(`Error getting localization from server: ${e}`);
      });

    this.setState({
      okLogin: true,
    });
    this.setState({ loading: false });
  };

  handleSubmit(event) {
    event.preventDefault();

    const { username, password, otp } = this.state;

    const form = {
      username,
      password,
      otp,
    };

    if (username !== "" && password !== "") {
      this.setState({
        loading: true,
        validateFail: false,
        wrongCredentials: false,
      });
      Auth.loginAsk(form).then((response) => {
        if (response.status === 403) {
          response.json().then((data) => {
            if (data.validFor) {
              this.setState({ duration: data.validFor });
            }
            if (data.validFor) {
              this.setState({ requestId: data.requestId });
            }
            this.wait2Fa(data.requestId);
          });
        } else if (response.status === 401) {
          this.setState({ loading: false });
          this.setState({ okLogin: false });
          this.setState({ wrongCredentials: true });
        } else {
          this.finishLogin(response.access_token, response.username);
        }
      });
    }
  }

  wait2Fa = (requestId) => {
    this.setState({ showTimeNotification: true, start: true });
    this.es = new EventSourcePolyfill(
      `${API_ENDPOINT}twoFA/validate?requestId=${requestId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }
    );
    const thisReference = this;
    this.es.onmessage = function (event) {
      if (event.data) {
        const esResp = JSON.parse(event.data);
        if (esResp.validate) {
          thisReference.onTimerFinish();
          if (esResp.username && esResp.token) {
            thisReference.finishLogin(
              JSON.parse(esResp.token).access_token,
              esResp.username
            );
          } else {
            thisReference.setState({ validateFail: true });
          }
        } else {
          thisReference.onTimerFinish();
          thisReference.setState({ validateFail: true });
        }
      } else {
        thisReference.onTimerFinish();
        thisReference.setState({ validateFail: true });
      }
    };
  };

  onTimerFinish = () => {
    if (this.es) {
      this.es.close();
      this.es = undefined;
    }
    this.setState({
      loading: false,
      showTimeNotification: false,
      start: false,
      duration: undefined,
      requestId: undefined,
    });
  };

  render() {
    const { okLogin, needTwoFac, wrongCredentials, loading, validateFail } =
      this.state;

    if (okLogin) {
      return <Redirect to="/" />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <h1>{getTranslation("login.form.text")}</h1>
                      <p className="text-muted">
                        {getTranslation("login.form.subtext")}
                      </p>
                      <InputGroup className={needTwoFac ? "hidden" : "mb-3"}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder={getTranslation("login.user.name")}
                          autoComplete="username"
                          onChange={this.onChangeStateUsername}
                        />
                      </InputGroup>
                      <InputGroup className={needTwoFac ? "hidden" : "mb-4"}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder={getTranslation("login.user.password")}
                          autoComplete="current-password"
                          onChange={this.onChangeStatePassword}
                        />
                      </InputGroup>
                      {needTwoFac && (
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder={getTranslation("login.user.otp")}
                            autoComplete="current-password"
                            onChange={this.onChangeStateOtp}
                          />
                        </InputGroup>
                      )}

                      {wrongCredentials && (
                        <InputGroup>
                          <div className="alert alert-danger" role="alert">
                            {getTranslation("login.error.badauth")}
                          </div>
                        </InputGroup>
                      )}
                      <Row>
                        <Col xs="5">
                          <Button
                            color="primary"
                            className="px-4"
                            disabled={loading}
                          >
                            {getTranslation("login.button.submit")}
                          </Button>
                        </Col>
                      </Row>
                      {this.state.showTimeNotification === true &&
                        this.state.duration && (
                          <Row>
                            <Col>
                              <CustomCircularProgress
                                label={getTranslation("2fa.confirm")}
                                duration={this.state.duration}
                                start={this.state.start}
                                onFinish={this.onTimerFinish}
                              />
                            </Col>
                          </Row>
                        )}
                      {validateFail && (
                        <Row>
                          <Col>
                            <InputGroup>
                              <div className="alert alert-danger" role="alert">
                                {getTranslation("2fa.error")}
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

const mapDispatchToProps = (dispatch) => ({
  checkUser: () => dispatch(userSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
