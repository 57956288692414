import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";

const TABLE_CODE = "cash.center.list";

class CashCenterList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    cashCenterList: { data: [], count: 0 },
    loading: true,
    generatingReport: false,
  };

  componentDidMount() {
    this.getCashCenterList();
  }

  getCashCenterList() {
    this.setState({ loading: true });
    const { showErrorAlert } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      "cashCenter",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(`api/${filter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({ cashCenterList: data, loading: false });
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Error while getting cash centers: ", error);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  }

  edit = (item) => {
    const { history } = this.props;
    history.push(`/app/cashCenter/${item.id}`);
  };

  addNewCashCenter = () => {
    const { history } = this.props;
    history.push("/app/cashCenter/create");
  };

  getFileNameFromHeader = (dispositionHeader) => {
    let fileName = "download.xml";
    if (dispositionHeader && dispositionHeader.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(dispositionHeader);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }
    return fileName;
  };

  generateTrafficXML = (cashCenterId) => {
    this.setState({ generatingReport: true });
    const { showErrorAlert } = this.props;
    const ENDPOINT = `api/cashCenter/generateTrafficXML?cashCenterId=${cashCenterId}`;

    fetch(ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            const disposition = response.headers.get("Content-Disposition");
            const fileName = this.getFileNameFromHeader(disposition);
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.target = "_blank";
            a.click();
            a.remove();
            this.setState({ generatingReport: false });
          });
        } else {
          showErrorAlert(getTranslation("api.error.generatingXML"));
          this.setState({ generatingReport: false });
        }
      })
      .catch((e) => {
        console.error("Error generating traffic XML", e);
        showErrorAlert(getTranslation("api.error.generatingXML"));
        this.setState({ generatingReport: false });
      });
  };

  generateStateXML = (cashCenterId) => {
    this.setState({ generatingReport: true });
    const { showErrorAlert } = this.props;
    const ENDPOINT = `api/cashCenter/generateStateXML?cashCenterId=${cashCenterId}`;

    fetch(ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            const disposition = response.headers.get("Content-Disposition");
            const fileName = this.getFileNameFromHeader(disposition);
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.target = "_blank";
            a.click();
            a.remove();
            this.setState({ generatingReport: false });
          });
        } else {
          showErrorAlert(getTranslation("api.error.generatingXML"));
          this.setState({ generatingReport: false });
        }
      })
      .catch((e) => {
        console.error("Error generating state XML", e);
        showErrorAlert(getTranslation("api.error.generatingXML"));
        this.setState({ generatingReport: false });
      });
  };

  createQRCode = (id) => {
    const { showErrorAlert } = this.props;
    const REPORT_ENDPOINT = `api/report/qrCode?reportId=${id}&type=cashCenter`;

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.pdf";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  render() {
    const { loading, cashCenterList, generatingReport } = this.state;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      name: {
        field: "name",
        header: "cash.center.table.header.name",
        properties: {},
      },
      email: {
        field: "email",
        header: "cash.center.table.header.email",
        properties: {},
      },
      city: {
        field: "city.name",
        header: "cash.center.table.header.city",
        properties: {},
      },
      address: {
        field: "address",
        header: "cash.center.table.header.address",
        properties: {},
      },
      code: {
        field: "code",
        header: "cash.center.table.header.code",
        properties: {},
      },
      buttonQRCode: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-qr-code",
          style: { width: "1em" },
          action: this.createQRCode,
        },
      },
      buttonGenerateTrafficXML: {
        header: "cash.center.table.header.transactionXML",
        properties: {
          type: "button",
          text: "",
          icon: "cil-file",
          style: { width: "1em" },
          action: this.generateTrafficXML,
        },
      },
      buttonGenerateStateXML: {
        header: "cash.center.table.header.stateXML",
        properties: {
          type: "button",
          text: "",
          icon: "cil-file",
          style: { width: "1em" },
          action: this.generateStateXML,
        },
      },
    };

    return (
      <React.Fragment>
        <Button
          outline
          className="margin--bottom"
          color="primary"
          onClick={this.addNewCashCenter}
        >
          {getTranslation("cash.center.create.button.name")}
        </Button>
        {generatingReport && <CustomSpinner />}
        <CustomTable
          data={cashCenterList}
          tableMap={map}
          edit={this.edit}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getCashCenterList()}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

export default connect(null, mapDispatchToProps)(CashCenterList);
