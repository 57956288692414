import DropDownComponent from "components/FormComponents/DropDownComponent";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { getTranslation } from "util/localisation";
import TextInput from "../FormComponents/TextInput";
import DatePicker from "../FormComponents/DatePicker";
import CustomSpinner from "../Spinner/CustomSpinner";
import CustomProgress from "../Progress/CustomProgress";
import * as Yup from "yup";

const CurrencySeriesSchema = Yup.object().shape({
  currency: Yup.object().required("Odaberite valutu!"),
  name: Yup.string().required("Unesite vrijednost!"),
});

class CurrencySeriesEdit extends Component {
  state = {
    currencySeriesData: null,
    currencyData: null,
    loading: true,
  };

  componentDidMount() {
    console.log(" series edit mounted");
    const { match } = this.props;
    const { id, currencyId } = match.params;
    if (id) {
      this.getCurrencySeries(id);
    } else {
      this.getCurrency(currencyId);
    }
  }

  getCurrency = (id) => {
    this.setState({ loading: true });
    fetch(`api/currency/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState({ currencyData: formData, loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting currency", e);
        this.setState({ loading: false });
      });
  };

  getCurrencySeries = (id) => {
    this.setState({ loading: true });
    fetch(`api/currencySeries/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState({
              currencySeriesData: {
                ...formData,
                seriesDate: new Date(formData.seriesDate),
              },
              loading: false,
            });
          });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while getting currency series", e);
        this.setState({ loading: false });
      });
  };

  deleteCurrencySeries = () => {
    const { match, history } = this.props;
    const { id, currencyId } = match.params;
    if (id) {
      // editing
      const url = `api/currencySeries/${id}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }).then((r) => {
        console.log(r);
        history.push(`/app/currency/${currencyId}`);
      });
    }
  };

  saveCurrencySeries(formData) {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/currencySeries/${id}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(formData),
      });
    }
    // new save
    const url = "api/currencySeries";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(formData),
    });
  }

  render() {
    const { history, match } = this.props;
    const { currencyId } = match.params;
    const { currencySeriesData, loading, currencyData } = this.state;
    const backPath = `/app/currency/${currencyId}`;

    if (loading) {
      return <CustomSpinner />;
    }

    const currencyFilter =
      '/autocomplete?columns=id&columns=code&columns=name&filter={"search":{"type":"string","key":"name","value":';

    console.log(currencySeriesData);

    let initialValues = null;
    if (currencyData) {
      initialValues = {
        currency: currencyData,
      };
    } else {
      initialValues = currencySeriesData;
    }

    console.log(initialValues);

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>
        <Formik
          enableReinitialize
          validationSchema={CurrencySeriesSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            this.saveCurrencySeries(values).then((response) => {
              console.log(response);
              actions.setSubmitting(false);
              history.push(backPath);
            });
          }}
          render={({
            errors,
            status,
            touched,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Row>
                <Col xs="12">
                  <Form className="form--container">
                    {isSubmitting && <CustomProgress />}
                    <Row>
                      <Col md="6">
                        <Field
                          disabled
                          required
                          labeltext="currency.series.create.label.currency"
                          name="currency"
                          onselected={(suggestion) =>
                            setFieldValue("currency", suggestion)
                          }
                          tabledatabasename="currency"
                          filter={currencyFilter}
                          showcolumn="name"
                          component={DropDownComponent}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          required
                          labeltext="currency.series.create.label.name"
                          name="name"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          labeltext="currency.series.create.label.seriesDate"
                          name="seriesDate"
                          component={DatePicker}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" disabled={isSubmitting}>
                      {getTranslation("table.button.save")}
                    </Button>
                    <Button color="danger" onClick={this.deleteCurrencySeries}>
                      {getTranslation("table.button.delete")}
                    </Button>
                  </Form>
                </Col>
              </Row>
            );
          }}
        />
      </div>
    );
  }
}

export default CurrencySeriesEdit;
