import TextInput from '../FormComponents/TextInput';
import CustomSpinner from '../Spinner/CustomSpinner';
import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { getTranslation } from 'util/localisation';
import CustomProgress from '../Progress/CustomProgress';

class DeliveryVehicleEdit extends Component {
  state = {
    deliveryVehicleData: null,
    loading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getDeliveryVehicle(id);
    } else {
      this.setState({ loading: false });
    }
  }

  getDeliveryVehicle = id => {
    this.setState({ loading: true });
    fetch(`api/deliveryVehicle/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          response.json().then(formData => {
            this.setState({ deliveryVehicleData: formData, loading: false });
          });
        }
        this.setState({ loading: false });
      })
      .catch(e => {
        console.error('Error while getting delivery vehicle', e);
        this.setState({ loading: false });
      });
  };

  deleteDeliveryVehicle = () => {
    const { match, history } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/deliveryVehicle/${id}`;
      fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }).then(r => {
        console.log(r);
        history.push('/app/deliveryVehicle');
      });
    }
  };

  saveDeliveryVehicle(formData) {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/deliveryVehicle/${id}`;
      return fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(formData),
      });
    }
    // new save
    const url = 'api/deliveryVehicle';
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(formData),
    });
  }

  render() {
    const { history } = this.props;
    const { deliveryVehicleData, loading, tenantEditable } = this.state;
    const backPath = '/app/deliveryVehicle';

    if (loading) {
      return <CustomSpinner />;
    }

    console.log(deliveryVehicleData);

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>
        <Formik
          enableReinitialize
          initialValues={deliveryVehicleData}
          onSubmit={(values, actions) => {
            this.saveDeliveryVehicle(values).then(response => {
              console.log(response);
              actions.setSubmitting(false);
              history.push(backPath);
            });
          }}
          render={({ errors, status, touched, isSubmitting }) => {
            return (
              <Row>
                <Col xs="12">
                  <Form className="form--container">
                    {isSubmitting && <CustomProgress />}
                    <Row>
                      <Col md="6">
                        <Field
                          labeltext="delivery.vehicle.create.label.name"
                          name="name"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          labeltext="delivery.vehicle.create.label.registration.plate"
                          name="registrationPlate"
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          labeltext="delivery.vehicle.create.label.code"
                          name="code"
                          component={TextInput}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" disabled={isSubmitting}>
                      {getTranslation('table.button.save')}
                    </Button>
                    {deliveryVehicleData && (
                      <Button
                        color="danger"
                        onClick={this.deleteDeliveryVehicle}
                        disabled={isSubmitting}
                      >
                        {getTranslation('table.button.delete')}
                      </Button>
                    )}
                  </Form>
                </Col>
              </Row>
            );
          }}
        />
      </div>
    );
  }
}

export default DeliveryVehicleEdit;
