import classnames from "classnames";
import { connect } from "react-redux";
import DropDownComponent from "components/FormComponents/DropDownComponent";
import EnumeratorComponent from "components/FormComponents/EnumeratorComponent";
import CustomTable from "components/Table/CustomTable";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { getAutocompleteURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import TextInput from "../FormComponents/TextInput";
import CustomProgress from "../Progress/CustomProgress";
import CustomSpinner from "../Spinner/CustomSpinner";

const TABLE_CODE_TRANSACTION = "task.transaction.list";

const NORMAL_QUALITY_CODE = 1;

const backPath = "/app/task";

const TabNavigation = ({ arrayFromWhichToGenerate, activeTab, switchTab }) => (
  <React.Fragment>
    <Nav tabs>
      {arrayFromWhichToGenerate.map((item) => (
        <NavLink
          key={item.code}
          className={classnames({ active: activeTab === item.code })}
          onClick={() => switchTab(item.code)}
        >
          <span>
            {getTranslation("shipment.header")}: <b>{item.shipment.code}</b>
          </span>
        </NavLink>
      ))}
    </Nav>
  </React.Fragment>
);

const numberWithCommas = (x) => {
  return x.toLocaleString("hr-HR", { minimumFractionDigits: 2 });
};

class TaskProcess extends Component {
  state = {
    loading: true,
    transactions: [],
    activeTab: null,
    taskId: null,
    errorModalOpen: false,
    errorStates: [],
    exceptionError: undefined,
  };

  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE_TRANSACTION)) {
      localStorage.setItem(
        TABLE_CODE_TRANSACTION,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    if (id) {
      this.setState({ taskId: id });
      this.getTaskShipments(id);
    } else {
      this.setState({ loading: false });
    }
  }

  getTaskShipments = (id) => {
    this.setState({ loading: true });
    fetch(`api/task/getTransactionData?taskId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            this.setState({
              transactions: data,
              activeTab: data[0] && data[0].code,
            });
          });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while getting transactions", e);
        this.setState({ loading: false });
      });
  };

  isString = (s) => {
    return typeof s === "string" || s instanceof String;
  };

  closeErrorModal = () => {
    this.setState({ errorModalOpen: false });
  };

  openErrorModal = () => {
    this.setState({ errorModalOpen: true });
  };

  getCurrencyDenominationFilter = (currency) => {
    const filters = {};
    if (currency) {
      filters.currency = {
        type: "long",
        key: "currency.id",
        value: currency.id,
      };
    }
    return getAutocompleteURL(
      "",
      "columns=id&columns=name&columns=denominationType&columns=value&allowedOnly=true",
      filters
    );
  };

  getCurrencySeriesFilter = (currency) => {
    const filters = {};
    if (currency) {
      filters.currency = {
        type: "long",
        key: "currency.id",
        value: currency.id,
      };
    }
    return getAutocompleteURL("", "columns=id&columns=name", filters);
  };

  changeSpecificationCurrency = (code, newCurrency) => {
    const { transactions, activeTab } = this.state;
    const newTransactions = transactions.map((transaction) => {
      let newTransaction = transaction;
      if (transaction.code === code) {
        newTransaction = {
          ...transaction,
          specification: {
            ...transaction.specification,
            currency: newCurrency,
          },
        };
      }
      return newTransaction;
    });
    this.setState({ transactions: newTransactions });
  };

  isAddItemButtonDisabled = (values) => {
    const {
      tempCurrencyDenomination,
      tempQuality,
      tempPieces,
      tempCurrencySeries,
      currency,
    } = values;
    return (
      !tempCurrencyDenomination ||
      !tempQuality ||
      !tempPieces ||
      !currency ||
      (tempCurrencyDenomination && this.isString(tempCurrencyDenomination)) ||
      (tempCurrencySeries && this.isString(tempCurrencySeries))
    );
  };

  addItemToSpecification = (specification, values) => {
    const {
      tempCurrencyDenomination,
      tempQuality,
      tempPieces,
      tempCurrencySeries,
      tempStatus,
      currency,
    } = values;

    const newItem = {
      currencyDenomination: tempCurrencyDenomination,
      quality: Number(tempQuality),
      pieces: tempPieces,
      currencySeries: this.isString(tempCurrencySeries)
        ? undefined
        : tempCurrencySeries,
      status: tempStatus ? Number(tempStatus) : undefined,
    };

    console.log(
      "NEW ITEM",
      newItem,
      tempCurrencySeries instanceof String,
      tempCurrencySeries instanceof String ? undefined : tempCurrencySeries
    );

    let shouldAddToList = true;
    const modifiedList = specification.specificationItems.map((item) => {
      if (
        item.currencyDenomination.id === newItem.currencyDenomination.id &&
        ((item.currencySeries == undefined &&
          newItem.currencySeries == undefined) ||
          (item.currencySeries != undefined &&
            newItem.currencySeries != undefined &&
            item.currencySeries.id === newItem.currencySeries.id)) &&
        +newItem.quality === +item.quality &&
        ((newItem.status == undefined && item.status == undefined) ||
          (newItem.status != undefined &&
            item.status != undefined &&
            +newItem.status === +item.status))
      ) {
        shouldAddToList = false;
        return {
          ...item,
          pieces: +newItem.pieces + +item.pieces,
        };
      }
      return item;
    });
    if (shouldAddToList) {
      modifiedList.push(newItem);
    }

    this.setState((prevState) => {
      const { transactions } = prevState;
      const newTransactions = transactions.map((item) => {
        const existingSpec = item.specification;
        if (specification.code === existingSpec.code) {
          existingSpec.specificationItems = modifiedList;
          existingSpec.amount =
            +existingSpec.amount +
            +newItem.pieces * +newItem.currencyDenomination.value;
        }
        return item;
      });
      return { transactions: newTransactions };
    });
  };

  removeItem = (row) => {
    const { transactions, activeTab } = this.state;
    const newTransactions = transactions.map((transaction) => {
      let newTransaction = transaction;
      if (transaction.code === activeTab) {
        const newSpecItems =
          transaction.specification.specificationItems.filter(
            (item) =>
              !(
                item.currencyDenomination.id === row.currencyDenomination.id &&
                ((item.currencySeries == undefined &&
                  row.currencySeries == undefined) ||
                  (item.currencySeries != undefined &&
                    row.currencySeries != undefined &&
                    item.currencySeries.id === row.currencySeries.id)) &&
                +row.quality === +item.quality &&
                ((row.status == undefined && item.status == undefined) ||
                  (row.status != undefined &&
                    item.status != undefined &&
                    +row.status === +item.status))
              )
          );
        newTransaction = {
          ...transaction,
          specification: {
            ...transaction.specification,
            amount:
              transaction.specification.amount -
              +row.pieces * +row.currencyDenomination.value,
            specificationItems: newSpecItems,
          },
        };
      }
      return newTransaction;
    });
    this.setState({ transactions: newTransactions });
  };

  processTask = () => {
    const { transactions, taskId } = this.state;
    const { history } = this.props;

    const requestBody = {
      taskId,
      transactions,
    };

    this.setState({
      processing: true,
      errorStates: [],
      exceptionError: undefined,
    });
    fetch(`api/task/processTask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            if (result.status === 0) {
              history.push(backPath);
            } else {
              this.setState({
                errorStates: result.errorStates,
                exceptionError: result.exceptionError,
                processing: false,
              });
              this.openErrorModal();
            }
          });
        } else {
          this.setState({ processing: false });
        }
      })
      .catch((e) => {
        console.error("Error while processing task", e);
        this.setState({ processing: false });
      });
  };

  toggle(tab) {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { history, userInfo } = this.props;
    const {
      loading,
      processing,
      transactions,
      activeTab,
      errorModalOpen,
      errorStates,
      exceptionError,
    } = this.state;

    if (loading) {
      return <CustomSpinner />;
    }

    let defaultCurrency = undefined;
    if (userInfo.companyEmployer) {
      defaultCurrency = userInfo.companyEmployer.defaultCurrency;
    }

    const mapTransaction = {
      denomName: {
        field: "currencyDenomination.name",
        header: "specification.table.row.name",
        properties: {},
      },
      currencySeries: {
        field: "currencySeries.name",
        header: "specification.table.row.series",
        properties: {},
      },
      pieces: {
        field: "pieces",
        header: "specification.table.row.pieces",
        properties: {},
      },
      quality: {
        field: "quality",
        header: "specification.table.row.quality",
        properties: { type: "enum", translation: "specificationItem.quality." },
      },
      status: {
        field: "status",
        header: "specification.table.row.status",
        properties: { type: "enum", translation: "specificationItem.status." },
      },
      buttonRemove: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-x",
          style: { width: "1em" },
          action: this.removeItem,
          returnRow: true,
        },
      },
    };

    return (
      <div>
        <Modal isOpen={errorModalOpen} className="custommodal--small">
          <ModalHeader toggle={() => this.closeErrorModal()}>
            <span>{getTranslation("task.process.error.header")}</span>
          </ModalHeader>
          <ModalBody>
            {errorStates && errorStates.length > 0 && (
              <span>
                <b>{getTranslation("task.process.missing.error.header")}</b>
              </span>
            )}
            {errorStates &&
              errorStates.map((errorState) => {
                return (
                  <React.Fragment>
                    <Row style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <span>
                        {getTranslation("task.process.currency.denomination")}:{" "}
                        <b>{errorState.currencyDenomination.name}</b>{" "}
                        {getTranslation("task.process.currency.series")}:{" "}
                        <b>
                          {errorState.currencySeries
                            ? errorState.currencySeries.name
                            : "-"}
                        </b>{" "}
                      </span>
                    </Row>
                    <Row style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                      <span>
                        {getTranslation("task.process.currency.amount")}:{" "}
                        <b>{errorState.amount}</b>{" "}
                        {getTranslation("task.process.currency.quality")}:{" "}
                        <b>
                          {getTranslation(
                            `specificationItem.quality.${errorState.quality}`
                          )}
                        </b>{" "}
                        {getTranslation("task.process.currency.status")}:{" "}
                        <b>
                          {errorState.status != undefined
                            ? getTranslation(
                                `specificationItem.quality.${errorState.status}`
                              )
                            : "-"}
                        </b>
                      </span>
                    </Row>
                    <hr />
                  </React.Fragment>
                );
              })}
            {exceptionError && (
              <span>
                {getTranslation("task.process.exception.error.header")}
              </span>
            )}
          </ModalBody>
        </Modal>
        {processing && <CustomProgress />}
        <Row>
          <Button
            className="margin--bottom"
            onClick={() => history.push(backPath)}
          >
            <span className="cil-arrow-left" />
          </Button>
          <Button
            disabled={processing}
            className="margin--bottom"
            onClick={(e) => {
              e.preventDefault();
              this.processTask();
            }}
            color="primary"
          >
            <span>{getTranslation("task.process.button.continue")}</span>
          </Button>
        </Row>
        <TabNavigation
          activeTab={activeTab}
          switchTab={(tab) => this.toggle(tab)}
          arrayFromWhichToGenerate={transactions}
        />
        <TabContent activeTab={activeTab}>
          {transactions.map((item) => {
            const initialValues = {
              ...item.specification,
              tempQuality: NORMAL_QUALITY_CODE,
              currency: defaultCurrency,
            };

            return (
              <TabPane tabId={item.code} key={`tab-${item.code}`}>
                <Formik
                  initialValues={initialValues}
                  render={({
                    errors,
                    status,
                    touched,
                    values,
                    setFieldValue,
                  }) => {
                    const handleCurrencyChange = (value) => {
                      setFieldValue("currency", value);
                      setFieldValue("tempCurrencyDenomination", null);
                      setFieldValue("tempCurrencySeries", null);
                      this.changeSpecificationCurrency(item.code, value);
                    };
                    const currencyFilter =
                      '/autocomplete?columns=id&columns=code&columns=name&filter={"search":{"type":"string","key":"name","value":';
                    return (
                      <Row>
                        <Col xs="12">
                          <Form className="form--container">
                            <Row>
                              <Col md="6">
                                <Field
                                  disabled={
                                    item.specification.specificationItems
                                      .length > 0
                                  }
                                  labeltext="specification.create.currency"
                                  name="currency"
                                  onselected={(value) =>
                                    handleCurrencyChange(value)
                                  }
                                  tabledatabasename="currency"
                                  filter={currencyFilter}
                                  showcolumn="name"
                                  component={DropDownComponent}
                                />
                              </Col>
                              <Col md="6">
                                <Field
                                  disabled={!values.currency}
                                  labeltext="order.create.denom"
                                  name="tempCurrencyDenomination"
                                  onselected={(value) =>
                                    setFieldValue(
                                      "tempCurrencyDenomination",
                                      value
                                    )
                                  }
                                  tabledatabasename="currencyDenomination"
                                  filter={this.getCurrencyDenominationFilter(
                                    values.currency
                                  )}
                                  showcolumn="name"
                                  component={DropDownComponent}
                                />
                              </Col>
                              <Col md="6">
                                <Field
                                  labeltext="order.create.quality"
                                  enumname="SpecificationItemQuality"
                                  onselected={(value) =>
                                    setFieldValue("tempQuality", value)
                                  }
                                  name="tempQuality"
                                  component={EnumeratorComponent}
                                />
                              </Col>
                              <Col md="6">
                                <Field
                                  name="tempPieces"
                                  labeltext="order.create.amount"
                                  component={TextInput}
                                />
                              </Col>
                              <Col md="6">
                                <Field
                                  disabled={!values.currency}
                                  labeltext="order.create.series"
                                  name="tempCurrencySeries"
                                  onselected={(value) => {
                                    setFieldValue("tempCurrencySeries", value);
                                  }}
                                  tabledatabasename="currencySeries"
                                  filter={this.getCurrencySeriesFilter(
                                    values.currency
                                  )}
                                  showcolumn="name"
                                  component={DropDownComponent}
                                />
                              </Col>
                              <Col md="6">
                                <Field
                                  allowclear={true}
                                  labeltext="order.create.status"
                                  enumname="SpecificationItemStatus"
                                  onselected={(value) =>
                                    setFieldValue("tempStatus", value)
                                  }
                                  name="tempStatus"
                                  component={EnumeratorComponent}
                                />
                              </Col>
                            </Row>
                            <Button
                              disabled={
                                this.isAddItemButtonDisabled(values) ||
                                processing
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.addItemToSpecification(
                                  item.specification,
                                  values
                                );
                              }}
                              color="primary"
                            >
                              {getTranslation(
                                "specifications.table.button.add"
                              )}
                            </Button>

                            {item.specification && (
                              <CustomTable
                                data={{
                                  data: item.specification.specificationItems,
                                  count: 0,
                                }}
                                tableMap={mapTransaction}
                                tableCode={TABLE_CODE_TRANSACTION}
                                onTableChange={() => {}}
                                hidePagination
                              />
                            )}
                            {item.specification && (
                              <Col md="12" style={{ padding: 0 }}>
                                {getTranslation(
                                  "specifications.table.total.amount"
                                )}
                                :{" "}
                                <b>
                                  {numberWithCommas(item.specification.amount)}
                                </b>
                              </Col>
                            )}
                          </Form>
                        </Col>
                      </Row>
                    );
                  }}
                />
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

export default connect(mapStateToProps)(TaskProcess);
