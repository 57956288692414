import { showErrorAlert } from 'actionCreators/spinnerActionCreator';
import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getTranslation } from 'util/localisation';
import CustomSpinner from '../Spinner/CustomSpinner';
import CashCenterForm from './CashCenterForm';
import VaultState from './VaultState';

const TabNavigation = ({ editing, activeTab, switchTab }) => (
  <React.Fragment>
    <Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '1' })}
          onClick={() => {
            switchTab('1');
          }}
        >
          {getTranslation('cash.center.tab.label.general')}
        </NavLink>
      </NavItem>
      {editing && (
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === '2',
            })}
            onClick={() => {
              switchTab('2');
            }}
          >
            {getTranslation('cash.center.tab.label.vault')}
          </NavLink>
        </NavItem>
      )}
    </Nav>
  </React.Fragment>
);

class CashCenterEdit extends Component {
  state = {
    cashCenterData: null,
    loading: true,
    activeTab: '1',
    editing: false,
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getCashCenter(id);
      this.setState({ editing: true });
    } else {
      this.setState({ loading: false });
    }
  }

  getCashCenter = id => {
    const { showErrorAlert } = this.props;
    this.setState({ loading: true });
    fetch(`api/cashCenter/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then(response => {
        if (response.status === 200) {
          response.json().then(formData => {
            this.setState({ cashCenterData: formData, loading: false });
          });
        } else {
          showErrorAlert(getTranslation('api.error.fetching'));
          this.setState({ loading: false });
        }
      })
      .catch(e => {
        console.error('Error while getting cash center', e);
        showErrorAlert(getTranslation('api.error.fetching'));
        this.setState({ loading: false });
      });
  };

  deleteCashCenter = () => {
    const { match, history, showErrorAlert } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/cashCenter/${id}`;
      fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${localStorage.auth}`,
        },
      })
        .then(r => {
          if (r.status === 200) {
            history.push('/app/cashCenter');
          } else {
            showErrorAlert(getTranslation('api.error.deleting'));
          }
        })
        .catch(e => {
          console.error('Error deleting cash center', e);
          showErrorAlert(getTranslation('api.error.deleting'));
        });
    }
  };

  handleSavingFail = () => {
    const { showErrorAlert } = this.props;
    showErrorAlert(getTranslation('api.error.saving'));
  };

  saveCashCenter(formData) {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/cashCenter/${id}`;
      return fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(formData),
      });
    }
    // new save
    const url = 'api/cashCenter';
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(formData),
    });
  }

  toggle(tab) {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { history, match } = this.props;
    const { id } = match.params;
    const { cashCenterData, loading, activeTab, editing } = this.state;
    const backPath = '/app/cashCenter';

    if (loading) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>

        <TabNavigation
          activeTab={activeTab}
          switchTab={tab => this.toggle(tab)}
          editing={editing}
        />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <CashCenterForm
              initialValues={cashCenterData}
              onSubmit={formData => this.saveCashCenter(formData)}
              onSubmitFail={this.handleSavingFail}
              onDelete={() => this.deleteCashCenter()}
              history={history}
              backPath={backPath}
            />
          </TabPane>
          <TabPane tabId="2">
            <VaultState cashCenterId={id} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showErrorAlert: error => dispatch(showErrorAlert(error)),
});

export default connect(
  null,
  mapDispatchToProps
)(CashCenterEdit);
