import PropTypes from "prop-types";
import React, { Component } from "react";
import { Table } from "reactstrap";
import { getTranslation } from "util/localisation";
import CustomPagination from "./CustomPagination";
import "./CustomTable.scss";
import TableRow from "./TableRow";
import CustomProgress from "../Progress/CustomProgress";

export default class CustomTable extends Component {
  state = {
    dropdownOpen: false,
    pageInputValue: "",
  };

  changePageInputValue = (newPage) => {
    this.setState({ pageInputValue: newPage });
  };

  jumpToPage = () => {
    const { pageInputValue } = this.state;
    this.changePage(null, +pageInputValue - 1);
  };

  toggle = () => {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  changePage = (e, nextPage) => {
    const { tableCode, data } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(tableCode));
    const { maxItems } = tableInfo;

    const maxPages = Math.ceil(data.count / maxItems);
    let currentPage = +nextPage;
    if (+nextPage > +maxPages - 1) {
      currentPage = +maxPages - 1;
    } else if (+nextPage < 0) {
      currentPage = 0;
    }

    localStorage.setItem(
      tableCode,
      JSON.stringify({
        ...tableInfo,
        currentPage: currentPage,
      })
    );
    this.handleTableChange();
  };

  select = (e) => {
    const maxItems = e.target.innerText;
    const { tableCode } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(tableCode));
    localStorage.setItem(
      tableCode,
      JSON.stringify({
        ...tableInfo,
        maxItems,
      })
    );
    this.handleTableChange();
  };

  change = (e, row) => {
    const { edit } = this.props;
    if (edit) {
      edit(row);
    }
  };

  handleTableChange() {
    this.props.onTableChange();
  }

  render() {
    const { tableCode, data, tableMap, hidePagination, loading } = this.props;
    const { dropdownOpen, pageInputValue } = this.state;
    const tableInfo = JSON.parse(localStorage.getItem(tableCode));
    const { maxItems, currentPage } = tableInfo;

    const maxPages = Math.ceil(data.count / maxItems);
    let counter = 0;

    return (
      <div className="animated fadeIn">
        {loading && <CustomProgress />}
        <Table striped responsive hover size="sm" bordered>
          <thead>
            <tr>
              {Object.keys(tableMap).map((key) => (
                <th key={tableMap[key].header} width={tableMap[key].width}>
                  {getTranslation(tableMap[key].header)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.data.map((row) => (
              <TableRow
                key={row.id}
                data={row}
                tableMap={tableMap}
                doubleClick={(e) => this.change(e, row)}
              />
            ))}
          </tbody>
        </Table>
        {!hidePagination && (
          <CustomPagination
            pageInputValue={pageInputValue}
            changePageInputValue={this.changePageInputValue}
            jumpToPage={this.jumpToPage}
            changePage={this.changePage}
            currentPage={currentPage}
            maxPages={maxPages}
            toggle={this.toggle}
            dropdownOpen={dropdownOpen}
            select={this.select}
            maxItems={maxItems}
          />
        )}
      </div>
    );
  }
}

CustomTable.propTypes = {
  tableMap: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};
