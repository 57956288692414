import React from "react";
import { Button } from "reactstrap";
import { getValue } from "util/api/Handlers";
import { getTranslation } from "util/localisation";

const TableRow = (props) => {
  const cells = [];
  const { tableMap, data, doubleClick, loading } = props;
  let styles = {};

  const handleDoubleClick = (e, row) => {
    doubleClick(e, row);
  };

  const CURRENCY_LOCALE = ["hr-HR", { minimumFractionDigits: 2 }];

  const renderTableButton = (
    id = null,
    item = null,
    action,
    className,
    text,
    isDisabled = false
  ) => {
    return (
      <Button
        className={"table-button"}
        disabled={isDisabled}
        onClick={() => action(id, item)}
      >
        <span className={className} />
        {text}
      </Button>
    );
  };

  Object.keys(tableMap).forEach((key) => {
    tableMap[key].value = "";
    if (tableMap[key].field) {
      tableMap[key].value = getValue(data, tableMap[key].field);
    }
    let cellContent = tableMap[key].value;
    if (tableMap[key].render) {
      cellContent = tableMap[key].render(data);
    } else if (tableMap[key].properties) {
      const mapProperty = tableMap[key].properties;
      if (mapProperty) {
        if (mapProperty.type === "date") {
          if (cellContent) {
            cellContent = new Date(cellContent).toLocaleString("hr-HR");
          }
        } else if (mapProperty.type === "date-short") {
          if (cellContent) {
            cellContent = new Date(cellContent).toLocaleString("hr-HR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
          }
        } else if (mapProperty.type === "sum" && mapProperty.cellsToAdd) {
          cellContent =
            +tableMap[mapProperty.cellsToAdd[0]].value *
            +tableMap[mapProperty.cellsToAdd[1]].value;
          if (mapProperty.format === "currency") {
            cellContent = new Intl.NumberFormat(...CURRENCY_LOCALE).format(
              cellContent
            );
          }
        } else if (mapProperty.type === "time") {
          cellContent = new Date(cellContent).toLocaleTimeString("hr-HR");
        } else if (mapProperty.type === "enum" && mapProperty.translation) {
          if (cellContent != undefined) {
            cellContent = getTranslation(
              `${mapProperty.translation}${cellContent}`
            );
          }
        } else if (mapProperty.type === "boolean") {
          if (
            cellContent &&
            (cellContent === true ||
              cellContent === "true" ||
              cellContent === 1)
          ) {
            cellContent = getTranslation("response.boolean.true");
          } else {
            cellContent = getTranslation("response.boolean.false");
          }
        } else if (mapProperty.type === "button") {
          const { id } = tableMap;
          styles = {
            ...styles,
            ...mapProperty.style,
          };
          let allConditions = true;
          if (mapProperty.show) {
            mapProperty.show.forEach((conditionField) => {
              let condition = false;
              conditionField.values.forEach((value) => {
                if (getValue(data, conditionField.field) === value) {
                  condition = true;
                }
              });
              if (condition === false) {
                allConditions = false;
              }
            });
          }

          if (allConditions) {
            let returnValue = null;
            if (mapProperty.returnRow) {
              returnValue = data;
            } else if (id) {
              returnValue = id.value;
            }
            cellContent = renderTableButton(
              returnValue,
              data,
              mapProperty.action,
              mapProperty.icon,
              mapProperty.text,
              mapProperty.disabled
            );
          }
        } else if (mapProperty.type === "currency") {
          cellContent = new Intl.NumberFormat(...CURRENCY_LOCALE).format(
            cellContent
          );
        }
      }
    }

    if (tableMap[key].message) {
      const mapProperty = tableMap[key].message;
      if (mapProperty) {
        if (mapProperty.field && mapProperty.values && mapProperty.message) {
          let condition = false;
          mapProperty.values.forEach((value) => {
            if (getValue(data, mapProperty.field) === value) {
              condition = true;
            }
          });
          if (condition === true) {
            cellContent = mapProperty.message;
          }
        }
      }
    }

    cells.push(
      <td style={styles} key={key}>
        {cellContent}
      </td>
    );
  });

  return <tr onDoubleClick={(e) => handleDoubleClick(e, data)}>{cells}</tr>;
};

export default TableRow;
