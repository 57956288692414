import * as Constants from '../constants';

export const checkResponseStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  return response;
};

export const getValue = (object, key) => {
  if (key.includes('.')) {
    const search = key.split('.')[0];
    const startIndex = key.substring(key.indexOf('.') + 1);
    const endIndex = key.length;
    return getValue(object[search], startIndex, endIndex);
  }
  if (object) {
    return object[key];
  }
  return null;
};

export function formatDate(string) {
  return new Date(string).toLocaleString('de-DE').replace(',', '.');
}
