import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import CompanyEmployeeEdit from "./CompanyEmployeeEdit";

const TABLE_CODE = "companyEmployee.list";

class CompanyEmployeeList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    companyId: null,
    employeeId: null,
    modalOpen: false,
    employeeList: { data: [], count: 0 },
    loading: true,
  };

  componentDidMount() {
    this.getEmployees();
  }

  getEmployees() {
    this.setState({ loading: true });
    let fetchUrl = "";
    const { company } = this.props;

    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      "companyEmployer",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    if (company) {
      const { id } = company;
      this.setState({ companyId: id });
      fetchUrl = `api/${filter}&companyId=${id}`;
    } else {
      fetchUrl = `api/${filter}`;
    }

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({ employeeList: data, loading: false });
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
            this.setState({ loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Error while getting employees: ", error);
        this.setState({ loading: false });
      });
  }

  editExistingUser = (userInstance) => {
    const { history } = this.props;
    if (this.state.companyId) {
      this.setState({ employeeId: userInstance.id });
      this.setState({ modalOpen: true });
    } else {
      history.push(`/app/user/${userInstance.id}`);
    }
  };

  createNewUser = () => {
    const { history } = this.props;
    if (this.state.companyId) {
      this.setState({ employeeId: null });
      this.setState({ modalOpen: true });
    } else {
      history.push("/app/user/create");
    }
  };

  onModalClose = (e, refetchEmployees = false) => {
    const { history } = this.props;
    if (this.state.companyId) {
      this.setState({ employeeId: null });
      this.setState({ modalOpen: false });
    } else {
      history.push("/app/user/");
    }
    if (refetchEmployees) {
      this.getEmployees();
    }
  };

  isDetailModalOpen = () => {
    const { match } = this.props;
    return match.params.employeeId ? true : this.state.modalOpen;
  };

  render() {
    const { loading, employeeList } = this.state;

    const { company, match } = this.props;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      username: {
        field: "user.username",
        header: "companyEmployer.create.label.username",
        properties: {},
      },
      firstName: {
        field: "user.firstName",
        header: "companyEmployer.create.label.firstName",
        properties: {},
      },
      lastName: {
        field: "user.lastName",
        header: "companyEmployer.create.label.lastName",
        properties: {},
      },
    };

    return (
      <div>
        <Modal isOpen={this.isDetailModalOpen()} className="custommodal">
          <ModalHeader toggle={this.onModalClose}>
            <span>Zaposlenik</span>
          </ModalHeader>
          <ModalBody>
            <CompanyEmployeeEdit
              afterSubmit={this.onModalClose}
              company={company}
              employeeId={this.state.employeeId}
              match={match}
            />
          </ModalBody>
        </Modal>
        <h2>{getTranslation("navbar.companyEmployer")}</h2>

        <Button
          className="tableButtonMargins"
          color="primary"
          onClick={this.createNewUser}
        >
          {getTranslation("companyEmployer.create.button.name")}
        </Button>

        <CustomTable
          data={employeeList}
          tableMap={map}
          edit={this.editExistingUser}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getEmployees()}
        />
      </div>
    );
  }
}

export default CompanyEmployeeList;
