import { showErrorAlert } from 'actionCreators/spinnerActionCreator';
import CustomSpinner from 'components/Spinner/CustomSpinner';
import CustomTable from 'components/Table/CustomTable';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { getFilterURL } from 'util/api/callGenerator';
import { getTranslation } from 'util/localisation';

const TABLE_CODE = 'city.list';

class CityList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    cityList: { data: [], count: 0 },
    loading: true,
  };

  componentDidMount() {
    this.getCityList();
  }

  getCityList() {
    this.setState({ loading: true });
    const { showErrorAlert } = this.props;
    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      'city',
      '',
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    fetch(`api/${filter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then(data => {
            this.setState({ cityList: data, loading: false });
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
          });
        } else {
          showErrorAlert(getTranslation('api.error.fetching'));
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error('Error while getting cities: ', error);
        showErrorAlert(getTranslation('api.error.fetching'));
        this.setState({ loading: false });
      });
  }

  edit = item => {
    const { history } = this.props;
    history.push(`/app/city/${item.id}`);
  };

  addNewCity = () => {
    const { history } = this.props;
    history.push('/app/city/create');
  };

  render() {
    const { loading, cityList } = this.state;

    const map = {
      id: { field: 'id', header: 'tables.id', properties: {} },
      name: { field: 'name', header: 'city.table.header.name', properties: {} },
      postalCode: {
        field: 'postalCode',
        header: 'city.table.header.postalCode',
        properties: {},
      },
      code: { field: 'code', header: 'city.table.header.code', properties: {} },
    };

    return (
      <React.Fragment>
        <Button
          outline
          className="margin--bottom"
          color="primary"
          onClick={this.addNewCity}
        >
          {getTranslation('city.create.button.name')}
        </Button>
        <CustomTable
          data={cityList}
          tableMap={map}
          edit={this.edit}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getCityList()}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showErrorAlert: error => dispatch(showErrorAlert(error)),
});

export default connect(
  null,
  mapDispatchToProps
)(CityList);
