import TextInput from "../FormComponents/TextInput";
import CustomSpinner from "../Spinner/CustomSpinner";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import { getTranslation } from "util/localisation";
import * as Yup from "yup";
import CustomProgress from "../Progress/CustomProgress";

const HolidaySchema = Yup.object().shape({
  name: Yup.string().required("Unesite naziv!"),
  date: Yup.string()
    .required("Unesite datum!")
    .matches(
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])$/,
      getTranslation("holidayDate.validation")
    ),
  year: Yup.string().matches(/^\d+$/, getTranslation("holidayYear.validation")),
});

class HolidayEdit extends Component {
  state = {
    holidayData: null,
    loading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getHoliday(id);
    } else {
      this.setState({ loading: false });
    }
  }

  getHoliday = (id) => {
    this.setState({ loading: true });
    fetch(`api/holiday/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.setState({ holidayData: formData, loading: false });
          });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error("Error while getting holiday", e);
        this.setState({ loading: false });
      });
  };

  deleteHoliday = () => {
    const { match, history } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/holiday/${id}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }).then((r) => {
        console.log(r);
        history.push("/app/holiday");
      });
    }
  };

  saveHoliday(formData) {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      // editing
      const url = `api/holiday/${id}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(formData),
      });
    }
    // new save
    const url = "api/holiday";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(formData),
    });
  }

  render() {
    const { history } = this.props;
    const { holidayData, loading } = this.state;
    const backPath = "/app/holiday";

    if (loading) {
      return <CustomSpinner />;
    }

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>
        <Formik
          enableReinitialize
          validationSchema={HolidaySchema}
          initialValues={holidayData}
          onSubmit={(values, actions) => {
            this.saveHoliday(values).then((response) => {
              console.log(response);
              actions.setSubmitting(false);
              history.push(backPath);
            });
          }}
          render={({ errors, status, touched, isSubmitting }) => {
            return (
              <Row>
                <Col xs="12">
                  <Form className="form--container">
                    {isSubmitting && <CustomProgress />}
                    <Row>
                      <Col md="6">
                        <Field
                          labeltext="holiday.create.label.name"
                          name="name"
                          required
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          labeltext="holiday.create.label.date"
                          name="date"
                          required
                          component={TextInput}
                        />
                      </Col>
                      <Col md="6">
                        <Field
                          labeltext="holiday.create.label.year"
                          name="year"
                          component={TextInput}
                        />
                      </Col>
                    </Row>
                    <Button type="submit" disabled={isSubmitting}>
                      {getTranslation("table.button.save")}
                    </Button>
                    {holidayData && (
                      <Button
                        color="danger"
                        onClick={this.holidayData}
                        disabled={isSubmitting}
                      >
                        {getTranslation("table.button.delete")}
                      </Button>
                    )}
                  </Form>
                </Col>
              </Row>
            );
          }}
        />
      </div>
    );
  }
}

export default HolidayEdit;
