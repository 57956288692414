import {
  MEDITERAN_SECURITY_ROLES,
  ROLE_WEB_ADMIN,
  SC_ROLES,
} from "util/roles/roleList";

const navs = {
  items: [
    {
      name: "navbar.adminPanel",
      url: "/",
      icon: "cil-settings",
      allowedRoles: [
        ROLE_WEB_ADMIN,
        SC_ROLES.ROLE_SC_ADMIN,
        SC_ROLES.ROLE_WEB_BANK_ADMIN,
        SC_ROLES.ROLE_WEB_CUSTOMER_ADMIN,
        SC_ROLES.ROLE_WEB_CUSTOMER,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_SPECIFICATIONS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ORDERS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_TASKS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_OBJECTS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_OBJECTS_COMPANY,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS_COMPANY,
      ],
      children: [
        {
          name: "navbar.currency",
          allowedRoles: [
            SC_ROLES.ROLE_SC_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
          ],
          url: "/app/currency",
          icon: "cil-british-pound",
        },
        {
          name: "navbar.company",
          allowedRoles: [
            SC_ROLES.ROLE_SC_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_OBJECTS,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_OBJECTS_COMPANY,
          ],
          url: "/app/company",
          icon: "cil-briefcase",
        },
        {
          name: "navbar.user",
          allowedRoles: [
            SC_ROLES.ROLE_SC_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_USERS_COMPANY,
          ],
          url: "/app/user",
          icon: "cil-people",
        },
        {
          name: "navbar.city",
          allowedRoles: [MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN],
          url: "/app/city",
          icon: "cil-building",
        },
        {
          name: "navbar.cash.center",
          allowedRoles: [MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN],
          url: "/app/cashCenter",
          icon: "cil-bank",
        },
        {
          name: "navbar.tenant.company",
          allowedRoles: [ROLE_WEB_ADMIN],
          url: "/app/companyTenant",
          icon: "cil-briefcase",
        },
        {
          name: "navbar.delivery.vehicle",
          allowedRoles: [MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN],
          url: "/app/deliveryVehicle",
          icon: "cil-car-alt",
        },
        {
          name: "navbar.holiday",
          allowedRoles: [MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN],
          url: "/app/holiday",
          icon: "cil-calendar",
        },
        {
          name: "navbar.task.type",
          allowedRoles: [MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN],
          url: "/app/taskType",
          icon: "cil-list",
        },
        {
          name: "navbar.specification",
          allowedRoles: [
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_SPECIFICATIONS,
          ],
          url: "/app/specification",
          icon: "cil-task",
        },
        {
          name: "navbar.order",
          allowedRoles: [
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ORDERS,
          ],
          url: "/app/order",
          icon: "cil-task",
        },
        {
          name: "navbar.task",
          allowedRoles: [
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
            MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_TASKS,
          ],
          url: "/app/task",
          icon: "cil-task",
        },
        {
          name: "navbar.ftp.report",
          allowedRoles: [MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN],
          url: "/app/ftpReport",
          icon: "cil-description",
        },
        {
          name: "navbar.settings",
          allowedRoles: [MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN],
          url: "/app/settings",
          icon: "cil-settings",
        },
      ],
    },
    {
      name: "navbar.specification",
      allowedRoles: [
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_SPECIFICATIONS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CASH_CENTER,
      ],
      forbiddenRoles: [
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_SPECIFICATIONS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
      ],
      url: "/app/specification",
      icon: "cil-task",
    },
    {
      name: "navbar.order",
      allowedRoles: [
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_VIEW,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_MONITOR,
      ],
      forbiddenRoles: [
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ORDERS,
      ],
      url: "/app/clientOrder",
      icon: "cil-task",
    },
  ],
};

export default navs;
