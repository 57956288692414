import React from 'react';
import { Progress } from 'reactstrap';
import './CustomProgress.scss';

const CustomProgress = () => (
  <div className="progress">
    <div className="progress-bar progress-bar-striped indeterminate" />
  </div>
);

export default CustomProgress;
