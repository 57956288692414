import React from 'react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Button,
} from 'reactstrap';
import { getTranslation } from 'util/localisation';

const NUM_PAGES_AFTER_CURRENT = 2;
const NUM_PAGES_BEFORE_CURRENT = 2;

const CustomPagination = ({
  currentPage,
  changePage,
  maxPages,
  toggle,
  dropdownOpen,
  select,
  maxItems,
  pageInputValue,
  changePageInputValue,
  jumpToPage,
}) => (
  <Pagination>
    <PaginationItem disabled={currentPage <= 0}>
      <PaginationLink onClick={e => changePage(e, 0)}>1</PaginationLink>
    </PaginationItem>
    <PaginationItem disabled={currentPage <= 0}>
      <PaginationLink
        previous
        tag="button"
        onClick={e => changePage(e, currentPage - 1)}
      />
    </PaginationItem>
    {[...Array(maxPages)].map((page, i) => {
      if (
        currentPage - i <= NUM_PAGES_BEFORE_CURRENT &&
        i - currentPage <= NUM_PAGES_AFTER_CURRENT
      ) {
        return (
          <PaginationItem active={i === currentPage} key={i}>
            <PaginationLink tag="button" onClick={e => changePage(e, i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }
      return null;
    })}

    <PaginationItem disabled={currentPage >= maxPages - 1}>
      <PaginationLink
        next
        tag="button"
        onClick={e => changePage(e, currentPage + 1)}
      />
    </PaginationItem>

    <PaginationItem disabled={currentPage >= maxPages - 1}>
      <PaginationLink onClick={e => changePage(e, maxPages - 1)}>
        {maxPages}
      </PaginationLink>
    </PaginationItem>

    <ButtonDropdown
      className="pagination--margin"
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle caret>{maxItems}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={select}>10</DropdownItem>
        <DropdownItem onClick={select}>25</DropdownItem>
        <DropdownItem onClick={select}>50</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
    <Input
      className="pagination--margin"
      style={{ width: '200px' }}
      placeholder={getTranslation('pagination.jumpToPage.label')}
      value={pageInputValue}
      onChange={e => changePageInputValue(e.target.value)}
    />
    <Button className="pagination--margin" onClick={jumpToPage}>
      {getTranslation('pagination.jumpToPage.button')}
    </Button>
  </Pagination>
);

export default CustomPagination;
