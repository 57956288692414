import {
  hideMainSpinner,
  showMainSpinner,
} from "actionCreators/spinnerActionCreator";
import {
  GET_USER_ERROR,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
} from "actions/userActions";
import { getTranslation } from "util/localisation";

export const userSuccess = (user, navs) => ({
  type: GET_USER_SUCCESS,
  payload: { user, navs },
});

const userFailure = (failure) => ({
  type: GET_USER_FAILURE,
  fail: failure,
});

const userError = (error) => ({
  type: GET_USER_ERROR,
  error,
});

const filterNavsByRole = (navs, userData) => {
  const { authorities } = userData.user;
  const userRoles = authorities.map((auth) => auth.authority);

  const navItems = navs.items
    .filter((parent) => {
      const { allowedRoles, forbiddenRoles } = parent;
      const matchingRolesAllowed = userRoles.filter((userRole) =>
        allowedRoles.includes(userRole)
      );
      if (forbiddenRoles) {
        const matchingRolesForbidden = userRoles.filter((userRole) =>
          forbiddenRoles.includes(userRole)
        );
        return (
          matchingRolesAllowed.length > 0 && matchingRolesForbidden.length == 0
        );
      }
      return matchingRolesAllowed.length > 0;
    })
    .map((nav) => {
      if (nav.children && nav.children.length > 0) {
        nav.children = nav.children.filter((child) => {
          const { allowedRoles, forbiddenRoles } = child;
          const matchingRolesAllowed = userRoles.filter((userRole) =>
            allowedRoles.includes(userRole)
          );
          if (forbiddenRoles) {
            const matchingRolesForbidden = userRoles.filter((userRole) =>
              forbiddenRoles.includes(userRole)
            );
            return (
              matchingRolesAllowed.length > 0 &&
              matchingRolesForbidden.length == 0
            );
          }
          return matchingRolesAllowed.length > 0;
        });
      }
      return nav;
    });
  const filteredNavs = {
    items: navItems,
  };

  filteredNavs.items = navItems.map((nav) => {
    return {
      ...nav,
      name: getTranslation(nav.name),
      children:
        nav.children &&
        nav.children.map((child) => {
          return {
            ...child,
            name: getTranslation(child.name),
          };
        }),
    };
  });

  return filteredNavs;
};

export const checkUser = (navs) => {
  return (dispatch) => {
    dispatch(showMainSpinner());
    return fetch(`${"api/user/current"}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            const filteredNavs = filterNavsByRole(navs, data);
            dispatch(userSuccess(data, filteredNavs));
            dispatch(hideMainSpinner());
          });
        } else if (response.status >= 400 || response.status <= 500) {
          console.log(response);
          delete localStorage.auth;
          dispatch(userFailure("401"));
          dispatch(hideMainSpinner());
        }
      })
      .catch((error) => {
        console.error(error);
        delete localStorage.auth;
        userError(error);
        dispatch(hideMainSpinner());
      });
  };
};
