import React from 'react';
import btcLogo from '../../assets/img/brand/btc_logo.png';
import { getTranslation } from '../../util/localisation';

const Footer = () => (
  <React.Fragment>
    <span>
      <a target="_blank" rel="noopener noreferrer" href="http://btc.hr">
        © b.t.c. d.o.o.
      </a>
      , 1989.-
      {new Date().getFullYear()}. {getTranslation('footer.rights.text')}
    </span>
    <span className="ml-auto">
      <a target="_blank" rel="noopener noreferrer" href="http://btc.hr">
        <img src={btcLogo} alt="BTC logo" height="35px" />
      </a>
    </span>
  </React.Fragment>
);

export default Footer;
