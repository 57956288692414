import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { getTranslation } from "util/localisation";
import * as Yup from "yup";
import TextInput from "../FormComponents/TextInput";
import CustomProgress from "../Progress/CustomProgress";

const CurrencySchema = Yup.object().shape({
  name: Yup.string().required("Unesite naziv!").max(50),
  code: Yup.string("Unesite kod!").required().max(3),
  isoCode: Yup.string("Unesite ISO kod!").required().max(3),
});

const CurrencyForm = ({
  currencyData,
  onSubmit,
  onDelete,
  backPath,
  history,
}) => (
  <Formik
    validationSchema={CurrencySchema}
    initialValues={currencyData}
    onSubmit={(values, actions) => {
      onSubmit(values).then((r) => {
        actions.setSubmitting(false);
        history.push(backPath);
      });
    }}
    render={({ isSubmitting }) => {
      return (
        <React.Fragment>
          {isSubmitting && <CustomProgress />}
          <Row>
            <Col xs="12">
              <Form>
                <Row>
                  <Col md="6">
                    <Field
                      required
                      disabled={isSubmitting}
                      labeltext="currency.create.label.name"
                      name="name"
                      component={TextInput}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      required
                      disabled={isSubmitting}
                      labeltext="currency.create.label.code"
                      name="code"
                      component={TextInput}
                    />
                  </Col>
                  <Col md="6">
                    <Field
                      required
                      disabled={isSubmitting}
                      labeltext="currency.create.label.isoCode"
                      name="isoCode"
                      component={TextInput}
                    />
                  </Col>
                </Row>

                <div className="margin--top">
                  <Button
                    className="margin--right"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {getTranslation("table.button.save")}
                  </Button>
                  {currencyData && (
                    <Button
                      color="danger"
                      onClick={onDelete}
                      disabled={isSubmitting}
                    >
                      {getTranslation("table.button.delete")}
                    </Button>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </React.Fragment>
      );
    }}
  />
);

export default CurrencyForm;
