import CustomSpinner from "components/Spinner/CustomSpinner";
import CustomTable from "components/Table/CustomTable";
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import { getFilterURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import DropDownComponent from "../FormComponents/DropDownComponent";

const TABLE_CODE = "company.denomination.list";

class CompanyDenominationList extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    companyId: null,
    currencyId: null,
    denomId: null,
    editExisting: false,
    modalOpen: false,
    denomList: { data: [], count: 0 },
    currencyInputValue: "",
    currencyDenomInputValue: "",
    stackNumValue: "",
    stackNumError: false,
    loading: true,
    currencyFilter:
      '/autocomplete?columns=id&columns=code&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"code","value":',
    currencyDenomFilter:
      '/autocomplete?columns=id&columns=code&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"code","value":',
  };

  componentDidMount() {
    this.getDenoms();
  }

  getDenoms() {
    this.setState({ loading: true });
    let fetchUrl = "";
    const { company } = this.props;

    const tableInfo = JSON.parse(localStorage.getItem(TABLE_CODE));
    const filter = getFilterURL(
      "company/denoms",
      "",
      tableInfo.maxItems,
      tableInfo.currentPage * tableInfo.maxItems
    );

    if (company) {
      const { id } = company;
      this.setState({ companyId: id });
      fetchUrl = `api/${filter}&companyId=${id}`;
    } else {
      fetchUrl = `api/${filter}`;
    }

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({ denomList: data, loading: false });
            localStorage.setItem(
              TABLE_CODE,
              JSON.stringify({
                ...tableInfo,
              })
            );
            this.setState({ loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.error("Error while getting denoms: ", error);
        this.setState({ loading: false });
      });
  }

  onModalClose = (e, refetchDenoms = false) => {
    this.setState({
      editExisting: false,
      modalOpen: false,
      currencyInputValue: "",
      currencyDenomInputValue: "",
      stackNumValue: "",
      stackNumError: false,
    });
    this.getDenoms();
  };

  onCurrencySearch = (newValue) => {
    this.setState({ currencyInputValue: newValue });
  };

  onCurrencyDenomSearch = (newValue) => {
    this.setState({ currencyDenomInputValue: newValue });
  };

  onStackNumChange = (newValue) => {
    if (
      (newValue && !isNaN(newValue) && Number(newValue) > 0) ||
      newValue === ""
    ) {
      this.setState({ stackNumValue: newValue, stackNumError: false });
    } else {
      this.setState({ stackNumValue: "", stackNumError: true });
    }
  };

  onCurrencyChange = (newValue) => {
    if (newValue && newValue.id) {
      this.setState({
        currencyId: newValue.id,
        currencyDenomFilter:
          '/autocomplete?columns=id&columns=code&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "currencyId": { "type": "long", "key": "currency.id", "value":"' +
          newValue.id +
          '"}, "search":{"type":"string","key":"code","value":',
      });
    } else {
      this.setState({
        currencyId: null,
        currencyDenomFilter:
          '/autocomplete?columns=id&columns=code&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"code","value":',
      });
    }
  };

  onDenomChange = (newValue) => {
    if (newValue && newValue.id) {
      this.setState({ denomId: newValue.id });
    } else {
      this.setState({ denomId: null });
    }
  };

  addCompanyDenom = () => {
    this.setState({ loading: true });
    let url = `api/company/addDenom?companyId=${this.state.companyId}&denomId=${this.state.denomId}`;
    if (this.state.stackNumValue) {
      url += `&stackNum=${this.state.stackNumValue}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    }).then((response) => {
      this.setState({ loading: false });
      if (response.status === 200) {
        this.onModalClose();
      } else {
        showErrorAlert(getTranslation("api.error.fetching"));
      }
    });
  };

  removeCompanyDenom = (id) => {
    this.setState({ loading: true });
    fetch(
      `api/company/removeDenom?companyId=${this.state.companyId}&denomId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }
    ).then((response) => {
      this.setState({ loading: false });
      if (response.status === 200) {
        this.getDenoms();
      } else {
        showErrorAlert(getTranslation("api.error.fetching"));
      }
    });
  };

  edit = (row) => {
    this.onCurrencyChange(row.currency);
    this.onDenomChange(row);
    this.setState({
      editExisting: true,
      modalOpen: true,
      currencyInputValue: row.currency,
      currencyDenomInputValue: row,
      stackNumValue: row.stackNum,
    });
  };

  render() {
    const {
      loading,
      denomList,
      currencyInputValue,
      currencyDenomInputValue,
      currencyId,
      denomId,
      stackNumValue,
      stackNumError,
      editExisting,
    } = this.state;

    const { company, match } = this.props;

    const map = {
      id: { field: "id", header: "tables.id", properties: {} },
      currency: {
        field: "currency.code",
        header: "currency.denomination.table.header.currency",
        properties: {},
      },
      name: {
        field: "name",
        header: "currency.denomination.table.header.name",
        properties: {},
      },
      value: {
        field: "value",
        header: "currency.denomination.table.header.value",
        properties: {},
      },
      denominationType: {
        field: "denominationTypeText",
        header: "currency.denomination.table.header.type",
        properties: {},
      },
      code: {
        field: "code",
        header: "currency.denomination.table.header.code",
        properties: {},
      },
      stackNum: {
        field: "stackNum",
        header: "currency.denomination.table.header.stackNum",
        properties: {},
      },
      buttonReport: {
        properties: {
          type: "button",
          text: "",
          icon: "cil-x",
          style: { width: "1em" },
          action: this.removeCompanyDenom,
        },
      },
    };

    return (
      <div>
        <Modal isOpen={this.state.modalOpen}>
          <ModalHeader toggle={this.onModalClose}>
            <span>Denominacija</span>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <div>
                  <DropDownComponent
                    disabled={editExisting}
                    labeltext="company.denoms.label.currency"
                    onselected={(suggestion) => {
                      this.onCurrencyChange(suggestion);
                    }}
                    tabledatabasename="currency"
                    filter={this.state.currencyFilter}
                    showcolumn="code"
                    field={{
                      name: "currency",
                      value: currencyInputValue,
                      onChange: (e, { newValue }) =>
                        this.onCurrencySearch(newValue),
                    }}
                    form={{ errors: {}, touched: {} }}
                  />
                </div>
              </Col>
              <Col md="6">
                <div>
                  <DropDownComponent
                    disabled={!currencyId || editExisting}
                    labeltext="company.denoms.label.currencyDenomination"
                    onselected={(suggestion) => {
                      this.onDenomChange(suggestion);
                    }}
                    tabledatabasename="currencyDenomination"
                    filter={this.state.currencyDenomFilter}
                    showcolumn="code"
                    field={{
                      name: "currencyDenomination",
                      value: currencyDenomInputValue,
                      onChange: (e, { newValue }) =>
                        this.onCurrencyDenomSearch(newValue),
                    }}
                    form={{ errors: {}, touched: {} }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div>
                  <Label>
                    {getTranslation(
                      "currency.denomination.table.header.stackNum"
                    )}
                  </Label>
                  <Input
                    type="number"
                    defaultValue={this.state.stackNumValue}
                    className={this.state.stackNumError ? "isInvalid" : ""}
                    onKeyDown={(e) => {
                      if (e.key === ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      this.onStackNumChange(e.target.value);
                    }}
                  />
                  {this.state.stackNumError && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {getTranslation(
                        "currency.denomination.create.validation.quantity"
                      )}{" "}
                      0
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Button
              disabled={!denomId || loading || stackNumError}
              onClick={() => this.addCompanyDenom()}
            >
              {getTranslation("table.button.save")}
            </Button>
          </ModalBody>
        </Modal>
        <h2>{getTranslation("company.denoms.table.title")}</h2>

        <Button
          className="tableButtonMargins"
          color="primary"
          onClick={() => this.setState({ modalOpen: true })}
        >
          {getTranslation("company.denoms.button.add")}
        </Button>

        <CustomTable
          edit={this.edit}
          data={denomList}
          tableMap={map}
          tableCode={TABLE_CODE}
          loading={loading}
          onTableChange={() => this.getDenoms()}
        />
      </div>
    );
  }
}

export default CompanyDenominationList;
