const ROLE_WEB_ADMIN = "ROLE_WEB_ADMIN";

const ROLE_SC_ADMIN = "ROLE_SC_ADMIN";
const ROLE_WEB_BANK_ADMIN = "ROLE_WEB_BANK_ADMIN";
const ROLE_WEB_CUSTOMER_ADMIN = "ROLE_WEB_CUSTOMER_ADMIN";
const ROLE_WEB_CUSTOMER = "ROLE_WEB_CUSTOMER";

const ROLE_SECURITY_ADMIN = "ROLE_SECURITY_ADMIN";
const ROLE_SECURITY_ORDERS = "ROLE_SECURITY_ORDERS";
const ROLE_SECURITY_TASKS = "ROLE_SECURITY_TASKS";
const ROLE_SECURITY_USERS = "ROLE_SECURITY_USERS";
const ROLE_SECURITY_USERS_COMPANY = "ROLE_SECURITY_USERS_COMPANY";
const ROLE_SECURITY_OBJECTS = "ROLE_SECURITY_OBJECTS";
const ROLE_SECURITY_OBJECTS_COMPANY = "ROLE_SECURITY_OBJECTS_COMPANY";
const ROLE_SECURITY_CLIENT_ORDERS = "ROLE_SECURITY_CLIENT_ORDERS";
const ROLE_SECURITY_CLIENT_ORDERS_VIEW = "ROLE_SECURITY_CLIENT_ORDERS_VIEW";
const ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC =
  "ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC";
const ROLE_SECURITY_CLIENT_ORDERS_MONITOR =
  "ROLE_SECURITY_CLIENT_ORDERS_MONITOR";
const ROLE_SECURITY_CLIENT_ORDERS_PROCESS =
  "ROLE_SECURITY_CLIENT_ORDERS_PROCESS";
const ROLE_SECURITY_GUARD = "ROLE_SECURITY_GUARD";
const ROLE_SECURITY_CASH_CENTER = "ROLE_SECURITY_CASH_CENTER";
const ROLE_SECURITY_SPECIFICATIONS = "ROLE_SECURITY_SPECIFICATIONS";
const ROLE_SECURITY_CLIENT_SPECIFICATIONS = "ROLE_SECURITY_CLIENT_SPECIFICATIONS";
const ROLE_WEB_USER_OTP = "ROLE_USER_OTP";

const SC_ROLES = {
  ROLE_SC_ADMIN,
  ROLE_WEB_BANK_ADMIN,
  ROLE_WEB_CUSTOMER_ADMIN,
  ROLE_WEB_CUSTOMER,
};

const MEDITERAN_SECURITY_ROLES = {
  ROLE_SECURITY_ADMIN,
  ROLE_SECURITY_USERS,
  ROLE_SECURITY_USERS_COMPANY,
  ROLE_SECURITY_OBJECTS,
  ROLE_SECURITY_OBJECTS_COMPANY,
  ROLE_SECURITY_ORDERS,
  ROLE_SECURITY_SPECIFICATIONS,
  ROLE_SECURITY_TASKS,
  ROLE_SECURITY_CLIENT_SPECIFICATIONS,
  ROLE_SECURITY_CLIENT_ORDERS,
  ROLE_SECURITY_CLIENT_ORDERS_VIEW,
  ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC,
  ROLE_SECURITY_CLIENT_ORDERS_MONITOR,
  ROLE_SECURITY_CLIENT_ORDERS_PROCESS,
  ROLE_SECURITY_GUARD,
  ROLE_SECURITY_CASH_CENTER,
};

export {
  ROLE_WEB_ADMIN,
  ROLE_SECURITY_ADMIN,
  ROLE_SECURITY_USERS,
  ROLE_SECURITY_USERS_COMPANY,
  ROLE_SECURITY_OBJECTS,
  ROLE_SECURITY_OBJECTS_COMPANY,
  ROLE_SECURITY_TASKS,
  ROLE_SECURITY_SPECIFICATIONS,
  ROLE_SECURITY_ORDERS,
  ROLE_SECURITY_CLIENT_SPECIFICATIONS,
  ROLE_SECURITY_CLIENT_ORDERS,
  ROLE_SECURITY_CLIENT_ORDERS_VIEW,
  ROLE_SECURITY_CLIENT_ORDERS_VIEW_CC,
  ROLE_SECURITY_CLIENT_ORDERS_MONITOR,
  ROLE_SECURITY_CLIENT_ORDERS_PROCESS,
  SC_ROLES,
  MEDITERAN_SECURITY_ROLES,
};
