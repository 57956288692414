import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import DropDownComponent from "components/FormComponents/DropDownComponent";
import EnumeratorComponent from "components/FormComponents/EnumeratorComponent";
import CustomSpinner from "components/Spinner/CustomSpinner";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-widgets/dist/css/react-widgets.css";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getAutocompleteURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import { MEDITERAN_SECURITY_ROLES } from "util/roles/roleList";
import * as Yup from "yup";
import DatePicker from "../FormComponents/DatePicker";
import TextInput from "../FormComponents/TextInput";
import CustomProgress from "../Progress/CustomProgress";

const COIN_TYPE_CODE = 1;
const BILL_TYPE_CODE = 0;

const MAIN_COMPANY_TYPE = 0;
const BRANCH_COMPANY_TYPE = 1;

const ORDER_TYPE_CODE = 3;

const NORMAL_QUALITY_CODE = 1;

const FROM_VAULT = 1;

const TABLE_CODE = "order.denom.table";

const CURRENCY_LOCALE = ["hr-HR", { minimumFractionDigits: 2 }];

const backPath = "/app/clientOrder";
const currencyFilter =
  '/autocomplete?columns=id&columns=code&columns=name&filter={"search":{"type":"string","key":"name","value":';

const companyEmployerFilter =
  '/autocomplete?columns=id&columns=user.fullName&&filter={"search":{"type":"string","key":"user.fullName","value":';

const cashCenterFilter =
  '/autocomplete?columns=id&columns=name&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';

const SpecificationSchema = Yup.object().shape({
  currency: Yup.object().required("Odaberite valutu!"),
  company: Yup.object().required("Odaberite tvrtku!"),
});

const getTotal = (bills, coins) => {
  let totalAmount = 0;
  let totalCoins = 0;
  let totalBills = 0;
  if (bills != null || coins != null) {
    if (bills != null) {
      totalBills = bills.reduce((acc, value) => {
        return (
          acc +
          value.currencyDenomination.value * (value.pieces ? value.pieces : 0)
        );
      }, 0);
    }
    if (coins != null) {
      totalCoins = coins.reduce((acc, value) => {
        return (
          acc +
          value.currencyDenomination.value * (value.pieces ? value.pieces : 0)
        );
      }, 0);
    }
    totalAmount = totalCoins + totalBills;
  }

  return totalAmount;
};

class ClientOrderEdit extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    specificationId: null,
    specificationData: null,
    currencyCode: null,
    loading: true,
    coins: [],
    bills: [],
    isLocked: true,
    countdown: null,
    intervalId: null,
    prevDiff: 0,
    denomErrors: {},
    dateError: null,
    showProcessModal: false,
    showCancelModal: false,
    createNew: false,
    oldSpecification: null,
    canProcess: false,
    workDay: undefined,
    subjectRequired: false,
    showCutoffModal: false,
  };

  onKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  getBackPath = () => {
    const { userInfo } = this.props;

    let returnPath = backPath;
    if (userInfo && userInfo.companyEmployer && userInfo.companyEmployer.user) {
      const userRoles = userInfo.companyEmployer.user.authorities.map(
        (auth) => auth.authority
      );
      const adminRoles = [
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ORDERS,
      ];
      const matchingRoles = userRoles.filter((userRole) =>
        adminRoles.includes(userRole)
      );
      if (matchingRoles.length > 0) {
        returnPath = "/app/order";
      }
    }
    return returnPath;
  };

  getDenominations = (currencyCode, specificationItems) => {
    const { match } = this.props;
    const { id } = match.params;

    this.setState({ currencyCode: currencyCode });
    const columns = "columns=id&columns=value&columns=denominationType";
    let additionalParams = "&allowedOnly=true";
    if (id) {
      additionalParams += `&forSpecification=${id}`;
    }
    const filters = {
      currency: { type: "string", key: "currency.code", value: currencyCode },
    };
    const url = getAutocompleteURL(
      "currencyDenomination",
      columns + additionalParams,
      filters
    );
    return fetch(`api/${encodeURI(url)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({
              currencyDenominations: data,
            });
            let coins = [],
              bills = [];
            data.forEach((denom) => {
              const item = {
                currencyDenomination: denom,
                quality: NORMAL_QUALITY_CODE,
              };
              if (denom.denominationType == COIN_TYPE_CODE) {
                coins.push(item);
              } else if (denom.denominationType == BILL_TYPE_CODE) {
                bills.push(item);
              }
            });

            if (specificationItems && specificationItems.length > 0) {
              bills = this.getUpdateDenoms(bills, specificationItems);
              coins = this.getUpdateDenoms(coins, specificationItems);
            }

            this.setState({ coins: coins });
            this.setState({ bills: bills });
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
        }
      })
      .catch((e) => {
        console.error("Error while getting denominations", e);
        showErrorAlert(getTranslation("api.error.fetching"));
      });
  };

  componentDidMount() {
    const { match, userInfo } = this.props;
    const { id } = match.params;

    if (id && id !== "create") {
      this.setState({ specificationId: id }, this.getSpecification(id));
      if (
        userInfo &&
        userInfo.companyEmployer &&
        userInfo.companyEmployer.user
      ) {
        const userRoles = userInfo.companyEmployer.user.authorities.map(
          (auth) => auth.authority
        );
        const adminRoles = [
          MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
          MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ORDERS,
          MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_CLIENT_ORDERS_PROCESS,
        ];
        const matchingRoles = userRoles.filter((userRole) =>
          adminRoles.includes(userRole)
        );
        if (matchingRoles.length > 0) {
          this.setState({ canProcess: true });
        }
      }
    } else {
      this.setState({ loading: false, isLocked: false });
      if (
        userInfo &&
        userInfo.companyEmployer &&
        userInfo.companyEmployer.defaultCurrency &&
        userInfo.companyEmployer.defaultCurrency.code
      ) {
        this.getDenominations(userInfo.companyEmployer.defaultCurrency.code);
      }

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (
        userInfo &&
        userInfo.companyEmployer &&
        userInfo.companyEmployer.company
      ) {
        if (userInfo.companyEmployer.company.type == MAIN_COMPANY_TYPE) {
          this.setState({ subjectRequired: true });
        }
        const { workingSaturday, workingSunday } =
          userInfo.companyEmployer.company;
        if (!workingSaturday && moment(tomorrow).isoWeekday() === 6) {
          tomorrow.setDate(tomorrow.getDate() + 1);
        }
        if (!workingSunday && moment(tomorrow).isoWeekday() === 7) {
          tomorrow.setDate(tomorrow.getDate() + 1);
        }
      }
      this.checkIfWorkDay(moment(tomorrow));
    }
  }

  getUpdateDenoms = (denoms, items) => {
    let newDenoms = [...denoms];
    if (items && items.length > 0) {
      items.forEach((item) => {
        newDenoms.map((denom, index) => {
          if (
            denom.currencyDenomination.id == item.currencyDenomination.id &&
            denom.quality == item.quality
          ) {
            newDenoms[index] = item;
            newDenoms[index].currencyDenomination.stackNum =
              denom.currencyDenomination.stackNum;
          }
        });
      });
    }
    return newDenoms;
  };

  getProcessedDenoms = (bills, coins) => {
    let denoms = [...bills, ...coins];
    return denoms.filter((denom) => {
      if (denom.pieces && denom.pieces > 0) {
        return true;
      }
      return false;
    });
  };

  getSpecification = (id) => {
    const { showErrorAlert, userInfo } = this.props;
    this.setState({ loading: true });
    fetch(`api/specification/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.getDenominations(
              formData.currency.code,
              formData.specificationItems
            );
            this.setState({
              specificationData: {
                ...formData,
                deliveryDate: new Date(formData.deliveryDate),
                creationDate: new Date(formData.creationDate),
              },
              loading: false,
            });
            if (formData.lockedDate) {
              this.getRemainingTime(formData.lockedDate);
            }
            if (
              (userInfo &&
                userInfo.companyEmployer &&
                userInfo.companyEmployer.user) ||
              (formData.companyBranch && formData.companyBranch.id)
            ) {
              this.setState({ subjectRequired: true });
            }
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting order", e);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  };

  checkIfWorkDay = (day) => {
    fetch(`api/holiday/isWorkDay?date=${day.format("YYYY-MM-DD")}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({ workDay: data });
            if (!data.isWorkDay) {
              this.setState({ dateError: "workDay" });
            }
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
        }
      })
      .catch((error) => {
        console.error("Error while checking if work day: ", error);
        showErrorAlert(getTranslation("api.error.fetching"));
      });
  };

  submitForm = (values) => {
    const { specificationId, coins, bills, oldSpecification } = this.state;
    let postData = {
      ...values,
      specificationType: ORDER_TYPE_CODE,
      amount: getTotal(coins, bills),
      specificationItems: this.getProcessedDenoms(coins, bills),
    };
    if (oldSpecification) {
      postData.id = undefined;
      postData.createdFrom = { id: oldSpecification };
      postData.creationDate = new Date();
    }
    if (specificationId) {
      const url = `api/specification/${specificationId}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(postData),
      });
    }
    const url = "api/specification";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(postData),
    });
  };

  generateClientAutocompleteURL = (type, parent, cashCenter, key) => {
    const columns = "columns=id&columns=name";
    const filters = {
      active: {
        type: "boolean",
        key: "active",
        value: "true",
      },
      type: {
        type: "integer",
        key: "type",
        value: type,
      },
    };

    if (parent) {
      filters.parent = {
        type: "long",
        key: "parent.id",
        value: parent.id,
      };
    }

    if (cashCenter) {
      filters.cashCenter = {
        type: "long",
        key: "cashCenter.id",
        value: cashCenter.id,
      };
    }

    var url = getAutocompleteURL("", columns, filters);
    if (key) {
      url =
        url.slice(0, -1) +
        ', "search":{"type":"string","key":"' +
        key +
        '","value":';
    }
    return url;
  };

  generateClientOrderSubjectAutocompleteURL = () => {
    const columns = "showUserCompaniesOnly=true&columns=id&columns=name";
    const filters = {
      active: {
        type: "boolean",
        key: "active",
        value: "true",
      },
    };

    var url = getAutocompleteURL("", columns, filters);
    url =
      url.slice(0, -1) + ', "search":{"type":"string","key":"name","value":';
    return url;
  };

  resetRemainingTimes = () => {
    let intervalId = this.state.intervalId;
    clearInterval(intervalId);
  };

  millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  getRemainingTime = (date) => {
    let intervalId = this.state.intervalId;
    if (!intervalId) {
      intervalId = setInterval(() => {
        let countdown = this.state.countdown;
        let diff = moment(date).diff(moment());
        if (diff > 0) {
          this.setState({ isLocked: false });
          let prevDiff = this.state.prevDiff;
          if (prevDiff - diff > 2000) {
            clearInterval(intervalId);
          }
          countdown = this.millisToMinutesAndSeconds(diff);
          if (countdown === this.state.countdown) {
            clearInterval(intervalId);
          }
        } else {
          countdown = undefined;
          this.setState({ isLocked: true });
          clearInterval(intervalId);
        }
        this.setState({ countdown: countdown, prevDiff: diff });
      }, 1000);
      this.setState({ intervalId: intervalId });
    }
  };

  processOrder = () => {
    const { history } = this.props;
    this.setState({ isSubmitting: true });
    fetch(
      `api/specification/processOrder?orderId=${this.state.specificationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          history.push(this.getBackPath());
        }
        this.setState({ isSubmitting: false });
      })
      .catch((e) => {
        console.error("Error while processing order", e);
        this.setState({ isSubmitting: false });
      });
  };

  cancelOrder = () => {
    const { history } = this.props;
    this.setState({ isSubmitting: true });
    fetch(
      `api/specification/cancelOrder?orderId=${this.state.specificationId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          history.push(this.getBackPath());
        }
        this.setState({ isSubmitting: false });
      })
      .catch((e) => {
        console.error("Error while canceling order", e);
        this.setState({ isSubmitting: false });
      });
  };

  cancelAndCreateOrder = () => {
    let coins = this.state.coins;
    if (coins) {
      coins.map((coin) => {
        coin.id = undefined;
        return coin;
      });
      this.setState({ coins: coins });
    }

    let bills = this.state.bills;
    if (bills) {
      bills.map((bill) => {
        bill.id = undefined;
        return bill;
      });
      this.setState({ bills: bills });
    }

    this.setState({
      isLocked: false,
      isProcessed: false,
      oldSpecification: this.state.specificationId,
      specificationId: null,
    });
  };

  render() {
    const {
      specificationData,
      loading,
      currencyDenominations,
      bills,
      coins,
      currencyCode,
      isLocked,
      countdown,
      dateError,
      canProcess,
      workDay,
    } = this.state;

    if (loading) {
      return <CustomSpinner />;
    }

    const { userInfo, history, showErrorAlert } = this.props;

    let returnPath = backPath;
    if (userInfo && userInfo.companyEmployer && userInfo.companyEmployer.user) {
      const userRoles = userInfo.companyEmployer.user.authorities.map(
        (auth) => auth.authority
      );
      const adminRoles = [
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
        MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ORDERS,
      ];
      const matchingRoles = userRoles.filter((userRole) =>
        adminRoles.includes(userRole)
      );
      if (matchingRoles.length > 0) {
        returnPath = "/app/order";
      }
    }

    let defaultCurrency = undefined,
      defaultClient = undefined,
      defaultCashCenter = undefined;
    if (userInfo.companyEmployer) {
      if (
        userInfo.companyEmployer.defaultCashCenter &&
        userInfo.companyEmployer.defaultCashCenter.id
      ) {
        defaultCashCenter = userInfo.companyEmployer.defaultCashCenter;
      }
      if (
        userInfo.companyEmployer.defaultClient &&
        userInfo.companyEmployer.defaultClient.id
      ) {
        defaultClient = userInfo.companyEmployer.defaultClient;
      }
      if (
        userInfo.companyEmployer.defaultCurrency &&
        userInfo.companyEmployer.defaultCurrency.id
      ) {
        defaultCurrency = userInfo.companyEmployer.defaultCurrency;
      }
    }

    let initialData = null;
    if (specificationData) {
      initialData = {
        ...specificationData,
        company: specificationData.company,
        cashCenter: specificationData.company.cashCenter,
      };
    } else {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (
        userInfo &&
        userInfo.companyEmployer &&
        userInfo.companyEmployer.company
      ) {
        const { workingSaturday, workingSunday } =
          userInfo.companyEmployer.company;
        if (!workingSaturday && moment(tomorrow).isoWeekday() === 6) {
          tomorrow.setDate(tomorrow.getDate() + 1);
        }
        if (!workingSunday && moment(tomorrow).isoWeekday() === 7) {
          tomorrow.setDate(tomorrow.getDate() + 1);
        }
      }

      initialData = {
        creationEmployer: userInfo.companyEmployer,
        deliveryDate: tomorrow,
        orderRoute: FROM_VAULT,
        quality: NORMAL_QUALITY_CODE,
        currency: defaultCurrency,
        company: defaultClient,
        cashCenter: defaultCashCenter,
      };
    }

    const isProcessed = initialData.processed;

    const billChange = (value, denom) => {
      let bills = this.state.bills;
      bills.map((bill) => {
        if (bill.currencyDenomination.id == denom.id) {
          let denomErrors = this.state.denomErrors;
          if (value && !isNaN(value) && Number(value) < 0) {
            bill.pieces = 0;
            denomErrors[denom.id] = "gtZero";
          } else if (
            value &&
            !isNaN(value) &&
            denom.stackNum &&
            Number(value) % denom.stackNum !== 0
          ) {
            bill.pieces = 0;
            denomErrors[denom.id] = "stackNum";
          } else {
            bill.pieces = value;
            delete denomErrors[denom.id];
          }
          this.setState({ denomErrors: denomErrors });
        }
        return bill;
      });
      this.setState({ bills: bills });
    };

    const coinChange = (value, denom) => {
      let coins = this.state.coins;
      coins.map((coin) => {
        if (coin.currencyDenomination.id == denom.id) {
          let denomErrors = this.state.denomErrors;
          if (value && !isNaN(value) && Number(value) < 0) {
            coin.pieces = 0;
            denomErrors[denom.id] = "gtZero";
          } else if (
            value &&
            !isNaN(value) &&
            denom.stackNum &&
            Number(value) % denom.stackNum !== 0
          ) {
            coin.pieces = 0;
            denomErrors[denom.id] = "stackNum";
          } else {
            coin.pieces = value;
            delete denomErrors[denom.id];
          }
          this.setState({ denomErrors: denomErrors });
        }
        return coin;
      });
      this.setState({ coins: coins });
    };

    const onDeliveryDateSelect = (value) => {
      this.setState({ dateError: null, workDay: undefined });
      var needToCheck = true;
      if (
        userInfo &&
        userInfo.companyEmployer &&
        userInfo.companyEmployer.company
      ) {
        const { workingSaturday, workingSunday } =
          userInfo.companyEmployer.company;
        if (moment(value).isBefore(moment().startOf("day"))) {
          this.setState({ dateError: "before" });
          needToCheck = false;
        } else if (
          (!workingSaturday && moment(value).isoWeekday() === 6) ||
          (!workingSunday && moment(value).isoWeekday() === 7)
        ) {
          this.setState({ dateError: "workDay" });
          needToCheck = false;
        }
      }
      if (needToCheck) {
        this.checkIfWorkDay(moment(value));
      }
    };

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => {
            this.resetRemainingTimes();
            history.push(this.getBackPath());
          }}
        >
          <span className="cil-arrow-left" />
        </Button>
        <Formik
          initialValues={initialData}
          validationSchema={SpecificationSchema}
          onSubmit={(values, actions) => {
            this.submitForm(values).then((r) => {
              if (r.status === 200) {
                history.push(this.getBackPath());
              } else {
                try {
                  r.json().then((data) => {
                    if (
                      data &&
                      data.messageList &&
                      data.messageList.length > 0
                    ) {
                      for (var i = 0; i < data.messageList.length; i++) {
                        if (
                          data.messageList[i].message &&
                          data.messageList[i].message.includes("cutoff.time")
                        ) {
                          this.setState({ showCutoffModal: true });
                          break;
                        }
                      }
                    }
                  });
                } catch (error) {}
                try {
                  showErrorAlert(getTranslation("api.error.saving"));
                } catch (error) {
                  console.log(error);
                }
              }
              actions.setSubmitting(false);
            });
          }}
          render={({
            errors,
            status,
            touched,
            isSubmitting,
            validateForm,
            setFieldValue,
            values,
          }) => {
            const { denomErrors, specificationId } = this.state;
            const handleCurrencyChange = (suggestion) => {
              setFieldValue("currency", suggestion);
              this.getDenominations(suggestion.code);
            };
            return (
              <React.Fragment>
                <Modal isOpen={this.state.showCutoffModal} onExit={() => {}}>
                  <ModalHeader
                    toggle={() => this.setState({ showCutoffModal: false })}
                  >
                    {getTranslation("dialog.warning")}
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col>{getTranslation("order.cutoff.message")}</Col>
                    </Row>

                    <Button
                      outline
                      className="margin--bottom"
                      color="primary"
                      onClick={() => this.setState({ showCutoffModal: false })}
                      style={{ float: "right" }}
                    >
                      {getTranslation("table.button.ok")}
                    </Button>
                  </ModalBody>
                </Modal>
                <Form onKeyDown={this.onKeyDown}>
                  {isSubmitting && <CustomProgress />}
                  <Row>
                    <Col xs="12">
                      <Row>
                        <Col md="4" style={{ display: "none" }}>
                          <Field
                            disabled
                            labeltext="order.create.cashCenter"
                            name="cashCenter"
                            onselected={(suggestion) =>
                              setFieldValue("cashCenter", suggestion)
                            }
                            tabledatabasename="cashCenter"
                            filter={cashCenterFilter}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="4" style={{ display: "none" }}>
                          <Field
                            required
                            disabled={isSubmitting || isProcessed || isLocked}
                            labeltext="order.create.route"
                            enumname="OrderRoute"
                            onselected={(value) =>
                              setFieldValue("orderRoute", value)
                            }
                            name="orderRoute"
                            component={EnumeratorComponent}
                          />
                        </Col>
                        <Col md="4">
                          <Field
                            required
                            disabled
                            labeltext="specification.create.employee"
                            name="creationEmployer"
                            onselected={(suggestion) =>
                              setFieldValue("creationEmployer", suggestion)
                            }
                            tabledatabasename="companyEmployer"
                            filter={companyEmployerFilter}
                            showcolumn="user.fullName"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col
                          md="4"
                          style={
                            !this.state.subjectRequired
                              ? { display: "none" }
                              : {}
                          }
                        >
                          <Field
                            disabled={isSubmitting || isProcessed || isLocked}
                            labeltext="order.create.client.subject"
                            name="companyBranch"
                            onselected={(suggestion) =>
                              setFieldValue("companyBranch", suggestion)
                            }
                            tabledatabasename="company"
                            filter={this.generateClientOrderSubjectAutocompleteURL()}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="4">
                          <Field
                            required
                            disabled={isSubmitting || isProcessed || isLocked}
                            labeltext="order.create.client"
                            name="company"
                            onselected={(suggestion) =>
                              setFieldValue("company", suggestion)
                            }
                            tabledatabasename="company"
                            filter={this.generateClientAutocompleteURL(
                              MAIN_COMPANY_TYPE,
                              null,
                              values.cashCenter,
                              "name"
                            )}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="4" style={{ display: "none" }}>
                          <Field
                            required
                            disabled={isSubmitting || isProcessed || isLocked}
                            labeltext="order.create.creationDate"
                            name="creationDate"
                            component={DatePicker}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col md="4">
                          <Field
                            min={moment().startOf("day").toDate()}
                            required
                            disabled={isSubmitting || isProcessed || isLocked}
                            labeltext="order.create.deliveryDate"
                            name="deliveryDate"
                            component={DatePicker}
                            setFieldValue={setFieldValue}
                            onSelect={(value) => onDeliveryDateSelect(value)}
                            className={dateError ? "isInvalid" : ""}
                          />
                          {dateError && dateError === "before" && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {getTranslation(
                                "order.create.date.validation.before"
                              )}
                            </div>
                          )}
                          {dateError && dateError === "workDay" && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {getTranslation(
                                "order.create.date.validation.workDay"
                              )}
                              {workDay &&
                                !workDay.isWorkDay &&
                                `: ${workDay.info}`}
                            </div>
                          )}
                        </Col>
                        <Col md="4">
                          <Field
                            required
                            disabled={isProcessed || isSubmitting || isLocked}
                            labeltext="specification.create.currency"
                            name="currency"
                            onselected={(suggestion) =>
                              handleCurrencyChange(suggestion)
                            }
                            tabledatabasename="currency"
                            filter={currencyFilter}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="8">
                          <Field
                            type="textarea"
                            disabled={isProcessed || isSubmitting || isLocked}
                            labeltext="specification.create.remark"
                            name="remark"
                            component={TextInput}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {bills && bills.length > 0 && (
                      <Col>
                        <hr
                          style={{ border: "1px solid #c8ced3", width: "100%" }}
                        />
                        <h5>
                          {getTranslation("specification.bills.table.header")} (
                          {new Intl.NumberFormat(...CURRENCY_LOCALE).format(
                            getTotal(bills, [])
                          )}{" "}
                          {currencyCode})
                        </h5>
                        <Table striped responsive hover size="sm" bordered>
                          <thead>
                            <tr>
                              <th key={"specification.bills.table.row.amount"}>
                                {getTranslation(
                                  "specification.bills.table.row.amount"
                                )}
                              </th>
                              <th key={"specification.bills.table.row.pieces"}>
                                {getTranslation(
                                  "specification.bills.table.row.pieces"
                                )}
                              </th>
                              <th key={"specifications.table.total.sum"}>
                                {getTranslation(
                                  "specifications.table.total.sum"
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {bills.map((bill) => {
                              return (
                                <tr>
                                  <td>
                                    {new Intl.NumberFormat(
                                      ...CURRENCY_LOCALE
                                    ).format(bill.currencyDenomination.value)}
                                  </td>
                                  <td>
                                    <div>
                                      <Input
                                        min="0"
                                        disabled={
                                          isSubmitting ||
                                          isProcessed ||
                                          isLocked
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === ".") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) => {
                                          billChange(
                                            e.target.value,
                                            bill.currencyDenomination
                                          );
                                        }}
                                        defaultValue={bill.pieces}
                                        type={"number"}
                                        className={
                                          denomErrors[
                                            bill.currencyDenomination.id
                                          ]
                                            ? "isInvalid"
                                            : ""
                                        }
                                      />
                                      {denomErrors[
                                        bill.currencyDenomination.id
                                      ] &&
                                        denomErrors[
                                          bill.currencyDenomination.id
                                        ] === "gtZero" && (
                                          <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                          >
                                            {getTranslation(
                                              "currency.denomination.create.validation.quantityEqual"
                                            )}{" "}
                                            0
                                          </div>
                                        )}
                                      {denomErrors[
                                        bill.currencyDenomination.id
                                      ] &&
                                        denomErrors[
                                          bill.currencyDenomination.id
                                        ] === "stackNum" && (
                                          <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                          >
                                            {getTranslation(
                                              "currency.denomination.create.validation.multiplier"
                                            )}
                                            {` {${
                                              bill.currencyDenomination.stackNum
                                            }, ${
                                              2 *
                                              bill.currencyDenomination.stackNum
                                            }, ${
                                              3 *
                                              bill.currencyDenomination.stackNum
                                            }, ...}`}
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat(
                                      ...CURRENCY_LOCALE
                                    ).format(
                                      bill.currencyDenomination.value *
                                        (bill.pieces ? bill.pieces : 0)
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                    {coins && coins.length > 0 && (
                      <Col>
                        <hr
                          style={{ border: "1px solid #c8ced3", width: "100%" }}
                        />
                        <h5>
                          {getTranslation("specification.coins.table.header")} (
                          {new Intl.NumberFormat(...CURRENCY_LOCALE).format(
                            getTotal([], coins)
                          )}{" "}
                          {currencyCode})
                        </h5>
                        <Table striped responsive hover size="sm" bordered>
                          <thead>
                            <tr>
                              <th key={"specification.bills.table.row.amount"}>
                                {getTranslation(
                                  "specification.bills.table.row.amount"
                                )}
                              </th>
                              <th key={"specification.bills.table.row.pieces"}>
                                {getTranslation(
                                  "specification.bills.table.row.pieces"
                                )}
                              </th>
                              <th key={"specifications.table.total.sum"}>
                                {getTranslation(
                                  "specifications.table.total.sum"
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {coins.map((coin) => {
                              return (
                                <tr>
                                  <td>
                                    {new Intl.NumberFormat(
                                      ...CURRENCY_LOCALE
                                    ).format(coin.currencyDenomination.value)}
                                  </td>
                                  <td>
                                    <div>
                                      <Input
                                        min="0"
                                        disabled={
                                          isSubmitting ||
                                          isProcessed ||
                                          isLocked
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === ".") {
                                            e.preventDefault();
                                          }
                                        }}
                                        onChange={(e) =>
                                          coinChange(
                                            e.target.value,
                                            coin.currencyDenomination
                                          )
                                        }
                                        defaultValue={coin.pieces}
                                        type={"number"}
                                      />
                                      {denomErrors[
                                        coin.currencyDenomination.id
                                      ] &&
                                        denomErrors[
                                          coin.currencyDenomination.id
                                        ] === "gtZero" && (
                                          <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                          >
                                            {getTranslation(
                                              "currency.denomination.create.validation.quantityEqual"
                                            )}{" "}
                                            0
                                          </div>
                                        )}
                                      {denomErrors[
                                        coin.currencyDenomination.id
                                      ] &&
                                        denomErrors[
                                          coin.currencyDenomination.id
                                        ] === "stackNum" && (
                                          <div
                                            className="invalid-feedback"
                                            style={{ display: "block" }}
                                          >
                                            {getTranslation(
                                              "currency.denomination.create.validation.multiplier"
                                            )}
                                            {` {${
                                              coin.currencyDenomination.stackNum
                                            }, ${
                                              2 *
                                              coin.currencyDenomination.stackNum
                                            }, ${
                                              3 *
                                              coin.currencyDenomination.stackNum
                                            }, ...}`}
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat(
                                      ...CURRENCY_LOCALE
                                    ).format(
                                      coin.currencyDenomination.value *
                                        (coin.pieces ? coin.pieces : 0)
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <h5>
                        {getTranslation("vaultReport.table.footer.sum")}
                        {new Intl.NumberFormat(...CURRENCY_LOCALE).format(
                          getTotal(bills, coins)
                        )}{" "}
                        {currencyCode}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {!isProcessed && !isLocked && (
                        <Button
                          disabled={
                            isSubmitting ||
                            getTotal(bills, coins) <= 0 ||
                            Object.keys(denomErrors).length > 0 ||
                            dateError
                          }
                          type="submit"
                          color="primary"
                        >
                          {getTranslation("table.button.execute")}
                        </Button>
                      )}
                      {specificationId && !isProcessed && canProcess && (
                        <>
                          <Button
                            onClick={() =>
                              this.setState({
                                showProcessModal: true,
                              })
                            }
                            disabled={isSubmitting || !isLocked}
                            color="primary"
                          >
                            {getTranslation("table.button.confirm")}
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({
                                showCancelModal: true,
                                createNew: false,
                              })
                            }
                            disabled={isSubmitting || !isLocked}
                            color="primary"
                          >
                            {getTranslation("table.button.cancelNotCreate")}
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({
                                showCancelModal: true,
                                createNew: true,
                              })
                            }
                            disabled={isSubmitting || !isLocked}
                            color="primary"
                          >
                            {getTranslation("table.button.cancelAndCreate")}
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Modal isOpen={this.state.showProcessModal} onExit={() => {}}>
                    <ModalHeader
                      toggle={() =>
                        this.setState({
                          showProcessModal: false,
                        })
                      }
                    >
                      {getTranslation("dialog.warning")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col>{getTranslation("order.process.message")}</Col>
                      </Row>

                      <Row>
                        <div style={{ width: "100%" }}>
                          <Button
                            outline
                            color="primary"
                            onClick={() => {
                              this.setState({ showProcessModal: false });
                              this.processOrder();
                            }}
                            style={{ float: "right" }}
                          >
                            {getTranslation("table.button.yes")}
                          </Button>
                          <Button
                            outline
                            color="primary"
                            onClick={() =>
                              this.setState({
                                showProcessModal: false,
                              })
                            }
                            style={{ float: "right" }}
                          >
                            {getTranslation("table.button.no")}
                          </Button>
                        </div>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={this.state.showCancelModal} onExit={() => {}}>
                    <ModalHeader
                      toggle={() =>
                        this.setState({
                          showCancelModal: false,
                          createNew: false,
                        })
                      }
                    >
                      {getTranslation("dialog.warning")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        {!this.state.createNew && (
                          <Col>{getTranslation("order.cancel.message")}</Col>
                        )}
                        {this.state.createNew && (
                          <Col>
                            {getTranslation("order.cancelAndCreate.message")}
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <div style={{ width: "100%" }}>
                          <Button
                            outline
                            color="primary"
                            onClick={() => {
                              this.setState({
                                showCancelModal: false,
                              });
                              if (this.state.createNew) {
                                this.cancelAndCreateOrder();
                              } else {
                                this.cancelOrder();
                              }
                            }}
                            style={{ float: "right" }}
                          >
                            {getTranslation("table.button.yes")}
                          </Button>
                          <Button
                            outline
                            color="primary"
                            onClick={() =>
                              this.setState({
                                showCancelModal: false,
                                createNew: false,
                              })
                            }
                            style={{ float: "right" }}
                          >
                            {getTranslation("table.button.no")}
                          </Button>
                        </div>
                      </Row>
                    </ModalBody>
                  </Modal>
                </Form>
              </React.Fragment>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

export default withRouter(connect(mapStateToProps)(ClientOrderEdit));
