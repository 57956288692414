import Login from 'components/Login/Login';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import getApplicationVersionFromServer from 'util/api/appInfo';
import * as CONST from 'util/constants';
import { getLocalisationFromServer, setLocalisation } from 'util/localisation';
import './App.scss';
import Layout from './Layout';

class App extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    getApplicationVersionFromServer()
      .then(response => {
        if (response.appVersion) {
          CONST.setVersion(response.appVersion);
        }
        if (
          localStorage.getItem('localisation') === null ||
          localStorage.getItem('version') !== CONST.getVersion()
        ) {
          console.log('no localisation');
          getLocalisationFromServer()
            .then(localisation => {
              setLocalisation(localisation);
              this.setState({ loading: false });
            })
            .catch(e => {
              console.error(`Error getting localization from server: ${e}`);
            });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(e => {
        console.error(`Error getting app version: ${e}`);
      });
  }

  render() {
    const { loading } = this.state;

    console.log('loading', loading);

    return (
      <React.Fragment>
        {!loading && (
          <HashRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/" component={Layout} />
            </Switch>
          </HashRouter>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.userReducer.userState,
});

export default connect(mapStateToProps)(App);
