import { API_ENDPOINT } from './apiConstants';
import { checkResponseStatus } from './Handlers';

export const GetApiCall = path => {
  return fetch(`${API_ENDPOINT + path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then(checkResponseStatus);
};

export const GetApiCallFilters = (path, params) => {
  return fetch(`${API_ENDPOINT + path + params}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${localStorage.auth}`,
    },
  }).then(checkResponseStatus);
};

export const GetApiCallFiltersBearer = (path, params = '') => {
  const encodedURI = encodeURI(path + params);
  return fetch(`${API_ENDPOINT + encodedURI}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${localStorage.auth}`,
    },
  }).then(checkResponseStatus);
};

export const GetApiCallFiltersBearer2 = path => {
  const encodedURI = encodeURI(path);
  return fetch(`${API_ENDPOINT + encodedURI}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${localStorage.auth}`,
    },
  });
};

export const GetApiCallParams = Params => {
  return fetch(`${API_ENDPOINT + Params.path}?${Params.params}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then(checkResponseStatus);
};

export const PostApiCall = Params => {
  return fetch(`${API_ENDPOINT + Params.path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${localStorage.auth}`,
    },
    body: Params.bodyContent,
  }).then(checkResponseStatus);
};
