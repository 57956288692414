import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    const { history } = this.props;

    history.listen(() => {
      const { hasError } = this.state;
      if (hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true, errorMessage: error };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.warn(info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <span>Došlo je do greške</span>
        </div>
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
