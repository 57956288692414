import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import DropDownComponent from "components/FormComponents/DropDownComponent";
import EnumeratorComponent from "components/FormComponents/EnumeratorComponent";
import CustomSpinner from "components/Spinner/CustomSpinner";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-widgets/dist/css/react-widgets.css";
import { Col, Button, Row } from "reactstrap";
import { getAutocompleteURL } from "util/api/callGenerator";
import { getTranslation } from "util/localisation";
import * as Yup from "yup";
import DatePicker from "../FormComponents/DatePicker";
import MultiSelect from "../FormComponents/MultiSelect";
import TextInput from "../FormComponents/TextInput";
import CustomTable from "../Table/CustomTable";
import CustomProgress from "../Progress/CustomProgress";

const COIN_TYPE_CODE = 1;
const BILL_TYPE_CODE = 0;

const MAIN_COMPANY_TYPE = 0;
const BRANCH_COMPANY_TYPE = 1;

const ORDER_TYPE_CODE = 1;

const NORMAL_QUALITY_CODE = 1;

const FROM_VAULT = 1;

const TABLE_CODE = "order.denom.table";

const CURRENCY_LOCALE = ["hr-HR", { minimumFractionDigits: 2 }];

const backPath = "/app/order";
const currencyFilter =
  '/autocomplete?columns=id&columns=code&columns=name&filter={"search":{"type":"string","key":"name","value":';

const companyEmployerFilter =
  '/autocomplete?columns=id&columns=user.fullName&&filter={"search":{"type":"string","key":"user.fullName","value":';

const cashCenterFilter =
  '/autocomplete?columns=id&columns=name&filter={ "active": { "type": "boolean", "key": "active", "value":"true"}, "search":{"type":"string","key":"name","value":';

const SpecificationSchema = Yup.object().shape({
  currency: Yup.object().required("Odaberite valutu!"),
  company: Yup.object().required("Odaberite tvrtku!"),
  companyBranch: Yup.object().required("Odaberite poslovnicu!"),
  cashCenter: Yup.object().required("Odaberite gotovinski centar!"),
});

const getTotal = (bills, coins) => {
  let totalAmount = 0;
  let totalCoins = 0;
  let totalBills = 0;
  if (bills != null || coins != null) {
    if (bills != null) {
      totalBills = bills.reduce((acc, value) => {
        return acc + value.currencyDenomination.value * value.pieces;
      }, 0);
    }
    if (coins != null) {
      totalCoins = coins.reduce((acc, value) => {
        return acc + value.currencyDenomination.value * value.pieces;
      }, 0);
    }
    totalAmount = totalCoins + totalBills;
  }

  return totalAmount;
};

class OrderEdit extends Component {
  constructor() {
    super();
    if (!localStorage.getItem(TABLE_CODE)) {
      localStorage.setItem(
        TABLE_CODE,
        JSON.stringify({
          currentPage: 0,
          maxItems: 10,
        })
      );
    }
  }

  state = {
    specificationId: null,
    specificationData: null,
    loading: true,
    currencyDenominations: [],
    coins: [],
    bills: [],
    itemsAdded: [],
  };

  getDenominations = (currencyCode) => {
    const columns =
      "columns=id&columns=value&columns=denominationType&columns=name&allowedOnly=true";
    const filters = {
      currency: { type: "string", key: "currency.code", value: currencyCode },
    };
    const url = getAutocompleteURL("currencyDenomination", columns, filters);
    return fetch(`api/${encodeURI(url)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            this.setState({
              currencyDenominations: data,
            });
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
        }
      })
      .catch((e) => {
        console.error("Error while getting denominations", e);
        showErrorAlert(getTranslation("api.error.fetching"));
      });
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    if (id && id !== "create") {
      this.setState({ specificationId: id }, this.getSpecification(id));
    } else {
      this.setState({ loading: false });
    }
    this.getDenominations("HRK");
  }

  handleItemRemoval = (itemForDeletion) => {
    const { itemsAdded } = this.state;
    const newItems = itemsAdded.filter(
      (item) =>
        item.quality !== itemForDeletion.quality ||
        item.currencyDenomination.id !== itemForDeletion.currencyDenomination.id
    );

    const denoms = this.separateBillsAndCoins(newItems);
    const { bills, coins } = denoms;
    this.setState({ itemsAdded: newItems, bills, coins });
  };

  addDenominationItem = (item) => {
    const denomination = item.currencyDenomination;
    const { itemsAdded } = this.state;
    let shouldAddToArray = true;
    let newItems = [];
    newItems = itemsAdded.map((addedItem) => {
      if (
        addedItem.currencyDenomination.id === denomination.id &&
        +addedItem.quality === +item.quality
      ) {
        shouldAddToArray = false;
        return {
          ...item,
          pieces: +addedItem.pieces + +item.pieces,
        };
      }
      return addedItem;
    });

    if (shouldAddToArray) {
      newItems.push(item);
    }

    const denoms = this.separateBillsAndCoins(newItems);
    const { bills, coins } = denoms;
    this.setState({ itemsAdded: newItems, bills, coins });
  };

  separateBillsAndCoins = (items) => {
    const coins = items.filter(
      (item) => item.currencyDenomination.denominationType === COIN_TYPE_CODE
    );

    const bills = items.filter(
      (item) => item.currencyDenomination.denominationType === BILL_TYPE_CODE
    );

    return { coins, bills };
  };

  addItemToOrder = (values) => {
    const { denominationValue, quality, addAmount } = values;
    const { currencyDenominations } = this.state;
    let denomination = null;
    currencyDenominations.forEach((denom) => {
      if (denom.id === +denominationValue) {
        denomination = denom;
      }
    });

    const item = {
      currencyDenomination: denomination,
      pieces: addAmount,
      quality,
    };

    this.addDenominationItem(item);
  };

  getSpecification = (id) => {
    const { showErrorAlert } = this.props;
    this.setState({ loading: true });
    fetch(`api/specification/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((formData) => {
            this.getDenominations(formData.currency.code);
            const denoms = this.separateBillsAndCoins(
              formData.specificationItems
            );
            const { bills, coins } = denoms;
            this.setState({
              specificationData: {
                ...formData,
                deliveryDate: new Date(formData.deliveryDate),
                creationDate: new Date(formData.creationDate),
              },
              itemsAdded: formData.specificationItems,
              coins,
              bills,
              loading: false,
            });
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        console.error("Error while getting order", e);
        showErrorAlert(getTranslation("api.error.fetching"));
        this.setState({ loading: false });
      });
  };

  submitForm = (values) => {
    const { itemsAdded, specificationId, coins, bills } = this.state;
    const postData = {
      ...values,
      specificationType: ORDER_TYPE_CODE,
      amount: getTotal(coins, bills),
      specificationItems: itemsAdded,
    };
    if (specificationId) {
      const url = `api/specification/${specificationId}`;
      return fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
        body: JSON.stringify(postData),
      });
    }
    const url = "api/specification";
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(postData),
    });
  };

  generateClientAutocompleteURL = (type, parent, cashCenter, key) => {
    const columns = "columns=id&columns=name";
    const filters = {
      active: {
        type: "boolean",
        key: "active",
        value: "true",
      },
      type: {
        type: "integer",
        key: "type",
        value: type,
      },
    };

    if (parent) {
      filters.parent = {
        type: "long",
        key: "parent.id",
        value: parent.id,
      };
    }

    if (cashCenter) {
      filters.cashCenter = {
        type: "long",
        key: "cashCenter.id",
        value: cashCenter.id,
      };
    }

    var url = getAutocompleteURL("", columns, filters);
    if (key) {
      url =
        url.slice(0, -1) +
        ', "search":{"type":"string","key":"' +
        key +
        '","value":';
    }
    return url;
  };

  generateClientOrderSubjectAutocompleteURL = (key) => {
    const columns = "columns=id&columns=name";
    const filters = {
      active: {
        type: "boolean",
        key: "active",
        value: "true",
      },
    };

    var url = getAutocompleteURL("", columns, filters);
    if (key) {
      url =
        url.slice(0, -1) +
        ', "search":{"type":"string","key":"' +
        key +
        '","value":';
    }
    return url;
  };

  render() {
    const {
      specificationData,
      loading,
      currencyDenominations,
      bills,
      coins,
      itemsAdded,
    } = this.state;

    if (loading) {
      return <CustomSpinner />;
    }

    const { userInfo, history, showErrorAlert } = this.props;

    let defaultCurrency = undefined,
      defaultClient = undefined,
      defaultCashCenter = undefined;
    if (userInfo.companyEmployer) {
      if (
        userInfo.companyEmployer.defaultCashCenter &&
        userInfo.companyEmployer.defaultCashCenter.id
      ) {
        defaultCashCenter = userInfo.companyEmployer.defaultCashCenter;
      }
      if (
        userInfo.companyEmployer.defaultClient &&
        userInfo.companyEmployer.defaultClient.id
      ) {
        defaultClient = userInfo.companyEmployer.defaultClient;
      }
      if (
        userInfo.companyEmployer.defaultCurrency &&
        userInfo.companyEmployer.defaultCurrency.id
      ) {
        defaultCurrency = userInfo.companyEmployer.defaultCurrency;
      }
    }

    let initialData = null;
    if (specificationData) {
      initialData = {
        ...specificationData,
        company: specificationData.company,
        cashCenter:
          specificationData.companyBranch != null
            ? specificationData.companyBranch.cashCenter
            : specificationData.company != null
            ? specificationData.company.cashCenter
            : null,
      };
    } else {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      initialData = {
        creationEmployer: userInfo.companyEmployer,
        deliveryDate: tomorrow,
        orderRoute: FROM_VAULT,
        quality: NORMAL_QUALITY_CODE,
        currency: defaultCurrency,
        company: defaultClient,
        cashCenter: defaultCashCenter,
      };
    }

    const isProcessed = initialData.processed;

    const isAddDenomButtonDisabled = (values) => {
      const { currency, denominationValue, quality, addAmount } = values;
      return !currency || !denominationValue || !quality || !addAmount;
    };

    const denomTableMap = {
      quality: {
        field: "quality",
        header: "specification.table.row.quality",
        properties: {
          type: "enum",
          translation: "specificationItem.quality.",
        },
      },
      denominationValue: {
        field: "currencyDenomination.value",
        header: "specification.bills.table.row.amount",
        properties: {
          type: "currency",
        },
      },
      pieces: {
        field: "pieces",
        header: "specification.bills.table.row.pieces",
      },
      totalDenomValue: {
        header: "specifications.table.total.sum",
        properties: {
          type: "sum",
          format: "currency",
          cellsToAdd: ["denominationValue", "pieces"],
        },
      },
      buttonRemoveItem: {
        properties: {
          type: "button",
          disabled: isProcessed,
          text: "",
          icon: "cil-x",
          style: { width: "1em" },
          action: this.handleItemRemoval,
          returnRow: true,
        },
      },
    };

    return (
      <div>
        <Button
          className="margin--bottom"
          onClick={() => history.push(backPath)}
        >
          <span className="cil-arrow-left" />
        </Button>
        <Formik
          initialValues={initialData}
          validationSchema={SpecificationSchema}
          onSubmit={(values, actions) => {
            this.submitForm(values).then((r) => {
              if (r.status === 200) {
                history.push(backPath);
              } else {
                showErrorAlert(getTranslation("api.error.saving"));
              }
              actions.setSubmitting(false);
            });
          }}
          render={({
            errors,
            status,
            touched,
            isSubmitting,
            validateForm,
            setFieldValue,
            values,
          }) => {
            const handleCurrencyChange = (suggestion) => {
              setFieldValue("currency", suggestion);
              this.getDenominations(suggestion.code);
            };
            return (
              <React.Fragment>
                <Row>
                  <Col xs="12">
                    <Form>
                      {isSubmitting && <CustomProgress />}
                      <Row>
                        <Col md="6">
                          <Field
                            required
                            disabled
                            labeltext="specification.create.employee"
                            name="creationEmployer"
                            onselected={(suggestion) =>
                              setFieldValue("creationEmployer", suggestion)
                            }
                            tabledatabasename="companyEmployer"
                            filter={companyEmployerFilter}
                            showcolumn="user.fullName"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            required
                            disabled={isSubmitting || isProcessed}
                            labeltext="order.create.route"
                            enumname="OrderRoute"
                            onselected={(value) =>
                              setFieldValue("orderRoute", value)
                            }
                            name="orderRoute"
                            component={EnumeratorComponent}
                          />
                        </Col>
                        <Col md="6" style={{ display: "none" }}>
                          <Field
                            required
                            disabled={isSubmitting || isProcessed}
                            labeltext="order.create.creationDate"
                            name="creationDate"
                            component={DatePicker}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            required
                            disabled={isSubmitting || isProcessed}
                            labeltext="order.create.deliveryDate"
                            name="deliveryDate"
                            component={DatePicker}
                            setFieldValue={setFieldValue}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            required
                            disabled={isSubmitting || isProcessed}
                            labeltext="order.create.client"
                            name="company"
                            onselected={(suggestion) =>
                              setFieldValue("company", suggestion)
                            }
                            tabledatabasename="company"
                            filter={this.generateClientAutocompleteURL(
                              MAIN_COMPANY_TYPE,
                              null,
                              values.cashCenter,
                              "name"
                            )}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            required
                            disabled={isSubmitting || isProcessed}
                            labeltext="order.create.cashCenter"
                            name="cashCenter"
                            onselected={(suggestion) =>
                              setFieldValue("cashCenter", suggestion)
                            }
                            tabledatabasename="cashCenter"
                            filter={cashCenterFilter}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            required
                            disabled={isSubmitting || isProcessed}
                            labeltext="order.create.client.branch"
                            name="companyBranch"
                            onselected={(suggestion) =>
                              setFieldValue("companyBranch", suggestion)
                            }
                            tabledatabasename="company"
                            filter={this.generateClientOrderSubjectAutocompleteURL(
                              "name"
                            )}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            type="textarea"
                            disabled={isProcessed || isSubmitting}
                            labeltext="specification.create.remark"
                            name="remark"
                            component={TextInput}
                          />
                        </Col>
                        <hr
                          style={{ border: "1px solid #c8ced3", width: "100%" }}
                        />
                        <Col md="6">
                          <Field
                            required
                            disabled={
                              isProcessed ||
                              itemsAdded.length > 0 ||
                              isSubmitting
                            }
                            labeltext="specification.create.currency"
                            name="currency"
                            onselected={(suggestion) =>
                              handleCurrencyChange(suggestion)
                            }
                            tabledatabasename="currency"
                            filter={currencyFilter}
                            showcolumn="name"
                            component={DropDownComponent}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            disabled={isSubmitting || isProcessed}
                            name="denominationValue"
                            labeltext="order.create.denom"
                            selectoptions={currencyDenominations}
                            showcolumn="name"
                            component={MultiSelect}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            disabled={isSubmitting || isProcessed}
                            labeltext="order.create.quality"
                            enumname="SpecificationItemQuality"
                            onselected={(value) =>
                              setFieldValue("quality", value)
                            }
                            name="quality"
                            component={EnumeratorComponent}
                          />
                        </Col>
                        <Col md="6">
                          <Field
                            disabled={isSubmitting || isProcessed}
                            name="addAmount"
                            labeltext="order.create.amount"
                            component={TextInput}
                          />
                        </Col>
                        <Button
                          onClick={() => this.addItemToOrder(values)}
                          disabled={
                            isAddDenomButtonDisabled(values) ||
                            isSubmitting ||
                            isProcessed
                          }
                          className="margin--right"
                          color="primary"
                        >
                          {getTranslation("table.button.add")}
                        </Button>
                        <Button
                          disabled={isSubmitting || isProcessed}
                          type="submit"
                          color="primary"
                        >
                          {getTranslation("table.button.save")}
                        </Button>
                      </Row>
                    </Form>
                  </Col>
                  <Col>
                    <hr
                      style={{ border: "1px solid #c8ced3", width: "100%" }}
                    />
                    <h4>
                      {getTranslation("specification.bills.table.header")}
                    </h4>
                    <CustomTable
                      data={{ data: bills, count: 0 }}
                      tableCode={TABLE_CODE}
                      tableMap={denomTableMap}
                      hidePagination
                    />
                  </Col>
                  <Col>
                    <hr
                      style={{ border: "1px solid #c8ced3", width: "100%" }}
                    />
                    <h4>
                      {getTranslation("specification.coins.table.header")}
                    </h4>
                    <CustomTable
                      data={{ data: coins, count: 0 }}
                      tableCode={TABLE_CODE}
                      tableMap={denomTableMap}
                      hidePagination
                    />
                  </Col>
                </Row>
                <Row
                  className="green-total"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <span>{getTranslation("vaultReport.table.footer.sum")}</span>
                  <span
                    style={{
                      marginLeft: "2px",
                    }}
                  >
                    {new Intl.NumberFormat(...CURRENCY_LOCALE).format(
                      getTotal(bills, coins)
                    )}
                  </span>
                </Row>
              </React.Fragment>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userReducer.userState,
});

export default withRouter(connect(mapStateToProps)(OrderEdit));
