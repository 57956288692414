import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormFeedback,
  FormGroup,
  Button,
  Row,
} from "reactstrap";
import { getTranslation } from "util/localisation";
import { GetApiCallFiltersBearer } from "util/api/Get";
import { getEnumURL } from "util/api/callGenerator";
import "./EnumeratorComponent.scss";

const EnumeratorComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { enumname, labeltext, onselected, disabled, required, allowclear } =
    props;
  const [isDropdownOpen, setDropDownOpen] = useState(false);
  const [enumData, setEnumData] = useState([]);
  const isInvalid = errors[field.name] != undefined;

  useEffect(() => {
    GetApiCallFiltersBearer(getEnumURL(enumname)).then((r) => {
      setEnumData(r);
    });
  }, [enumname]);

  const toggle = () => {
    setDropDownOpen(!isDropdownOpen);
  };

  const selectItem = (e) => {
    if (e) {
      onselected(e.target.value);
    } else {
      onselected(undefined);
    }
  };

  const getStringEnumeratorValue = (enumId) => {
    let stringValue = "";
    if (enumId || enumId === 0) {
      enumData.forEach((enumItem) => {
        const { id, value } = enumItem;
        if (id.toString() === enumId.toString()) {
          stringValue = value;
        }
      });
    }

    return stringValue;
  };

  return (
    <div className="enum-container">
      <Label>
        {required && "*"}
        {getTranslation(labeltext)}
      </Label>
      <Row style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Dropdown
          isOpen={!disabled && isDropdownOpen}
          toggle={toggle}
          style={{ flex: "auto" }}
        >
          <DropdownToggle caret>
            {getStringEnumeratorValue(field.value)}
          </DropdownToggle>
          <DropdownMenu {...field}>
            {enumData.map((row) => (
              <DropdownItem
                key={row.id}
                value={row.id}
                onClick={(e) => selectItem(e)}
              >
                {row.value}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {allowclear && (
          <Button
            style={{ marginLeft: "10px" }}
            onClick={(e) => selectItem(undefined)}
          >
            <span className="cil-x" />
          </Button>
        )}
      </Row>
      {isInvalid && (
        <div className="custom--invalid--feedback">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default EnumeratorComponent;
