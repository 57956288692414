import { showErrorAlert } from "actionCreators/spinnerActionCreator";
import { AppHeaderDropdown, AppNavbarBrand } from "@coreui/react";
import logo from "assets/img/brand/as_logo.png";
import React, { Component } from "react";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonDropdown,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  FormGroup,
  Modal,
  Nav,
  NavItem,
  Row,
  Spinner,
} from "reactstrap";
import { API_ENDPOINT } from "util/api/apiConstants";
import Auth from "util/api/auth";
import { getTranslation } from "util/localisation";
import { MEDITERAN_SECURITY_ROLES, SC_ROLES } from "util/roles/roleList";
import CustomCircularProgress from "../Progress/CustomCircularProgress";
import { EventSourcePolyfill } from "event-source-polyfill";
import { getAppSettings, setAppSettings } from "../../util/settings";

class Header extends Component {
  state = {
    companyUser: null,
    loading: true,
    loading2fa: false,
    start: false,
    showTimeNotification: false,
    modalIsOpen: false,
    modalIsForced: false,
    appModalIsOpen: false,
    resetPasswordModalIsOpen: false,
    force2fa: false,
    validateFail: false,
    qrCode: "",
    otp: "",
    fadeIn: false,
    color: "success",
    dropDownOpen: false,
    newPassword: "",
    newPasswordValid: true,
    newPasswordRepeat: "",
    oldPassword: "",
  };

  componentDidMount() {
    fetch(`${API_ENDPOINT}user/current`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((data) => {
          this.setState({ companyUser: data }, () => {
            this.setState({ loading: false });
          });
          if (data && data.user) {
            this.setState({
              force2fa: data.user.twoFAForced,
              forcePassChange: data.user.passChangeForced,
            });
            if (
              data.user.twoFAForced &&
              (!data.user.twoFAActive ||
                (data.user.twoFAActive && !data.user.twoFASecret))
            ) {
              this.setState({ modalIsForced: true });
            }
            if (data.user.passChangeForced) {
              this.setState({ modalPasswordIsForced: true });
            }
          }
          getAppSettings()
            .then((settings) => {
              setAppSettings(settings.data);
              this.setState({ loading: false });
            })
            .catch((e) => {
              console.error(`Error getting settings from server: ${e}`);
            });
        });
      } else {
        delete localStorage.auth;
      }
    });
  }

  showHeaderButton = (neededRoles) => {
    const { userInfo } = this.props;
    const { authorities } = userInfo.companyEmployer.user;
    const userRoles = authorities.map((auth) => auth.authority);
    const matchingRoles = userRoles.filter((value) =>
      neededRoles.includes(value)
    );
    return matchingRoles.length > 0;
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ dropDownOpen: !prevState.dropDownOpen }));
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    if (!this.state.modalIsForced) {
      this.setState({ modalIsOpen: false });
    }
  };

  openAppModal = () => {
    this.setState({ appModalIsOpen: true });
  };

  closeAppModal = () => {
    this.setState({ appModalIsOpen: false });
  };

  openResetPasswordModal = () => {
    this.setState({ resetPasswordModalIsOpen: true });
  };

  closeResetPasswordModal = () => {
    if (!this.state.modalPasswordIsForced) {
      this.setState({
        resetPasswordModalIsOpen: false,
        newPassword: "",
        newPasswordValid: true,
        newPasswordRepeat: "",
        oldPassword: "",
      });
    }
  };

  otpChanged = (event) => {
    this.setState({ otp: event.target.value });
  };

  get2FASettings = () => {
    this.setState({ loading2fa: true });
    fetch(`${API_ENDPOINT}user/get2FASettings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          if (this.state.force2fa) {
            data.content.twoFAActive = true;
          }
          this.setState({ qrCode: data.content });
        });
      } else {
      }
      this.setState({ loading2fa: false });
    });
  };

  onTimerFinish = () => {
    if (this.es) {
      this.es.close();
      this.es = undefined;
    }
    this.setState({
      showTimeNotification: false,
      start: false,
      loading2fa: false,
    });
  };

  updateUser2fa = (qrCode) => {
    this.setState({ loading2fa: true });
    fetch(
      `${API_ENDPOINT}user/updateUser2fa?active=${qrCode.twoFAActive}&secret=${qrCode.twoFASecret}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${localStorage.auth}`,
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          if (data) {
            this.setState({ force2fa: data.twoFAForced });
            if (
              data.twoFAForced &&
              (!data.twoFAActive || (data.twoFAActive && !data.twoFASecret))
            ) {
              this.setState({ modalIsForced: true });
            } else {
              this.setState({ modalIsForced: false });
              this.closeModal();
            }
          }
        });
      } else {
      }
      this.setState({ loading2fa: false });
    });
  };

  save2Fa = () => {
    const { qrCode } = this.state;
    if (qrCode.twoFAActive) {
      this.setState({
        loading2fa: true,
        showTimeNotification: true,
        start: true,
        validateFail: false,
      });
      this.es = new EventSourcePolyfill(
        `${API_ENDPOINT}/twoFA/register?secret=${qrCode.twoFASecret}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.auth}`,
          },
        }
      );
      const thisReference = this;
      this.es.onmessage = function (event) {
        if (event.data) {
          const esResp = JSON.parse(event.data);
          if (esResp.validate) {
            thisReference.onTimerFinish();
            thisReference.updateUser2fa(qrCode);
          } else {
            thisReference.onTimerFinish();
            thisReference.setState({ validateFail: true });
          }
        } else {
          thisReference.onTimerFinish();
          thisReference.setState({ validateFail: true });
        }
      };
    } else {
      this.updateUser2fa(qrCode);
    }
  };

  goToSpec = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push("/app/specification");
  };

  goToOrder = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push("/app/order");
  };

  logout() {
    Auth.logOut();
  }

  change2FaChecked = (checked) => {
    let qrCodeNew = { ...this.state.qrCode };
    qrCodeNew.twoFAActive = checked;
    this.setState({ qrCode: qrCodeNew });
  };

  onOldPassChange = (value) => {
    this.setState({ oldPassword: value });
  };

  onNewPassChange = (value) => {
    this.setState({ newPassword: value });
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.{8,12}$)/;
    if (value) {
      this.setState({ newPasswordValid: regex.test(value) });
    } else {
      this.setState({ newPasswordValid: true });
    }
  };

  onNewRepeatPassChange = (value) => {
    this.setState({ newPasswordRepeat: value });
  };

  resetPassword = () => {
    const { newPassword, oldPassword } = this.state;
    const postData = {
      newPassword: newPassword,
      oldPassword: oldPassword,
    };
    const url = "api/user/resetPassword";
    this.setState({ loading: true });
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        this.setState({ loading: false });
        if (response.status === 200) {
          this.closeResetPasswordModal();
          this.setState({
            modalPasswordIsForced: false,
            forcePassChange: false,
          });
        } else {
          showErrorAlert(getTranslation("api.error.fetching"));
        }
      })
      .catch((e) => {
        console.error("Error while resetting", e);
        this.setState({ loading: false });
      });
  };

  createQRCode = () => {
    const { showErrorAlert } = this.props;
    const REPORT_ENDPOINT = `api/report/qrCode?reportId=${this.state.companyUser.company.id}&type=company`;

    fetch(REPORT_ENDPOINT, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${localStorage.auth}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .blob()
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "download.pdf";
              a.target = "_blank";
              a.click();
              a.remove();
            })
            .catch((e) => {
              console.error("error : ", e);
              showErrorAlert(getTranslation("api.error.generatingReport"));
            });
        } else {
          showErrorAlert(getTranslation("api.error.generatingReport"));
        }
      })
      .catch((e) => {
        console.error("error : ", e);
        showErrorAlert(getTranslation("api.error.generatingReport"));
      });
  };

  render() {
    const {
      loading,
      loading2fa,
      force2fa,
      showTimeNotification,
      validateFail,
      modalIsOpen,
      modalIsForced,
      modalPasswordIsForced,
      appModalIsOpen,
      resetPasswordModalIsOpen,
      qrCode,
      start,
      fadeIn,
      color,
      companyUser,
      dropDownOpen,
      newPassword,
      newPasswordValid,
      newPasswordRepeat,
      oldPassword,
    } = this.state;

    const hasSCWebRoles = this.showHeaderButton(Object.values(SC_ROLES));
    const hasMediteranRoles = this.showHeaderButton(
      Object.values(MEDITERAN_SECURITY_ROLES)
    );

    return (
      <React.Fragment>
        {!loading && (
          <React.Fragment>
            <Modal
              centered
              isOpen={modalIsOpen || modalIsForced}
              onOpened={this.get2FASettings}
              toggle={this.closeModal}
            >
              <div className="header_specification">
                <h2>
                  <span>{getTranslation("account.menu.2fa")}</span>
                </h2>
                {!modalIsForced && (
                  <Button onClick={(e) => this.closeModal(e)} color="danger">
                    X
                  </Button>
                )}
              </div>
              {loading2fa && (
                <div className="progress">
                  <div className="progress-bar progress-bar-striped indeterminate" />
                </div>
              )}
              {qrCode && (
                <Container>
                  <Row>
                    <Col>
                      <div class="form-check">
                        <input
                          disabled={loading2fa || force2fa}
                          class="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            this.change2FaChecked(e.target.checked);
                          }}
                          checked={qrCode.twoFAActive || force2fa}
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {getTranslation("2fa.active")}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  {qrCode.twoFAActive && (
                    <>
                      <Row>
                        <Col>
                          <h5>
                            <b>{getTranslation("2fa.scanCode")}</b>
                          </h5>
                        </Col>
                      </Row>
                      {(qrCode.appUrlAndroid || qrCode.appUrlIOS) && (
                        <Row>
                          <Col>
                            <Button
                              onClick={this.openAppModal}
                              color="primary"
                              disabled={loading2fa}
                            >
                              {getTranslation("2fa.appDownload")}
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <br />
                      <Row>
                        <Col>
                          <QRCode value={qrCode.qrCode} size={200} />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Input value={qrCode.twoFASecret} disabled />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col>
                      <Button
                        onClick={this.save2Fa}
                        color="success"
                        disabled={loading2fa}
                      >
                        {getTranslation("table.button.save")}
                      </Button>
                    </Col>
                  </Row>
                  {showTimeNotification && qrCode && qrCode.validFor && (
                    <Row>
                      <Col>
                        <CustomCircularProgress
                          label={getTranslation("2fa.confirm")}
                          duration={qrCode.validFor}
                          start={start}
                          onFinish={this.onTimerFinish}
                        />
                        <br />
                      </Col>
                    </Row>
                  )}
                  {validateFail && (
                    <Row>
                      <Col>
                        <InputGroup>
                          <div className="alert alert-danger" role="alert">
                            {getTranslation("2fa.error")}
                          </div>
                        </InputGroup>
                      </Col>
                    </Row>
                  )}
                </Container>
              )}
            </Modal>

            <Modal isOpen={appModalIsOpen} toggle={this.closeAppModal} centered>
              <div className="header_specification">
                <h2>
                  <span>{getTranslation("2fa.appDownload")}</span>
                </h2>
                <Button onClick={(e) => this.closeAppModal(e)} color="danger">
                  X
                </Button>
              </div>
              <Container>
                <Row>
                  <Col>
                    <h5>
                      <b>{getTranslation("2fa.appCode")}</b>
                    </h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  {qrCode.appUrlAndroid && (
                    <Col>
                      <b>{getTranslation("2fa.android")}</b>
                      <QRCode value={qrCode.appUrlAndroid} size={200} />
                    </Col>
                  )}
                  {qrCode.appUrlIOS && (
                    <Col>
                      <b>{getTranslation("2fa.ios")}</b>
                      <QRCode value={qrCode.appUrlIOS} size={200} />
                    </Col>
                  )}
                </Row>
                <br />
              </Container>
            </Modal>

            <Modal
              isOpen={resetPasswordModalIsOpen || modalPasswordIsForced}
              toggle={this.closeResetPasswordModal}
              centered
            >
              <div className="header_specification">
                <h2>
                  <span>{getTranslation("user.resetPassword")}</span>
                </h2>
                {!modalPasswordIsForced && (
                  <Button
                    onClick={(e) => {
                      this.closeResetPasswordModal();
                    }}
                    color="danger"
                  >
                    X
                  </Button>
                )}
              </div>
              <Container>
                <form>
                  {!this.showHeaderButton([
                    MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
                  ]) && (
                    <Row>
                      <Col span="6">
                        <React.Fragment>
                          <Label>
                            {getTranslation("user.resetPassword.old")}
                          </Label>
                          <FormGroup>
                            <Input
                              type="password"
                              value={oldPassword}
                              onChange={(e) =>
                                this.onOldPassChange(e.target.value)
                              }
                            ></Input>

                            {!oldPassword && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block", marginLeft: "15px" }}
                              >
                                {getTranslation("user.resetPassword.empty")}
                              </div>
                            )}
                          </FormGroup>
                        </React.Fragment>
                      </Col>
                      <Col span="6"></Col>
                    </Row>
                  )}
                  <Row>
                    <Col span="6">
                      <React.Fragment>
                        <Label>
                          {getTranslation("user.resetPassword.new")}
                        </Label>
                        <FormGroup>
                          <Input
                            type="password"
                            value={newPassword}
                            onChange={(e) =>
                              this.onNewPassChange(e.target.value)
                            }
                          ></Input>

                          {!newPassword && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block", marginLeft: "15px" }}
                            >
                              {getTranslation("user.resetPassword.empty")}
                            </div>
                          )}
                          {newPassword && !newPasswordValid && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block", marginLeft: "15px" }}
                            >
                              {getTranslation("passwordPolicy.validation")}
                            </div>
                          )}
                          {newPassword &&
                            newPasswordValid &&
                            newPassword != newPasswordRepeat && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block", marginLeft: "15px" }}
                              >
                                {getTranslation(
                                  "user.resetPassword.notMatching"
                                )}
                              </div>
                            )}
                        </FormGroup>
                      </React.Fragment>
                    </Col>
                    <Col span="6">
                      <React.Fragment>
                        <Label>
                          {getTranslation("user.resetPassword.newRepeat")}
                        </Label>
                        <FormGroup>
                          <Input
                            type="password"
                            value={newPasswordRepeat}
                            onChange={(e) =>
                              this.onNewRepeatPassChange(e.target.value)
                            }
                          ></Input>

                          {!newPasswordRepeat && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block", marginLeft: "15px" }}
                            >
                              {getTranslation("user.resetPassword.empty")}
                            </div>
                          )}
                          {newPasswordRepeat &&
                            newPassword != newPasswordRepeat && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block", marginLeft: "15px" }}
                              >
                                {getTranslation(
                                  "user.resetPassword.notMatching"
                                )}
                              </div>
                            )}
                        </FormGroup>
                      </React.Fragment>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        onClick={this.resetPassword}
                        color="success"
                        disabled={
                          loading ||
                          !newPasswordValid ||
                          newPassword != newPasswordRepeat ||
                          (!oldPassword &&
                            !this.showHeaderButton([
                              MEDITERAN_SECURITY_ROLES.ROLE_SECURITY_ADMIN,
                            ])) ||
                          !newPassword ||
                          !newPasswordRepeat
                        }
                      >
                        {getTranslation("table.button.save")}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Container>
            </Modal>

            <AppNavbarBrand
              full={{
                src: logo,
                width: 30,
                height: 30,
                alt: getTranslation("header.title"),
              }}
              minimized={{
                src: logo,
                width: 30,
                height: 30,
                alt: getTranslation("header.title"),
              }}
            />

            <Nav className="d-md-down-none" navbar>
              <NavItem>
                {getTranslation("header.title")} v
                {localStorage.getItem("version")}
              </NavItem>
            </Nav>

            <Nav className="ml-auto" navbar>
              <AppHeaderDropdown direction="down">
                <ButtonDropdown
                  toggle={this.toggleDropdown}
                  isOpen={dropDownOpen}
                >
                  <DropdownToggle nav>
                    <button type="button" className="btn btn-primary">
                      {companyUser.user.fullName}
                    </button>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.openModal}>
                      <i className="fa fa-lock" />{" "}
                      {getTranslation("account.menu.2fa")}
                    </DropdownItem>
                    <DropdownItem onClick={this.openResetPasswordModal}>
                      <i className="fa fa-key" />{" "}
                      {getTranslation("user.resetPassword")}
                    </DropdownItem>
                    {companyUser && companyUser.company && (
                      <DropdownItem onClick={this.createQRCode}>
                        <i className="fa fa-key" />{" "}
                        {getTranslation("account.menu.company.qrcode")}
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              </AppHeaderDropdown>

              <button className="btn btn-outline-primary" onClick={this.logout}>
                <i className="fa fa-sign-out" />{" "}
                {getTranslation("account.menu.logout")}
              </button>
            </Nav>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, dispatch) => ({
  userInfo: state.userReducer.userState,
  showErrorAlert: (error) => dispatch(showErrorAlert(error)),
});

export default withRouter(connect(mapStateToProps)(Header));
